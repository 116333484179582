import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { find, orderBy } from 'lodash/fp';
import Modal from '../../../../layout/modal';
import { CardTitle, CardContainer, CardContainerBody, MutedSubtitle } from '../../../../ui-components/card';
import { Body1 } from '../../../../ui-components/typography';
import { CommentRepliesWrapper } from '../../../../ui-components/comments';
import { Spinner } from '../../../../ui-components/spinner';
import MakeRequest from '../../../../request';
import { PrimaryButtonElement } from '../../../../ui-components/buttons';
import { getApprovalFlowApiUrl, getApprovalFlowApiKey } from '../../../../state/config/selectors';
import MainComment from './main-comment';
import SubComment from './sub-comment';

const StyledPrimaryButton = styled(PrimaryButtonElement)`
  background-color: gray;
  border-color: gray;
  outline: none !important;
  cursor: pointer;

  &.active {
    background-color: #f69c2d;
    border-color: #f69c2d;
    outline: none !important;
    cursor: pointer;
  }
`;
class Comments extends React.Component {
  state = {
    comments: undefined,
    versions: undefined,
    stackSelected: undefined,
    editJobId: localStorage.getItem('editJobId'),
    reloadVersions: false,
    loading: true,
    selectedPlatform: null,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedPlatform != this.state.selectedPlatform) {
      this.getApprovalVersions(this.state.selectedPlatform);
    }
  }

  render() {
    const { versions, comments, processing } = this.state;
    const hasVersions = !!(versions && versions.length > 0);
    const hasComments = !!(comments && comments.length > 0);

    const buttonStyle = {
      width: 'auto',
      marginRight: 20,
      marginTop: 15,
      textTransform: 'uppercase',
    };

    return (
      <React.Fragment>
        {processing && (
          <Modal>
            <Spinner />
          </Modal>
        )}

        <CardContainer>
          <CardContainerBody>
            <CardTitle>Get Comments</CardTitle>
            <MutedSubtitle>Get approval versions and comments from enabled approval platforms</MutedSubtitle>
            {this.props.approvalPlatforms.length > 0 &&
              this.props.approvalPlatforms.map(
                platform =>
                  platform.enabled && (
                    <StyledPrimaryButton
                      className={`platform ${this.state.selectedPlatform == platform.name ? 'active' : ''}`}
                      key={platform.name}
                      onClick={() => this.setState({ selectedPlatform: platform.name })}
                      style={buttonStyle}
                    >
                      {platform.commentsButtonText || platform.name}
                    </StyledPrimaryButton>
                  )
              )}

            {this.state.loadingVersions ? (
              <React.Fragment>
                <h4>Loading Versions ...</h4>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {this.props.approvalPlatforms.length == 0 && (
                  <MutedSubtitle>
                    No approval platforms enabled for your account. Please contact at{' '}
                    <a mailto="support@nekta.io">support@nekta.io</a>
                  </MutedSubtitle>
                )}

                {this.state.selectedPlatform != null && hasVersions ? (
                  <div style={{ marginTop: 15 }}>
                    <select onChange={e => this.onStackVersionChange(e.target.value)} style={{ width: '100%' }}>
                      {versions.map((version, index) => {
                        const time = new Date(version.uploaded_at);
                        return (
                          <option key={index + time} value={version.id}>
                            {`v${version.index}: ${time.toUTCString()}`}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                ) : (
                  this.state.selectedPlatform && !hasVersions && <MutedSubtitle>No approvals found</MutedSubtitle>
                )}

                {this.state.loadingComments ? (
                  <React.Fragment>
                    <h4>Loading Comments ...</h4>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div>{hasComments && comments.map(this.renderComment)}</div>{' '}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </CardContainerBody>
        </CardContainer>
      </React.Fragment>
    );
  }

  renderComment = comment => {
    const hasReplies = comment.replies.length > 0;

    return [
      <MainComment
        key={`comment-${comment.id}`}
        comment={comment}
        onComplete={this.onComplete}
        panelBridge={this.props.panelBridge}
        platform={this.state.selectedPlatform}
      />,
      hasReplies ? (
        <CommentRepliesWrapper key={`replies-${comment.id}`}>
          {comment.replies.map(commentReply => (
            <SubComment key={`reply-${commentReply.id}`} comment={commentReply} />
          ))}
        </CommentRepliesWrapper>
      ) : null,
    ];
  };

  getApprovalVersions = async selectedPlatform => {
    const { editJobId } = this.state;

    this.setState({ versions: [], comments: [], loadingVersions: true });
    const {
      data: { result: versionList },
    } = await MakeRequest(
      {
        endpoint: {
          url: `${this.props.approvalFlowApiUrl}/approval/versions`,
          xApiKey: this.props.approvalFlowApiKey,
        },
      },
      { method: 'post', data: { jobID: editJobId, commentsPlatform: selectedPlatform } }
    );

    let data;
    if (selectedPlatform == 'frameio') {
      data = versionList.map(version => ({
        id: version['version'],
        index: version['version'],
        fps: version['fps'],
        frames: version['frames'],
        uploaded_at: version['uploadedDate'],
        assetId: version['assetID'],
      }));
    } else {
      data = versionList.map(version => ({
        id: version['version'],
        index: version['version'],
        uploaded_at: version['uploadedDate'],
        assetId: version['assetID'],
      }));
    }

    data = orderBy(['id'], ['desc'])(data);

    this.setState({ versions: data, loadingVersions: false, reloadVersions: false }, async () => {
      await this.onStackVersionChange(data[0]['id']);
      this.setState({ loading: false });
    });
  };

  onStackVersionChange = async reviewStackVersionId => {
    const { versions } = this.state;

    let version = find({ id: parseInt(reviewStackVersionId) })(versions);

    this.setState({ loadingComments: true });
    const {
      data: { result: rawComments },
    } = await MakeRequest(
      {
        endpoint: {
          url: `${this.props.approvalFlowApiUrl}/approval/comments`,
          xApiKey: this.props.approvalFlowApiKey,
        },
      },
      {
        method: 'post',
        data: {
          jobID: this.state.editJobId,
          commentsPlatform: this.state.selectedPlatform,
          assetID: version.assetId,
        },
      }
    );

    const parentComments = rawComments.filter(c => !c['parent_id']);
    const childComments = rawComments.filter(c => c['parent_id']);
    const nestedComments = parentComments.map(comment => ({
      ...comment,
      version: version,
      replies: childComments.filter(childComment => {
        return childComment['parent_id'] === comment['id'];
      }),
    }));

    this.setState({
      comments: nestedComments,
      stackSelected: reviewStackVersionId,
      loadingComments: false,
    });
  };

  onComplete = async ({ id: commentId, completed }) => {
    this.setState({ processing: true });
    await MakeRequest(
      {
        endpoint: {
          url: `${this.props.approvalFlowApiUrl}/approval/comment/${commentId}/complete`,
          xApiKey: this.props.approvalFlowApiKey,
        },
      },
      { method: !completed ? 'post' : 'delete', commentId: commentId, data: { commentsPlatform: 'frameio' } }
    );
    await this.onStackVersionChange(this.state.stackSelected);
    this.setState({ processing: false });
  };

  splitCamelCase = camelCaseStr => {
    return camelCaseStr.replace(/([A-Z])/g, ' $1').replace(/^./, str => {
      return str.toUpperCase();
    });
  };
}

export default connect(state => ({
  approvalFlowApiUrl: getApprovalFlowApiUrl()(state),
  approvalFlowApiKey: getApprovalFlowApiKey()(state),
}))(Comments);
