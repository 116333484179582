import styled from 'styled-components';
import { PrimaryTextColor } from './colors';

export const SharedAlert = styled.div`
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
`;

export const PrimaryAlert = styled(SharedAlert)`
  color: ${PrimaryTextColor};
  background-color: #cce5ff;
  border-color: ##b8daff;
`;

export const ErrorAlert = styled(SharedAlert)`
  color: ${PrimaryTextColor};
  background-color: #d22948;
  border-color: #731015;
`;

export const SuccessAlert = styled(SharedAlert)`
  color: ${PrimaryTextColor};
  background-color: #425f4a;
  border-color: #425f4a;
`;

export const SuccessMessageContainer = styled.div`
  display: flex;
  color: ${PrimaryTextColor};
  font-size: 30px;
  align-items: center;
  justify-content: center;
  height: 50px;
`;

export const SuccessTick = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 70px;
  background-color: green;
  border-radius: 35px;
  &:before {
    content: '✓';
    color: ${PrimaryTextColor};
    font-size: 50px;
  }
`;
