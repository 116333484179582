import React from 'react';
import { CardContainer, CardContainerBody, CardTitle, MutedSubtitle } from '../../../ui-components/card';
import Modal from '../../../layout/modal';
import { PrimaryButtonElement } from '../../../ui-components/buttons';
import { PrimaryAlert, SuccessAlert } from '../../../ui-components/alerts';
import { ProgressBar } from '../../../ui-components/progressBar';
import { Spinner } from '../../../ui-components/spinner';

export default class Submit extends React.Component {
  state = {
    processStarted: false,
  };

  componentDidMount = async () => {
    if (this.props.activeSequenceId != '0') {
      this.props.panelBridge.emit('app.project.getActiveSequenceSettings');
    }
  };

  submitEdit = async () => {
    this.setState({ processStarted: true });
    this.props.panelBridge.emit('app.edit.submission');
  };

  submitAFXML = () => {
    this.setState({ processStarted: true }, async () => {
      const editJobId = localStorage.getItem('editJobId');
      this.props.panelBridge.emit('app.submit.getAFXML', { editJobId });
    });
  };

  compareSourceToSequence = async () => {
    const editJobId = localStorage.getItem('editJobId');
    this.props.panelBridge.emit('app.submit.compareSourceToSequence', { editJobId });
  };

  getLoader = () => {
    return (
      <div style={{ marginTop: 20 }}>
        <ProgressBar percentage={this.props.percentage} />
      </div>
    );
  };

  render() {
    const { processStarted } = this.state;
    const { cannotSubmit } = this.props;

    const buttonStyle = {
      marginTop: 10,
      width: 'auto',
      pointerEvents: processStarted || cannotSubmit || this.props.disableButtons ? 'none' : '',
      backgroundColor: processStarted || cannotSubmit || this.props.disableButtons ? 'grey' : '',
      borderColor: processStarted || cannotSubmit || this.props.disableButtons ? 'grey' : '',
      color: processStarted || cannotSubmit || this.props.disableButtons ? '#a2a2a2' : '',
    };

    return (
      <div>
        <CardContainer>
          <CardContainerBody>
            <CardTitle>Submission</CardTitle>
            {this.props.activeSequenceSettings && (
              <React.Fragment>
                <MutedSubtitle>Active Sequence Output Resolution</MutedSubtitle>
                <div>
                  <p style={{ marginTop: 0 }}>
                    Width: {this.props.activeSequenceSettings.width} <br />
                    Height: {this.props.activeSequenceSettings.height} <br />
                  </p>
                  <p style={{ marginTop: 0, textTransform: 'uppercase', color: 'red', fontWeight: 'bold' }}>
                    Please update the sequence settings if you want to change your output resolution or use our
                    predefined presets below
                  </p>
                </div>
              </React.Fragment>
            )}

            {this.props.activeSequenceId == 0 && this.props.disableButtons && (
              <MutedSubtitle
                style={{ marginTop: 20, marginBottom: 15, color: 'red !important', textTransform: 'uppercase' }}
              >
                No active sequence found for conform
              </MutedSubtitle>
            )}

            {this.props.activeSequenceId != 0 && this.props.disableButtons && (
              <MutedSubtitle style={{ marginTop: 20, marginBottom: 15, color: 'red', textTransform: 'uppercase' }}>
                Your sequence contains external files. Please remove external files or upload them and then reimport
                using SELECT FILES to enable conform options
              </MutedSubtitle>
            )}

            {(this.props.isUploading || this.props.waitingForInitConform) && (
              <React.Fragment>
                <Modal />
                <div id="loading">
                  <Spinner />
                </div>
                <PrimaryAlert>Uploading files for submission...</PrimaryAlert>
                {this.getLoader()}
              </React.Fragment>
            )}
            {this.props.panelUploadSuccess && !this.props.waitingForInitConform && (
              <SuccessAlert>Successfully sent for submission at {new Date().toLocaleString()}.</SuccessAlert>
            )}

            <PrimaryButtonElement style={buttonStyle} onClick={this.submitEdit}>
              Start Submission Conform
            </PrimaryButtonElement>
          </CardContainerBody>
        </CardContainer>
      </div>
    );
  }
}
