import Postmate from 'postmate';
import { get } from 'lodash/fp';
import {
  setEditJobId,
  setSequenceId,
  setSequenceComparisonResult,
  setSequenceSettings,
  createFileDownloadProgressBar,
  updateFileDownloadProgressBar,
  uploadingProjectFileForApproval,
  uploadingXmlFileForApproval,
  uploadingProjectFileForApprovalCompleted,
  uploadingXmlFileForApprovalCompleted,
  uploadingXmlFileForSubmission,
  uploadingProjectFileForSubmission,
  uploadingProjectFileForSubmissionCompleted,
  uploadingXmlFileForSubmissionCompleted,
} from '../state/project/actions';

import {
  getS3ConformBucket,
  getEditRequestStateApiKey,
  getEditRequestStateApiUrl,
  getConformApprovalsPrefix,
  getConformSubmissionPrefix,
  getS3FileCredentialsApiUrl,
  getS3FileCredentialsApiKey,
  getUploadApiUrl,
  getUploadApiKey,
} from '../state/config/selectors';

import S3UploadClient from '../helpers/s3Upload';

import MakeRequest from '../request/makeRequest';
import { getEditJobId } from '../state/project/selectors';

export default class PanelBridge {
  handshake = null;

  constructor(store) {
    this.handshake = new Postmate.Model({
      //Generic all routes
      'load.edit.job.id': async data => {
        localStorage.setItem('isAdobePanel', 'true');
        localStorage.setItem('editJobId', get('editJobId')(data));
        store.dispatch(setEditJobId(get('editJobId')(data)));
      },
      //Login
      'on.credentials.saved': async () => {
        window.location = process.env.WORKFLOW_PPRO_PANEL_ROOT;
      },
      //Approvals Route
      'on.active.sequence.id.response': async ({ sequenceId }) => {
        store.dispatch(setSequenceId(sequenceId));

        let editJobId = localStorage.getItem('editJobId');
        this.emit('compare.sequence.source.files', { editJobId });
      },
      'on.sequence.source.files.comparison': async ({ result }) => {
        store.dispatch(setSequenceComparisonResult(result));
      },
      'on.active.sequence.settings.response': async ({ settings }) => {
        store.dispatch(setSequenceSettings(settings));
      },
      'on.xml.file.export': async ({ fileString, approval, platformName }) => {
        const state = store.getState();

        const editRequestStateApiKey = getEditRequestStateApiKey()(state);
        const editRequestStateApiUrl = getEditRequestStateApiUrl()(state);

        let queryParams = ['fields=id,jobName,dueDate,requestedBy,brief,editType,files'];
        const { data } = await MakeRequest({
          endpoint: {
            url: `${editRequestStateApiUrl}/records/${getEditJobId(state)}`,
            xApiKey: editRequestStateApiKey,
          },
          queryParams,
        });

        const { id: editJobId, jobName } = data[0];

        let timestamp = new Date().getTime();

        const file = this.base64StringToFile(fileString, `${timestamp}-project.pproj.xml`);

        if (approval) {
          const path = `${getConformApprovalsPrefix(state)}/${editJobId}-${jobName}/${file.name}`;

          store.dispatch(uploadingXmlFileForApproval({ xmlObjectKey: path, approvalPlatformName: platformName }));

          S3UploadClient.upload({
            file: file,
            path: path,
            bucket: getS3ConformBucket(state),
            progressCallback: percent => {},
            completeCallback: async () => {
              store.dispatch(uploadingXmlFileForApprovalCompleted());
            },
            uploadAccessKeyEndpoint: {
              url: getS3FileCredentialsApiUrl()(state) + '/s3/access',
              xApiKey: getS3FileCredentialsApiKey()(state),
            },
            signedUrlEndpoint: {
              url: getUploadApiUrl()(state) + '/get-upload-signed-url',
              xApiKey: getUploadApiKey()(state),
            },
          });
        } else {
          const path = `${getConformSubmissionPrefix(state)}/${editJobId}-${jobName}/${file.name}`;

          store.dispatch(uploadingXmlFileForSubmission({ xmlObjectKey: path, approvalPlatformName: 'frameio' }));

          S3UploadClient.upload({
            file: file,
            path: path,
            bucket: getS3ConformBucket(state),
            progressCallback: percent => {},
            completeCallback: async () => {
              store.dispatch(uploadingXmlFileForSubmissionCompleted());
            },
            uploadAccessKeyEndpoint: {
              url: getS3FileCredentialsApiUrl()(state) + '/s3/access',
              xApiKey: getS3FileCredentialsApiKey()(state),
            },
            signedUrlEndpoint: {
              url: getUploadApiUrl()(state) + '/get-upload-signed-url',
              xApiKey: getUploadApiKey()(state),
            },
          });
        }
      },
      'on.project.file.export': async ({ fileString, approval, platformName }) => {
        const state = store.getState();

        const editRequestStateApiKey = getEditRequestStateApiKey()(state);
        const editRequestStateApiUrl = getEditRequestStateApiUrl()(state);

        let queryParams = ['fields=id,jobName,dueDate,requestedBy,brief,editType,files'];
        const { data } = await MakeRequest({
          endpoint: {
            url: `${editRequestStateApiUrl}/records/${getEditJobId(state)}`,
            xApiKey: editRequestStateApiKey,
          },
          queryParams,
        });

        const { id: editJobId, jobName } = data[0];

        let timestamp = new Date().getTime();

        const file = this.base64StringToFile(fileString, `${timestamp}-project.prproj`);

        if (approval) {
          const path = `${getConformApprovalsPrefix(state)}/${editJobId}-${jobName}/${file.name}`;

          store.dispatch(
            uploadingProjectFileForApproval({ projectObjectKey: path, approvalPlatformName: platformName })
          );

          S3UploadClient.upload({
            file: file,
            path: path,
            bucket: getS3ConformBucket(state),
            progressCallback: percent => {},
            completeCallback: async () => {
              store.dispatch(uploadingProjectFileForApprovalCompleted());
            },
            uploadAccessKeyEndpoint: {
              url: getS3FileCredentialsApiUrl()(state) + '/s3/access',
              xApiKey: getS3FileCredentialsApiKey()(state),
            },
            signedUrlEndpoint: {
              url: getUploadApiUrl()(state) + '/get-upload-signed-url',
              xApiKey: getUploadApiKey()(state),
            },
          });
        } else {
          const path = `${getConformSubmissionPrefix(state)}/${editJobId}-${jobName}/${file.name}`;

          store.dispatch(
            uploadingProjectFileForSubmission({ projectObjectKey: path, approvalPlatformName: 'frameio' })
          );

          S3UploadClient.upload({
            file: file,
            path: path,
            bucket: getS3ConformBucket(state),
            progressCallback: percent => {},
            completeCallback: async () => {
              store.dispatch(uploadingProjectFileForSubmissionCompleted());
            },
            uploadAccessKeyEndpoint: {
              url: getS3FileCredentialsApiUrl()(state) + '/s3/access',
              xApiKey: getS3FileCredentialsApiKey()(state),
            },
            signedUrlEndpoint: {
              url: getUploadApiUrl()(state) + '/get-upload-signed-url',
              xApiKey: getUploadApiKey()(state),
            },
          });
        }
      },
      //File Download Route
      'progress.indicator.appendProgressBar': async ({ fileName }) => {
        store.dispatch(createFileDownloadProgressBar({ fileName: fileName, percentage: 0 }));
      },
      'progress.indicator.update': async ({ percent, fileName }) => {
        store.dispatch(updateFileDownloadProgressBar({ fileName, percentage: percent }));
      },
      'progress.indicator.error': async ({ fileName }) => {
        store.dispatch(updateFileDownloadProgressBar({ fileName, error: true }));
      },
    });

    this.emit('app.load.edit.job.id');
  }

  emit = async (label, params = {}) => {
    const parent = await this.handshake;
    parent.emit(label, params);
  };

  base64StringToFile = (byteCharacters, filename) => {
    var bstr = atob(byteCharacters),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: 'video/mp4' });
  };
}
