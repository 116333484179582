import styled from 'styled-components';

export const MainCommentContainer = styled.div`
  display: flex;
  width: auto;
  flex-direction: column;
  padding: 10px;
  background-color: #576068;
  margin-top: 10px;
  border-radius: 0.25rem;
`;

export const CommentContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-radius: 0.25rem;
`;

export const CommentStatusContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CommentAuthor = styled.div`
  font-weight: bold;
`;

export const CommentStatusButton = styled.div`
  width: 15px;
  height: 15px;
  border: 1px solid #3e3c3c;
  border-radius: 15px;
  cursor: pointer;
`;

export const CommentRepliesWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: 30px;
  flex-direction: column;
`;

export const CommentReplyContainer = styled.div`
  background-color: #576068;
  width: 95%;
  padding: 10px;
  display: block;
  width: 100%;
  margin-top: 10px;
  border-radius: 0.25rem;
`;

export const TextareaComment = styled.textarea`
  width: 100%;
  padding: 8px;
  background-color: rgb(15, 16, 20);
  border: 0px;
  border-radius: 0.25rem;
  color: white;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: rgba(125, 130, 156, 0.6);
  }
  :-ms-input-placeholder {
    color: rgba(125, 130, 156, 0.6);
  }
  &:focus {
    outline: none;
  }
`;

export const LittleReplyButton = styled.button`
  margin-top: 4px;
  color: rgb(125, 130, 156);
  cursor: pointer;
  user-select: none;
  &:hover {
    color: rgb(184, 193, 207);
  }
  &:active {
    color: rgb(125, 130, 156);
  }
`;
