import MakeRequest from '../../../request';

export default class BucketPrefixDataSource {
  props = null;

  constructor(props) {
    this.props = props;
  }

  init = async () => {
    await this.fetchPrefix();
    this.getNodeFromStorage();
  };

  fetchPrefix = async ({ prefix = '', _prefix = prefix.endsWith('/') ? prefix : prefix + '/', search } = {}) => {
    let queryParams = [`prefix=${_prefix}`];
    if (_prefix != 'root/' && _prefix.startsWith('root/')) {
      queryParams = [`prefix=${_prefix.substring(5)}`];
    } else if (_prefix == 'root/') {
      queryParams = ['prefix=/'];
    }

    if (search) {
      queryParams.push(`search=${search}`);
    }

    const { data } = await MakeRequest({
      endpoint: { url: this.props.url, xApiKey: this.props.xApiKey },
      queryParams: queryParams,
    });
    const response = data;
    const files = response.files.map(file => ({
      id: file.Key,
      name: file.Key.split('/').pop(),
      url: file.Key,
      size: file.Size,
      metadata: file.Metadata,
    }));
    if (search) {
      return files;
    }

    const folders = response.folders.map(folder => {
      const _folder = folder.slice(0, -1);
      return { id: _folder, name: _folder.split('/').pop() };
    });

    localStorage.setItem(
      this.props.mediaBucket + _prefix,
      JSON.stringify({
        id: _prefix,
        children: folders.concat(files),
      })
    );
  };

  getNodeFromStorage = (prefix = '/', _prefix = prefix.endsWith('/') ? prefix : prefix + '/') => {
    _prefix = this.props.mediaBucket + _prefix;
    return JSON.parse(localStorage.getItem(_prefix));
  };

  getFirstNode = () => {
    return this.getNodeFromStorage();
  };

  getNode = async ({ node, id = node.id, backgroundUpdate = true }) => {
    let _node = this.getNodeFromStorage(id);
    if (_node) {
      if (backgroundUpdate) {
        this.fetchPrefix({ prefix: id });
      }
      return _node;
    }
    await this.fetchPrefix({ prefix: id });
    return this.getNodeFromStorage(id);
  };

  getNodeByPrefix = async _prefix => {
    let prefix = _prefix;
    if (prefix.startsWith('root/')) {
      prefix = prefix.substring(5);
    }

    let node = this.getNodeFromStorage(prefix);
    if (node) {
      this.fetchPrefix({ prefix });
      return node;
    } else {
      return await this.getNode({ node: { id: prefix } });
    }
  };

  searchTimer = null;

  searchCallback = null;

  setSearchCallback = searchCallback => (this.searchCallback = searchCallback);

  triggerSearchEvent = searching => this.searchCallback && this.searchCallback(searching);

  getSearchResults = async (currentNode, search) => {
    if (!search) {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
        this.triggerSearchEvent(false);
      }
      const _node = await this.getNode({ node: currentNode, backgroundUpdate: false });
      return _node;
    } else {
      return new Promise(resolve => {
        if (this.searchTimer) {
          clearTimeout(this.searchTimer);
        }

        this.triggerSearchEvent(true);
        this.searchTimer = setTimeout(async () => {
          resolve({
            id: currentNode.id,
            search: true,
            children:
              (await this.fetchPrefix({
                prefix: currentNode.id,
                search,
              })) || [],
          });
          this.searchTimer = null;
          this.triggerSearchEvent(false);
        }, 350);
      });
    }
  };
}
