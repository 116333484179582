import React from 'react';
import { connect } from 'react-redux';
import { getCreateEditRequestApiUrl, getCreateEditRequestApiKey } from '../../../state/config/selectors';
import validateData from '../../../helpers/validateData';
import Modal from '../../../layout/modal';
import UI from '../../../ui-components';
import { SuccessMessageContainer, SuccessTick } from '../../../ui-components/alerts';
import { Spinner } from '../../../ui-components/spinner';
import MakeRequest from '../../../request';
import validationRules from './validationRules';

var x = new Date().getTimezoneOffset() * 60000;
var localISOTime = new Date(Date.now() - x).toISOString().slice(0, 16);

const INIT_STATE_FORM = {
  projectName: '',
  dueDate: localISOTime,
  editType: '',
  brief: '',
};

const INIT_ERROR_FORM = {
  hasError: false,
  errorKey: undefined,
};

const EDIT_TYPES = ['Shoot', 'Distribution', 'Social'];

function CreateEditRequest(props) {
  const initStateForm = { ...INIT_STATE_FORM, ...props.stateForm };
  const [stateFrom, setStateFrom] = React.useState(initStateForm);
  const [isLoading, setLoading] = React.useState(false);
  const [postSuccess, setPostSuccess] = React.useState(false);
  const [{ hasError, errorKey }, setErrorForm] = React.useState(INIT_ERROR_FORM);
  const [isSuccess, setSuccess] = React.useState(false);

  return (
    <React.Fragment>
      {isLoading && (
        <Modal>
          <Spinner />
        </Modal>
      )}

      {isSuccess && (
        <Modal>
          <SuccessTick />
        </Modal>
      )}

      <div style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
        <div style={{ marginTop: 20 }}>
          <div style={{ marginBottom: 30 }}>
            <UI.CardContainer style={{ width: '30%' }}>
              <UI.CardContainerBody>
                <UI.GroupedElement style={{ marginBottom: 20 }}>
                  <UI.Label>Project Name</UI.Label>
                  <UI.InputElement
                    value={stateFrom.projectName}
                    onChange={onChange('projectName')}
                    hasError={errorKey === 'projectName'}
                    disabled={postSuccess}
                    style={{ width: '100%' }}
                  />
                </UI.GroupedElement>

                <UI.GroupedElement style={{ marginBottom: 20 }}>
                  <UI.Label>Due Date</UI.Label>
                  <UI.InputElement
                    type="datetime-local"
                    min={INIT_STATE_FORM.dueDate}
                    value={stateFrom.dueDate}
                    onChange={onChange('dueDate')}
                    hasError={errorKey === 'dueDate'}
                    disabled={postSuccess}
                    style={{ width: '100%' }}
                  />
                </UI.GroupedElement>

                <UI.GroupedElement style={{ marginBottom: 20 }}>
                  <UI.Label>Edit Type</UI.Label>
                  <UI.CheckboxInputWrapper hasError={errorKey === 'editType'} disabled={postSuccess}>
                    {EDIT_TYPES.map(produceCheckBox)}
                  </UI.CheckboxInputWrapper>
                </UI.GroupedElement>

                <UI.GroupedElement>
                  <UI.Label>Brief description</UI.Label>
                  <UI.TextareaElement
                    rows="4"
                    style={{ height: 'auto', border: '1ps solid' }}
                    onChange={onChange('brief')}
                    value={stateFrom.brief}
                    hasError={errorKey === 'brief'}
                    disabled={postSuccess}
                  />
                </UI.GroupedElement>
                <UI.BlockButton onClick={onSubmit} style={{ marginTop: 30 }} disabled={postSuccess}>
                  Create Edit Job
                </UI.BlockButton>
              </UI.CardContainerBody>
            </UI.CardContainer>

            {postSuccess && (
              <SuccessMessageContainer>Edit request[{stateFrom.jobId}] created successfully</SuccessMessageContainer>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  function produceCheckBox(editType) {
    const isChecked = editType === stateFrom.editType;
    return (
      <UI.CheckboxInputWrapper key={editType}>
        <UI.CheckboxInputElement type="radio" value={editType} checked={isChecked} onChange={onChange('editType')} />
        <UI.CheckboxLabelElement>{editType}</UI.CheckboxLabelElement>
      </UI.CheckboxInputWrapper>
    );
  }

  function onChange(stateKey) {
    return evt => {
      const value = evt.target.value;
      setStateFrom({
        ...stateFrom,
        [stateKey]: value,
      });
    };
  }

  async function onSubmit() {
    setLoading(true);
    let jobId;

    const isValid = validateData({
      validationRules,
      data: stateFrom,
      itemResult: (key, pass) => {
        if (pass) {
          return;
        }
        setLoading(false);
        setErrorForm({
          hasError: true,
          errorKey: key,
        });
      },
    });

    if (!isValid) {
      return;
    }
    setErrorForm(INIT_ERROR_FORM);

    try {
      const response = await MakeRequest(
        { endpoint: { url: `${props.createEditRequestApiUrl}/job`, xApiKey: props.createEditRequestApiKey } },
        { method: 'post', data: stateFrom }
      );

      jobId = response.data.insert_id;

      setStateFrom({
        ...stateFrom,
        jobId,
      });
    } catch (error) {
      setLoading(false);
      return window.alert(['Sorry. There was a problem.', 'contact support. Error:', error].join(' '));
    }

    setLoading(false);
    setSuccess(true);
    setPostSuccess(true);
    props.history.push('/edits');

    setTimeout(() => {
      setSuccess(false);
      setStateFrom({ ...INIT_STATE_FORM, jobId });
    }, 2000);
  }
}

export default connect(state => ({
  createEditRequestApiUrl: getCreateEditRequestApiUrl()(state),
  createEditRequestApiKey: getCreateEditRequestApiKey()(state),
}))(CreateEditRequest);
