import { get } from 'lodash/fp';

const xApiKeys = {
  FileSelect: {
    GenerateS3Url: process.env.FILE_SELECT_API_KEY,
    SubmitFiles: process.env.FILE_SELECT_API_KEY,
    GetS3ObjectsTree: process.env.FILE_SELECT_API_KEY,
    GetS3Children: process.env.FILE_SELECT_API_KEY,
    GetS3PathAndBucket: process.env.FILE_SELECT_API_KEY,
    GetSize: process.env.FILE_SELECT_API_KEY,
  },
  EditJob: {
    Create: process.env.CREATE_EDIT_REQUEST_API_KEY,
  },
  EditRequestState: {
    GetDetails: process.env.EDIT_REQUEST_STATE_API_KEY,
  },
  SubmitEditFlow: {
    GetSqsMessage: process.env.SUBMIT_EDIT_FLOW_API_KEY,
    UpdateSqsMessage: process.env.SUBMIT_EDIT_FLOW_API_KEY,
    InitConformProcess: process.env.SUBMIT_EDIT_FLOW_API_KEY,
    CompleteConform: process.env.SUBMIT_EDIT_FLOW_API_KEY,
    FailedConforms: process.env.SUBMIT_EDIT_FLOW_API_KEY,
    RunFailedJob: process.env.SUBMIT_EDIT_FLOW_API_KEY,
    DeleteFailedJob: process.env.SUBMIT_EDIT_FLOW_API_KEY,
  },
  FileUpload: {
    GetJobIds: process.env.UPLOAD_SHOOTS_FLOW_API_KEY,
    GetSignedUrl: process.env.UPLOAD_SHOOTS_FLOW_API_KEY,
  },
  Credentails: {
    GetFilesCredentials: process.env.S3_FILE_CREDENTIALS_API_KEY,
    GetUploadAccessKey: process.env.S3_FILE_CREDENTIALS_API_KEY,
  },
  Events: {
    Save: process.env.STATUS_API_KEY,
    GetAll: process.env.STATUS_API_KEY,
    GetLatest: process.env.STATUS_API_KEY,
    UpdateProgress: process.env.STATUS_API_KEY,
  },
  Auth: {
    GetAccessToken: process.env.AUTH_API_KEY,
    RefreshToken: process.env.AUTH_API_KEY,
  },
  Approval: {
    GetVersions: process.env.APPROVAL_FLOW_API_KEY,
    GetVersionComments: process.env.APPROVAL_FLOW_API_KEY,
    MarkCommentAsComplete: process.env.APPROVAL_FLOW_API_KEY,
  },
  Config: {
    GetFrontEndConfig: process.env.CONFIG_API_KEY,
    GetConfigItem: process.env.CONFIG_API_KEY,
  },
  Info: {
    GetAllEncoders: process.env.INFO_API_KEY,
    PauseAME: process.env.INFO_API_KEY,
    ResumeAME: process.env.INFO_API_KEY,
  },
};

export const getXApiKey = endPointLabel => {
  return get(endPointLabel, xApiKeys);
};
