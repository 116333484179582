import React from 'react';
import { withRouter, matchPath, Link } from 'react-router-dom';
import { AsyncPaginate } from 'react-select-async-paginate';
import { connect } from 'react-redux';
import styled from 'styled-components';
import jwt_decode from 'jwt-decode';
import { compose } from 'lodash/fp';
import MakeRequest from '../request';
import WhiteLink, { WhiteLinkAnchor } from '../ui-components/whiteLink';
import { Body1 } from '../ui-components/typography';
import { PrimaryLinkButton } from '../ui-components/buttons';
import { logoutUser } from '../state/user/actions';
import { getIsAuthenticated } from '../state/user/selectors';
import { getEditRequestStateApiKey, getEditRequestStateApiUrl } from '../state/config/selectors';
import Refresh from './refresh';

const EnvironmentIdentifier = styled.div`
  width: 100%;
  background: ${props => (props.env === 'dev' ? 'red' : 'lightgreen')};
  color: ${props => (props.env === 'dev' ? 'white' : 'black')}
  border-radius: 5px;
  text-align: center;
  padding: 0 3px;
  text-transform: uppercase;
`;

const NavBarBrand = styled(Link)`
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  text-decoration: none;
  color: white;
  &:hover {
    text-decoration: none;
    color: white;
  }
`;

const NavBar = styled.div`
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background-color: #252829;
  border-radius: 0px;
`;

const Spinner = styled.div`
  position: absolute;
  left: calc(50% - 30px);
  top: 50%;
  height: 60px;
  width: 60px;
  margin: 0px auto;
  -webkit-animation: rotation 0.6s infinite linear;
  -moz-animation: rotation 0.6s infinite linear;
  -o-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
  border-left: 6px solid rgba(0, 174, 239, 0.15);
  border-right: 6px solid rgba(0, 174, 239, 0.15);
  border-bottom: 6px solid rgba(0, 174, 239, 0.15);
  border-top: 6px solid rgba(0, 174, 239, 0.8);
  border-radius: 100%;
`;

const TitleHeader = styled.div`
color: white;
font-size: 16px;
padding-right: 30px;
line-height:11px;
`;

// const TitleHeader = styled.span`
//   color: white;
//   font-weight: 600;
//   font-size: 16px;
//   padding-right: 30px;
// `;

class Header extends React.Component {
  state = {
    loading: true,
    disableLinks: true,
    editJobIdOptions: null,
    lastKey: null,
  };

  async componentDidMount() {
    this.setState({ loading: false });
  }

  componentDidUpdate = async prevProps => {
    const {
      isAdobePanel,
      editJobId,
      editRequestStateApiUrl,
      editRequestStateApiKey,
      location: { pathname },
    } = this.props;
    const { isAdobePanel: prevIsAdobePanel, editJobId: prevEditJobId } = prevProps;

    if (isAdobePanel !== prevIsAdobePanel) {
      this.setState({ isAdobePanel: isAdobePanel });
    }

    if (editJobId != 0 && editJobId !== prevEditJobId && matchPath(pathname, '/edit-panel')) {
      const { data } = await await MakeRequest({
        endpoint: { url: `${editRequestStateApiUrl}/records/${editJobId}`, xApiKey: editRequestStateApiKey },
      });

      this.setState({ editJobDetail: data[0], editJobId: editJobId, disableLinks: false, loading: false });
    }
  };

  existsInRecords(id, data) {
    for (var i = 0; i < data.length; i++) {
      if (data[i].id == id) {
        return true;
      }
    }
    return false;
  }

  getUserEmail = () => {
    if (localStorage.getItem('idToken') && localStorage.getItem('idToken') !== 'undefined') {
      let decodedToken = jwt_decode(localStorage.getItem('idToken'));
      return decodedToken.email;
    }
  };

  loadOptions = async (search, loadedOptions) => {
    const { editRequestStateApiKey, editRequestStateApiUrl } = this.props;

    const {
      data: { jobs: editJobIdOptions, lastKey },
    } = await await MakeRequest({
      endpoint: { url: `${editRequestStateApiUrl}/records`, xApiKey: editRequestStateApiKey },
      queryParams: this.state.lastKey ? [`lastKey=${encodeURIComponent(JSON.stringify(this.state.lastKey))}`] : [],
    });

    let selectOptions = editJobIdOptions.map(option => ({
      value: option.id,
      label: `${option.id}-${option.jobName}`,
    }));

    this.setState({ lastKey: lastKey });

    return {
      options: selectOptions,
      hasMore: lastKey !== null,
    };
  };

  onChange = ({ value }) => {
    localStorage.setItem('editJobId', value);
    this.setState({ selectedValue: value });
    this.state.disableLinks && this.setState({ disableLinks: false });
    window.location.reload();
  };

  render() {
    const {
      location: { pathname },
      isAuthenticated,
      logoutUser,
    } = this.props;

    const email = this.getUserEmail();

    return (
      <React.Fragment>
        <NavBar style={{ justifyContent: 'flex-start', paddingRight: 5 }}>
          {/* {<NavBarBrand to={matchPath(pathname, '/edit-panel') ? '/edit-panel' : '/'}>
            <EnvironmentIdentifier env={process.env.ADOBE_EXTENSION_ENV}>
              {process.env.ADOBE_EXTENSION_ENV}
            </EnvironmentIdentifier>
          </NavBarBrand>} */}
          {!matchPath(pathname, '/edit-panel') ? (
            <React.Fragment>
            <img src={require('../static/images/originalsaccess_logo.png')} />
            <TitleHeader>
              <div style={{fontWeight: '600'}}>Editorial In the Cloud </div>
              <div style={{fontSize: '7px',color:'#b4b6b6'}}>brought to you by Production Technology</div>
            </TitleHeader>
              {/* <WhiteLink active={matchPath(pathname, '/file-browser')} to="/file-browser" linkName="Media Browser" />
              <WhiteLink active={matchPath(pathname, '/edits')} to="/edits" linkName="Job List" />
              <WhiteLink active={matchPath(pathname, '/dashboard')} to="/dashboard" linkName="Connected Tools" /> */}

              <WhiteLink active={matchPath(pathname, '/upload')} to="/upload" linkName="Upload" />
              <WhiteLink active={matchPath(pathname, '/editRequest')} to="/editRequest" linkName="Edit Request" />
              <WhiteLink active={matchPath(pathname, '/edits')} to="/edits" linkName="Edit Jobs" />
              <WhiteLink active={matchPath(pathname, '/file-browser')} to="/file-browser" linkName="File Browser" />
              <WhiteLink active={matchPath(pathname, '/dashboard')} to="/dashboard" linkName="Dashboard" />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <img src={require('../static/images/originalsaccess_logo.png')} />
              <TitleHeader>Editorial In the Cloud </TitleHeader>
              <WhiteLinkAnchor
                disabledLink={this.state.disableLinks}
                active={matchPath(pathname, '/edit-panel/file-transfer')}
                to="/edit-panel/file-transfer"
                linkName="Select Files"
              />
              <WhiteLinkAnchor
                disabledLink={this.state.disableLinks}
                active={matchPath(pathname, '/edit-panel/edit-approvals')}
                to="/edit-panel/edit-approvals"
                linkName="Approvals"
              />
              <WhiteLinkAnchor
                disabledLink={this.state.disableLinks}
                active={matchPath(pathname, '/edit-panel/history')}
                to="/edit-panel/history"
                linkName="Timeline"
              />
              {!this.state.isAdobePanel && !this.state.loading && (
                <AsyncPaginate
                  value={this.state.selectedValue}
                  loadOptions={this.loadOptions}
                  onChange={this.onChange}
                  placeholder="Change Selected Job"
                  styles={{
                    control: () => ({
                      display: 'flex',
                      width: 200,
                      background: 'white',
                      color: 'black',
                      marginLeft: 10,
                    }),
                    singleValue: (provided, state) => {
                      const opacity = state.isDisabled ? 0.5 : 1;
                      const transition = 'opacity 300ms';

                      return { ...provided, opacity, transition };
                    },
                  }}
                  isSearchable={false}
                />
              )}
              <Refresh width="16px" height="16px" />
              {this.state.editJobId && (
                <Body1 style={{ marginLeft: 'auto', marginRight: 20 }}>
                  {`Active Job: ${this.state.editJobId}-${this.state.editJobDetail.jobName || 'Loading'}`}
                </Body1>
              )}
            </React.Fragment>
          )}
          {isAuthenticated && (
            <div style={{ marginLeft: 'auto', marginRight: 20 }}>
              <Body1 style={{ marginRight: 20 }}>{email}</Body1>
              <PrimaryLinkButton onClick={logoutUser} style={{ fontSize: 16 }} title="Logout">
                <i className="fa fa-sign-out"></i>
              </PrimaryLinkButton>
            </div>
          )}
        </NavBar>
        {this.state.loading ? (
          <div id="loading" style={{ zIndex: 1 }}>
            <Spinner />
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default compose(
  withRouter,
  connect(
    state => ({
      isAuthenticated: getIsAuthenticated()(state),
      editRequestStateApiUrl: getEditRequestStateApiUrl()(state),
      editRequestStateApiKey: getEditRequestStateApiKey()(state),
    }),
    dispatch => ({
      logoutUser: () => dispatch(logoutUser()),
    })
  )
)(Header);
