import React from 'react';
import styled from 'styled-components';
import Modal from '../../../../layout/modal';
import frameioClient from '../../../../helpers/frameioClient';
import { CardContainer, CardContainerBody, MutedSubtitle, CardTitle } from '../../../../ui-components/card';
import { ProgressBar } from '../../../../ui-components/progressBar';
import { CustomFile, CustomFileInput } from '../../../../ui-components/inputs';
import { PrimaryButtonElement } from '../../../../ui-components/buttons';
import { PrimaryAlert, SuccessAlert, ErrorAlert } from '../../../../ui-components/alerts';
import { Spinner } from '../../../../ui-components/spinner';

const FileLabel = styled.label`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
`;

const splitCamelCase = s => s.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
const pause = t => new Promise(ok => setTimeout(ok, t));

export default class Upload extends React.Component {
  constructor(props) {
    super(props);

    const reviewTypeSelected = localStorage.getItem('reviewType') || 'playerLiason';
    localStorage.setItem('reviewType', reviewTypeSelected);

    this.state = {
      isInsideAdobePanel: false,
      files: [],
      reviewTypeSelected: reviewTypeSelected,
      reviewTypes: ['playerLiason', 'productionManager'],
    };
  }

  componentDidMount = async () => {
    const {
      panelBridge: { handshake },
    } = this.props;

    await handshake;

    this.props.panelBridge.emit('app.editReview.upload.init', {
      page: 'editReviewUpload',
    });

    this.setState({
      isInsideAdobePanel: true,
      reviewTypeSelected: localStorage.getItem('reviewType'),
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isInsideAdobePanel ? this.getAdobePanelVersion() : this.getNormalWebVersion()}
      </React.Fragment>
    );
  }

  getNormalWebVersion = () => {
    const onChange = syntheticEvent => {
      const { files } = syntheticEvent.target;
      this.setState({ files });
    };

    const {
      files: [file],
    } = this.state;

    const buttonStyle = {
      marginTop: 30,
      maxWidth: 200,
      marginRight: 20,
    };

    return (
      <CardContainer>
        <CardContainerBody>
          <div>
            <MutedSubtitle style={{ marginTop: 20, marginBottom: 15 }}>
              Please fill all the fields below and submit for edit review
            </MutedSubtitle>
            {this.props.isUploading && (
              <React.Fragment>
                <Modal />
                {this.getLoader()}
              </React.Fragment>
            )}
            <CustomFile style={{ marginBottom: '1rem' }}>
              <CustomFileInput onChange={onChange} type="file" />
              <FileLabel htmlFor="fileInput" style={{ padding: 0, paddingLeft: 20, paddingTop: 15, fontSize: 14 }}>
                {file ? file['name'] : 'Select file for review'}
              </FileLabel>
            </CustomFile>

            {this.props.approvalPlatforms.length > 0 &&
              this.props.approvalPlatforms.map(
                platform =>
                  platform.enabled && (
                    <PrimaryButtonElement
                      key={platform.name}
                      onClick={() => this.onUploadConfirmClick(platform.name)}
                      style={buttonStyle}
                    >
                      {platform.approvalButtonText}
                    </PrimaryButtonElement>
                  )
              )}

            {this.props.approvalPlatforms.length == 0 && (
              <MutedSubtitle style={{ marginTop: 30 }}>
                No approval platforms enabled for your account. Please contact at{' '}
                <a mailto="support@nekta.io">support@nekta.io</a>
              </MutedSubtitle>
            )}

            {/* <PrimaryButtonElement onClick={this.onUploadConfirmClick} style={{ marginTop: 30 }}>
              Submit For Review
            </PrimaryButtonElement> */}
          </div>
        </CardContainerBody>
      </CardContainer>
    );
  };

  getAdobePanelVersion = () => {
    const { processStarted, cannotSubmit } = this.state;

    const buttonStyle = {
      marginTop: 15,
      width: 'auto',
      marginRight: 20,
      pointerEvents: processStarted || cannotSubmit || this.props.disableButtons ? 'none' : '',
      backgroundColor: processStarted || cannotSubmit || this.props.disableButtons ? 'grey' : '',
      borderColor: processStarted || cannotSubmit || this.props.disableButtons ? 'grey' : '',
      color: processStarted || cannotSubmit || this.props.disableButtons ? '#a2a2a2' : '',
    };

    const initApprovalConform = platformName => {
      this.setState({ processStarted: true });
      this.props.panelBridge.emit('init.approval.conform', {
        reviewType: localStorage.getItem('reviewType'),
        platformName,
      });
    };

    return (
      <CardContainer>
        <CardContainerBody>
          <CardTitle>Approval</CardTitle>
          <MutedSubtitle>Send your active sequence render for approval</MutedSubtitle>

          {this.props.activeSequenceId == 0 && this.props.disableButtons && (
            <MutedSubtitle style={{ marginTop: 15 }}>No active sequence found to start conform process</MutedSubtitle>
          )}

          {this.props.activeSequenceId != 0 && this.props.disableButtons && (
            <MutedSubtitle style={{ marginTop: 15, color: 'red' }}>
              Your sequence contains external files. Please remove external files or upload them and then reimport using
              SELECT FILES to enable conform options
            </MutedSubtitle>
          )}

          {(this.props.isUploading || this.props.waitingForInitConform) && (
            <React.Fragment>
              <Modal />
              <div id="loading">
                <Spinner />
              </div>
              <PrimaryAlert>Uploading files for approval...</PrimaryAlert>
              {this.getLoader()}
            </React.Fragment>
          )}
          {this.props.panelUploadSuccess && !this.props.waitingForInitConform && (
            <SuccessAlert>Successfully sent for approval at {new Date().toLocaleString()}</SuccessAlert>
          )}
          {this.props.panelUploadError && <ErrorAlert>An error has occured. Please try again</ErrorAlert>}
          {this.props.approvalPlatforms.length > 0 &&
            this.props.approvalPlatforms.map(
              platform =>
                platform.enabled && (
                  <PrimaryButtonElement
                    key={platform.name}
                    onClick={() => initApprovalConform(platform.name)}
                    style={buttonStyle}
                    disabled={this.props.disabledButtons}
                  >
                    {platform.approvalButtonText}
                  </PrimaryButtonElement>
                )
            )}

          {this.props.approvalPlatforms.length == 0 && (
            <MutedSubtitle style={{ marginTop: 30, fontSize: 12 }}>
              No approval platforms enabled for your account. Please contact at{' '}
              <a mailto="support@nekta.io">support@nekta.io</a>
            </MutedSubtitle>
          )}
        </CardContainerBody>
      </CardContainer>
    );
  };

  getReviewTypeDropDown = () => {
    return (
      <CardTitle>
        Review Type
        <select
          style={{ marginLeft: 10, fontSize: 14 }}
          onChange={this.onReviewTypeChange}
          value={this.state.reviewTypeSelected}
        >
          {this.state.reviewTypes.map(reviewType => (
            <option key={reviewType} value={reviewType}>
              {splitCamelCase(reviewType)}
            </option>
          ))}
        </select>
      </CardTitle>
    );
  };

  getLoader = () => {
    return (
      <div style={{ marginTop: 20 }}>
        <ProgressBar percentage={this.props.percentage} />
      </div>
    );
  };

  onUploadConfirmClick = async () => {
    const editJobId = localStorage.getItem('editJobId');
    const reviewType = 'playerLiason';

    const {
      files: [file],
    } = this.state;

    if (!file) {
      return window.alert('Please select a file');
    }

    this.setState({ isUploading: true });

    // setState is asynchronous so pause
    // to wait for modal to show up. For
    // sure 100ms is overblown but makes
    // the code look simpler than cb, etc.
    await pause(100);

    const folderId = await frameioClient.createFolderAssetIfNotExist(editJobId, reviewType);

    const payload = {
      name: file.name,
      filesize: file.size,
      properties: { editJobId, reviewType },
    };

    const parent = {
      _parent_asset_id: folderId,
    };

    const asset = await frameioClient.createFileAsset(payload, parent);

    await frameioClient.upload({
      asset,
      file,
      progress: percent => {
        this.setState({ percentage: percent });
      },
    });

    await frameioClient.addAssetToVersionStack(asset.id, editJobId, reviewType);

    this.setState({
      isUploading: false,
      files: [],
    });
  };

  onReviewTypeChange = async syntheticEvent => {
    const reviewType = syntheticEvent.target.value;
    localStorage.setItem('reviewType', reviewType);
    this.setState({ reviewTypeSelected: reviewType });
  };
}
