import React, { Component } from 'react';
import { withRouter } from 'react-router';
import qs from 'qs';
import { InputElement } from '../../ui-components/inputs';
import BreadCrumbs from './breadCrumbs';
import DropzoneUpload from './dropzoneUpload';
import TreeView from './treeView';
import DataSource from './dataSource';

class Navigation extends Component {
  dataSource = null;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      currentNode: null,
      searchTerm: '',
      searchFetching: false,
    };

    this.treeView = React.createRef();
  }

  async componentDidMount() {
    const { fileSelectDataSource, fileSelectApiKey, fileSelectApiUrl, mediaBucket } = this.props;

    const { file, folder, mediaBucket: queryMediaBucket } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });

    if (mediaBucket != '') {
      this.dataSource = new DataSource(fileSelectDataSource, {
        entireBucketEndpoint: {
          url: fileSelectApiUrl + '/s3/' + mediaBucket,
          xApiKey: fileSelectApiKey,
        },
        bucketPrefixEndpoint: {
          url: fileSelectApiUrl + '/s3-children/' + mediaBucket,
          xApiKey: fileSelectApiKey,
        },
        mediaBucket: queryMediaBucket ? queryMediaBucket : mediaBucket,
      });

      await this.dataSource.init();
      this.dataSource.setSearchCallback(this.searchEventHandler);
      let currentNode = this.dataSource.getFirstNode();

      if (folder) {
        currentNode = await this.dataSource.getNodeByPrefix(folder);
      }

      if (file) {
        const fileParts = file.split('/');
        fileParts.pop();
        currentNode = await this.dataSource.getNodeByPrefix(fileParts.join('/'));
        this.props.preview(file);
      }

      this.setState({
        isLoading: false,
        currentNode,
      });
    }
  }

  setLocation = ({ folder, file }) =>
    this.props.history.replace({ search: '?' + qs.stringify({ folder, file, mediaBucket: this.props.mediaBucket }) });

  render() {
    if (this.state.isLoading) {
      return null;
    }

    return (
      <div>
        <div style={{ position: 'relative' }}>
          <InputElement
            placeholder="Search"
            onChange={this.onSearchChange}
            value={this.state.searchTerm}
            style={{ width: '100%', boxSizing: 'border-box' }}
          />
          {this.state.searchFetching ? (
            <i className="fa fa-spinner" style={{ color: 'white', position: 'absolute', right: 10, top: 10 }}></i>
          ) : null}
        </div>
        {/* <div style={{ display: 'flex' }}> */}
        <BreadCrumbs onBreadCrumbClicked={this.onBreadCrumbClicked} path={this.state.currentNode.id} />
        {/* <div style={{ color: '#f69c2d', textAlign: 'right', fontWeight: 600 }} onClick={() => this.onUploadClick()}>
          {' '}
          Upload
        </div> */}
        {/* </div> */}
        <DropzoneUpload currentLocationInBucket={this.state.currentNode.id}>
          <TreeView
            mediaBucket={this.props.mediaBucket}
            nodes={this.state.currentNode.children}
            isSearching={this.state.currentNode.search}
            download={this.props.download}
            onNodeClick={async node => {
              this.setLocation({ folder: node.id });
              this.setState({
                currentNode: await this.dataSource.getNode(node),
                searchTerm: '',
                allFilesToggled: false,
              });
            }}
            preview={(url, type, metadata) => {
              this.setLocation({ file: url });
              this.props.preview(url, type, metadata);
            }}
            ref={this.treeView}
          />
        </DropzoneUpload>
      </div>
    );
  }

  searchEventHandler = fetching => this.setState({ searchFetching: fetching });

  onSearchChange = async e => {
    const searchTerm = e.target.value;

    let searchTermTimestamp = new Date().getTime();
    this.setState({ searchTerm, searchTermTimestamp }, async () => {
      let searchResponse = await this.dataSource.getSearchResults(
        this.state.currentNode,
        searchTerm.length >= 3 ? searchTerm : ''
      );

      const { searchTermTimestamp: stateTimestamp } = this.state;

      if (searchTermTimestamp == stateTimestamp) {
        this.setState({ currentNode: searchResponse });
      }
    });

    this.treeView.current.clearDownloads();
  };

  onSearchChangePromise = async (searchTerm, searchTermTimestamp) => {
    let searchResponse = await this.dataSource.getSearchResults(
      this.state.currentNode,
      searchTerm.length >= 3 ? searchTerm : ''
    );

    if (searchTermTimestamp == this.state.searchTermTimestamp) {
      this.setState({ currentNode: searchResponse });
    }
  };

  onBreadCrumbClicked = async nodeId => {
    this.setLocation({ folder: nodeId });

    let currentNode = await this.dataSource.getNodeByPrefix(nodeId);
    this.setState({
      searchTerm: '',
      currentNode,
    });
    this.treeView.current.clearDownloads();
  };
  onUploadClick = () => {
    this.props.history.push('/upload');
  };
}

export default withRouter(Navigation);
