import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const WhiteLink = props => {
  const LinkComponent = styled(Link)`
    display: block;
    padding: 0.5rem 1rem;
    text-decoration: none;
    background-color: transparent;
    ${props.active ? 'color: #f69c2d;' : 'color:#fff;'}
    &:hover {
      background: transparent;
      text-decoration: none;
      color: #f6c42d;
    }
    ${props.disabledLink ? 'color: #555;pointer-events: none;cursor: default;' : ''}
  `;
  return (
    <div>
      <LinkComponent to={props.to}>{props.linkName}</LinkComponent>
    </div>
  );
};

export const WhiteLinkAnchor = props => {
  const LinkComponent = styled.a`
    font-size: 11px;
    display: block;
    padding: 0.5rem 1rem;
    text-decoration: none;
    background-color: transparent;
    ${props.active ? 'color: #f69c2d;' : 'color:#fff;'}
    &:hover {
      background: transparent;
      text-decoration: none;
      color: #f7a845;
    }
    ${props.disabledLink ? 'color: #555;pointer-events: none;cursor: default;' : ''}
  `;

  return (
    <div>
      <LinkComponent href={props.to}>{props.linkName}</LinkComponent>
    </div>
  );
};

export default WhiteLink;
