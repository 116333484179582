import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { user } from './user/reducer';
import { app } from './app/reducer';
import { config } from './config/reducer';
import { project } from './project/reducer';

import storageMiddleware from './storageMiddleware';

const reducers = combineReducers({ app, user, config, project });

const store = createStore(
  reducers,
  compose(
    applyMiddleware(
      thunk,
      storageMiddleware({
        getItem: key => localStorage.getItem(key),
        setItem: (key, value) => localStorage.setItem(key, value),
        removeItem: key => localStorage.removeItem(key),
        multiGet: keys => keys.map(key => localStorage.getItem(key)),
        multiSet: tuples => tuples.forEach(([key, value]) => localStorage.setItem(key, value)),
        multiRemove: keys => keys.forEach(key => localStorage.removeItem(key)),
        clearAll: () => localStorage.clear(),
      })
    ),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : compose
  )
);

export default store;
