import styled from 'styled-components';
import { PrimaryTextColor } from './colors';
const S = { navBar: {}, itemList: {} };

S.navBar.Nav = styled.div`
  list-style: none;
  padding-top: 10px;
  margin-bottom: 10px;
  // width: 100%;
  // height: 30px;
  // background-color: red;
`;

S.navBar.NavItemContainer = styled.li`
  display: inline;
  cursor: pointer;
  font: inherit;
  font-size: 11px;
  &:not(:first-child):before {
    color: ${PrimaryTextColor};
    display: inline-block;
    content: '/';
    margin: 5px;
    font-size: 11px;
  }
  &:last-child span {
    background-color: rgb(50, 56, 62);
    padding: 0.5rem;
    border-radius: 0.25rem;
    font-size: 11px;
    color: #fff;
  }
`;

S.navBar.NavItemChild = styled.span`
//  color: ${PrimaryTextColor};
  color: #000
`;

S.navBar.NavPreChild = styled.span`
  display: 'inline-block',
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-all',
  font-size: 11px;
`;

S.itemList.ItemListContainer = styled.ul`
  padding: 0.1rem;
  border-radius: 0.4rem;
  font-size: 11px;
  background-color: #dcdcdc;
  height: 680px;
  overflow: auto;
  overflow-x: hidden;
  border: 1px solid #ffbb2c;
  border-style: dashed;

  &.highlight {
    border-color: red;
  }
`;

S.itemList.ItemContainer = styled.li`
  padding: 0.1rem;
  margin-bottom: 0.2rem;
  list-style-type: none;
  cursor: pointer;
 // color: ${PrimaryTextColor};
  color: #000;

  &:hover {
    background: #cbccd2;
  }
`;

S.itemList.LinkButton = styled.div`
  margin: auto;
  text-align: center;
  vertical-align: middle;
  &:active {
    color: green;
  }
`;

S.itemList.IconDiv = styled.div`
  display: inline-grid;
  width: 10%;
  height: 3em;
  text-align: center;

  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em; /* Adjusts for spacing */
  }
`;

export default S;
