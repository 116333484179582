import AWS from 'aws-sdk';
import Evaporate from 'evaporate';
import MakeRequest from '../request';

export const missingEnvError = 'Missing env variable';
export const missingRequiredParams = 'Missing required params';

let _signedUrlEndpoint;
let _uploadAccessKeyEndpoint;

export default {
  upload: async ({
    file,
    path,
    progressCallback,
    completeCallback,
    bucket = process.env['S3_MEDIA'],
    uploadAccessKeyEndpoint,
    signedUrlEndpoint,
  }) => {
    // const awsRegion = process.env['AWS_REGION'];
    // const awsKey = process.env['AWS_KEY'];

    // if (!awsRegion || !awsKey || !bucket) {
    //   return missingEnvError;
    // }

    // if (!file || !path || !progressCallback || !completeCallback) {
    //   return missingRequiredParams;
    // }

    console.log({ uploadAccessKeyEndpoint, signedUrlEndpoint });

    const evaporate = await initEvaporate(bucket, uploadAccessKeyEndpoint, signedUrlEndpoint);
    return evaporate.add({
      name: path,
      file: file,
      progress: progressCallback,
      complete: completeCallback,
    });
  },
};

export const initEvaporate = async (bucket, uploadAccessKeyEndpoint, signedUrlEndpoint) => {
  _uploadAccessKeyEndpoint = uploadAccessKeyEndpoint;
  _signedUrlEndpoint = signedUrlEndpoint;

  const {
    data: {
      response: { AccessKeyId: aws_key },
    },
  } = await MakeRequest({ endpoint: _uploadAccessKeyEndpoint });

  return await Evaporate.create({
    awsRegion: process.env['AWS_REGION'],
    // signerUrl: undefined,
    aws_key: aws_key,
    bucket,
    cloudfront: true,
    computeContentMd5: true,
    cryptoMd5Method: function(data) {
      return AWS.util.crypto.md5(data, 'base64');
    },
    cryptoHexEncodedHash256: function(data) {
      return AWS.util.crypto.sha256(data, 'hex');
    },
    logging: false,
    s3FileCacheHoursAgo: 1,
    allowS3ExistenceOptimization: true,
    customAuthMethod: await signingMethod,
    s3Acceleration: window.location,
  });
};

async function signingMethod(_signParams, _signHeaders, valueString, dateString) {
  let queryParams = [`valueString=${valueString}`, `dateString=${dateString}`];
  const { data } = await MakeRequest({ endpoint: _signedUrlEndpoint, queryParams: queryParams });
  return data.signingKey;
}
