import React from 'react';
import styled from 'styled-components';
import PanelBridge from '../../helpers/panelBridge';

const EditPlaceholder = styled.h5`
  margin-top: 3rem;
  text-transform: uppercase;
  color: #6c757d;
`;

export default class Edit extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: 'calc(100vh - 40px)',
          flexDirection: 'column',
        }}
      >
        {localStorage.getItem('editJobId') ? null : (
          <EditPlaceholder>Please select edit job to proceed</EditPlaceholder>
        )}
        <h1>NEKTA</h1>
      </div>
    );
  }
}
