import React from 'react';
import moment from 'moment';
import {
  MainCommentContainer,
  CommentContainer,
  CommentStatusContainer,
  CommentAuthor,
  CommentStatusButton,
} from '../../../../ui-components/comments';

export default function MainComment(props) {
  const comment = props.comment;
  const platform = props.platform;

  const scrubProjectTimeline = async () => {
    let seconds;

    if (platform == 'frameio') {
      seconds = comment['frame'] / comment['version']['fps'];
    }

    if (platform == 'moxion') {
      seconds = comment['marker_time'];
    }

    global.pproBridge.emit('app.editReview.comments.scrubTo', { seconds });
  };

  return (
    <MainCommentContainer key={comment.id}>
      <CommentContainer onClick={scrubProjectTimeline}>
        <div>
          <CommentAuthor>
            {(comment['owner'] || comment['anonymous_user'])['name']}
            <span style={{ color: 'grey' }}>
              {' - '}
              {moment(comment['inserted_at']).fromNow()}
            </span>
          </CommentAuthor>
          <div style={{ marginTop: 5 }}>
            <span style={{ color: '#ffba2d', fontWeight: 'bold' }}>
              {platform == 'frameio' && formatSeconds(comment['frame'] / comment['version']['fps'])}
              {platform == 'moxion' && formatSeconds(comment['marker_time'])}
            </span>{' '}
            {comment['text']}
          </div>
        </div>
        {platform == 'frameio' && <CommentStatusContainer>{getStatus(comment.completed)}</CommentStatusContainer>}
      </CommentContainer>
    </MainCommentContainer>
  );

  function getStatus(isCompleted) {
    const color = isCompleted ? '#ffba2d' : 'grey';
    return <CommentStatusButton style={{ backgroundColor: color }} onClick={() => props.onComplete(comment)} />;
  }

  function formatSeconds(seconds) {
    const numSec = Math.floor(Number(seconds));
    const min = addPadding(Math.floor(numSec / 60).toFixed(0));
    const sec = addPadding((numSec % 60).toFixed(0));
    return min + ':' + sec;
  }

  function addPadding(string) {
    return string.length < 2 ? '0' + string : string;
  }
}
