export default ({ data, validationRules, itemResult = () => {} }) => {
  let valid = true;
  for (let key in data) {
    if (validationRules[key]) {
      if (!validationRules[key](data[key])) {
        itemResult(key, false);
        valid = false;
      } else {
        itemResult(key, true);
      }
    }
  }
  return valid;
};
