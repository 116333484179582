import React from 'react';
import { UploadProgressBar } from '../../ui-components/progressBar';

export default function SingleUpload(props) {
  const didMountRef = React.useRef(false);
  const [percent, setPercent] = React.useState(0);

  React.useEffect(() => {
    const useEffectCleanUp = () => {};

    const isInitalRender = !didMountRef.current;
    if (isInitalRender) {
      didMountRef.current = true;
      return useEffectCleanUp;
    }

    const localFilePath = props.file['webkitRelativePath'];
    const projectBasePath = props['basePath'];

    const fullPath = [projectBasePath, localFilePath ? localFilePath : props.file.name].join('/');

    // Evaporate.prototype.add()
    // Docs: https://git.io/JeDEh
    props.evaporateInst.add({
      name: fullPath,
      file: props.file,
      started: () => {},
      progress: onProgress,
      complete: () => {},
    });

    return useEffectCleanUp;
  }, [props.evaporateInst]);

  function onProgress(newPercent) {
    setPercent(newPercent * 100);
  }

  return <UploadProgressBar percent={percent} fileName={props.file.name} />;
}
