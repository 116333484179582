/* eslint-disable no-redeclare */
import { set, get, merge, flow } from 'lodash/fp';
import {
  FETCH_EDIT_JOB_ID_FAILURE,
  FETCH_EDIT_JOB_ID_SUCCESS,
  FETCH_EDIT_JOB_ID_REQUEST,
  SET_SEQUENCE_ID,
  SET_SEQUENCE_SOURCE_COMPARISON_RESULT,
  SET_SEQUENCE_SETTINGS,
  CREATE_FILE_DOWNLOAD_PROGRESS_BAR,
  UPDATE_FILE_DOWNLOAD_PROGRESS_BAR,
  UPLOADING_PROJECT_FILE_FOR_APPROVAL_SUCCESS,
  UPLOADING_PROJECT_FILE_FOR_APPROVAL_REQUEST,
  UPLOADING_XML_FILE_FOR_APPROVAL_REQUEST,
  UPLOADING_XML_FILE_FOR_APPROVAL_SUCCESS,
  UPLOADING_PROJECT_FILE_FOR_SUBMISSION_REQUEST,
  UPLOADING_PROJECT_FILE_FOR_SUBMISSION_SUCCESS,
  UPLOADING_XML_FILE_FOR_SUBMISSION_REQUEST,
  UPLOADING_XML_FILE_FOR_SUBMISSION_SUCCESS,
} from './actions';

export const project = (state = { loadingEditJobId: false, loaded: false, progressBars: [] }, action) => {
  switch (action.type) {
    case FETCH_EDIT_JOB_ID_REQUEST:
      return set('loadingEditJobId', true)(state);
    case FETCH_EDIT_JOB_ID_FAILURE:
      return set('loadingEditJobId', false)(state);
    case SET_SEQUENCE_ID:
    case SET_SEQUENCE_SETTINGS:
    case FETCH_EDIT_JOB_ID_SUCCESS:
    case SET_SEQUENCE_SOURCE_COMPARISON_RESULT:
      return flow(
        get('editJob'),
        merge(state),
        set('loadingEditJobId', false),
        set('loaded', true)
      )(action);

    case CREATE_FILE_DOWNLOAD_PROGRESS_BAR:
      var progressBar = get('progressBar')(action);
      var progressBars = state.progressBars.slice(0);
      progressBars.push({ fileName: progressBar.fileName, percentage: 0 });
      return set('progressBars', progressBars)(state);

    case UPDATE_FILE_DOWNLOAD_PROGRESS_BAR:
      var progressBar = get('progressBar')(action);
      var progressBars = state.progressBars.slice(0);

      progressBars.forEach((bar, idx) => {
        if (progressBar.fileName == bar.fileName) {
          if (progressBar.percentage) {
            progressBars[idx].percentage = progressBar.percentage;
          }
          if (progressBar.error == true) {
            progressBars[idx].error = true;
          }
        }
      });

      return set('progressBars', progressBars)(state);

    case UPLOADING_PROJECT_FILE_FOR_APPROVAL_REQUEST:
    case UPLOADING_PROJECT_FILE_FOR_APPROVAL_SUCCESS:
    case UPLOADING_XML_FILE_FOR_APPROVAL_REQUEST:
    case UPLOADING_XML_FILE_FOR_APPROVAL_SUCCESS:
    case UPLOADING_PROJECT_FILE_FOR_SUBMISSION_REQUEST:
    case UPLOADING_PROJECT_FILE_FOR_SUBMISSION_SUCCESS:
    case UPLOADING_XML_FILE_FOR_SUBMISSION_REQUEST:
    case UPLOADING_XML_FILE_FOR_SUBMISSION_SUCCESS:
      return flow(
        get('editJob'),
        merge(state)
      )(action);
    default:
      return state;
  }
};
