import React from 'react';
import styled from 'styled-components';
import S from '../../ui-components/treeView';

function BreadCrumbs(props = {}) {
  const Crumb = styled.div`
    display: inline-block;
    white-space: pre-wrap;
    word-break: break-all;
    position: relative;
    border-radius: 0.25rem;

    &:before {
      background-color: black;
      content: '${({ folder }) => folder}';
      display: none;
      position: absolute;
      top: 30px;
      left: -5px;
      width: max-content;
      text-align: center;
      padding: 5px;
      color:#fff;
    }
    &:hover:before {
      display: block;
    }
  `;

  let path = props.path.startsWith('/') ? props.path.substring(1) : props.path;
  const pathParts = path.endsWith('/') ? path.split('/').slice(0, -1) : path.split('/');

  if (pathParts[0] === '') {
    pathParts[0] = 'root';
  }

  if (pathParts[0] !== 'root') {
    pathParts.unshift('root');
  }

  return (
    <S.navBar.Nav>
      {pathParts.map((folder, i) => {
        return (
          <S.navBar.NavItemContainer key={folder}>
            <S.navBar.NavItemChild
              role="breadElm"
              onClick={() =>
                props.onBreadCrumbClicked(
                  pathParts
                    .slice(0, i)
                    .concat([folder])
                    .join('/')
                )
              }
            >
              <Crumb folder={folder}>{folder.replace(/(.{7})..+/, '$1…')}</Crumb>
            </S.navBar.NavItemChild>
          </S.navBar.NavItemContainer>
        );
      })}
    </S.navBar.Nav>
  );
}

export default BreadCrumbs;
