import { get } from 'lodash/fp';

export const getIsConfigLoaded = () => state => get('config.loaded')(state);

//Api Url and Keys
export const getEditRequestStateApiUrl = () => state => get('config.editRequestStateService.value.apiUrl')(state);
export const getEditRequestStateApiKey = () => state => get('config.editRequestStateService.value.x-api-key')(state);

export const getAuthorizationApiUrl = () => state => get('config.authorizationService.value.apiUrl')(state);
export const getAuthorizationApiKey = () => state => get('config.authorizationService.value.x-api-key')(state);

export const getEditRequestApiUrl = () => state => get('config.editRequestService.value.apiUrl')(state);
export const getEditRequestApiKey = () => state => get('config.editRequestService.value.x-api-key')(state);

export const getSubmitApiUrl = () => state => get('config.submitService.value.apiUrl')(state);
export const getSubmitApiKey = () => state => get('config.submitService.value.x-api-key')(state);

export const getS3FileCredentialsApiUrl = () => state => get('config.s3FileCredentialsService.value.apiUrl')(state);
export const getS3FileCredentialsApiKey = () => state => get('config.s3FileCredentialsService.value.x-api-key')(state);

export const getStatusApiUrl = () => state => get('config.statusService.value.apiUrl')(state);
export const getStatusApiKey = () => state => get('config.statusService.value.x-api-key')(state);

export const getCreateEditRequestApiUrl = () => state => get('config.createEditRequestService.value.apiUrl')(state);
export const getCreateEditRequestApiKey = () => state => get('config.createEditRequestService.value.x-api-key')(state);

export const getUploadApiUrl = () => state => get('config.uploadService.value.apiUrl')(state);
export const getUploadApiKey = () => state => get('config.uploadService.value.x-api-key')(state);

export const getEditFileSelectApiUrl = () => state => get('config.editFileSelectService.value.apiUrl')(state);
export const getEditFileSelectApiKey = () => state => get('config.editFileSelectService.value.x-api-key')(state);

export const getInfoApiUrl = () => state => get('config.infoService.value.apiUrl')(state);
export const getInfoApiKey = () => state => get('config.infoService.value.x-api-key')(state);

export const getApprovalFlowApiUrl = () => state => get('config.approvalFlowService.value.apiUrl')(state);
export const getApprovalFlowApiKey = () => state => get('config.approvalFlowService.value.x-api-key')(state);

//Other Configs
export const getS3Config = () => state => get('config.s3Config.value')(state);

export const getMediaBucketList = () => state => get('config.mediaBucketList.value')(state);

export const getAccountName = () => state => get('config.accountName.value')(state);

export const getFileSelectDataSource = () => state => get('config.fileSelectDataSource.value')(state);

export const getFileExtensions = () => state => get('config.fileExtensions.value')(state);

export const getEnv = () => state => get('config.env.value')(state);

export const getCommentsPlatform = () => state => get('config.commentsPlatform.value')(state);

export const getStatusMessages = () => state => get('config.statusMessages.value')(state);

//Conform Prefixes
export const getConformApprovalDeliveryPrefix = state =>
  get('config.submitService.value.conform-approvals-delivery-prefix')(state);
export const getConformSubmissionDeliveryPrefix = state =>
  get('config.submitService.value.conform-submission-delivery-prefix')(state);
export const getConformApprovalsPrefix = state => get('config.submitService.value.conform-approvals-prefix')(state);
export const getConformSubmissionPrefix = state => get('config.submitService.value.conform-submission-prefix')(state);

//Buckets
export const getS3ConformBucket = state => get('config.s3Config.value.conformBucket')(state);
