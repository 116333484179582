import React from 'react';
import styled from 'styled-components';

const S = {};

S.Svg = styled.svg`
  cursor: pointer;
  background-color: transparent;
  border-radius: 10%;
  margin-left: 10px;
  path {
    fill: #f69c2d;
  }
  &:hover {
    background-color: transparent;
    path {
      fill: #f6b02d;
    }
  }
  &:active {
    background-color: transparent;
    path {
      fill: #f6b02d;
    }
  }
`;

export default function Refresh(props = {}) {
  const svgProps = {
    width: props.width || 50,
    height: props.height || 50,
    style: { padding: props.padding || 5 },
    x: 0,
    y: 0,
    viewBox: '0 0 512 512',
    onClick: () => window.location.reload(),
  };

  return (
    <S.Svg {...svgProps}>
      <g>
        <path d="M479.971,32.18c-21.72,21.211-42.89,43-64.52,64.301c-1.05,1.23-2.26-0.16-3.09-0.85   c-24.511-23.98-54.58-42.281-87.221-52.84c-37.6-12.16-78.449-14.07-117.029-5.59c-68.67,14.67-128.811,64.059-156.44,128.609   c0.031,0.014,0.062,0.025,0.093,0.039c-2.3,4.537-3.605,9.666-3.605,15.1c0,18.475,14.977,33.451,33.451,33.451   c15.831,0,29.084-11.002,32.555-25.773c19.757-41.979,58.832-74.445,103.967-85.527c52.2-13.17,111.37,1.33,149.4,40.041   c-22.03,21.83-44.391,43.34-66.33,65.26c59.52-0.32,119.06-0.141,178.59-0.09C480.291,149.611,479.931,90.891,479.971,32.18z" />
        <path d="M431.609,297.5c-14.62,0-27.041,9.383-31.591,22.453c-0.009-0.004-0.019-0.008-0.027-0.012   c-19.11,42.59-57.57,76.219-102.84,88.18c-52.799,14.311-113.45,0.299-152.179-39.051c21.92-21.76,44.369-43.01,66.189-64.869   c-59.7,0.049-119.41,0.029-179.11,0.01c-0.14,58.6-0.159,117.189,0.011,175.789c21.92-21.91,43.75-43.91,65.79-65.699   c14.109,13.789,29.76,26.07,46.92,35.869c54.739,31.971,123.399,38.602,183.299,17.891   c57.477-19.297,106.073-63.178,131.212-118.318c3.645-5.357,5.776-11.824,5.776-18.793C465.06,312.477,450.083,297.5,431.609,297.5   z" />
      </g>
    </S.Svg>
  );
}
