import { loadConfig } from '../config/actions';
import { getItem, removeItem } from '../storageMiddleware';

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';

export const RESTORE_LOGIN_REQUEST = 'RESTORE_LOGIN_REQUEST';
export const RESTORE_LOGIN_SUCCESS = 'RESTORE_LOGIN_SUCCESS';
export const RESTORE_LOGIN_FAILURE = 'RESTORE_LOGIN_FAILURE';

export const restoreLogin = () => async dispatch => {
  dispatch({ type: RESTORE_LOGIN_REQUEST });
  const [accessToken, refreshToken, idToken, isAuthenticatedAsString] = await Promise.all([
    dispatch(getItem('accessToken')),
    dispatch(getItem('refreshToken')),
    dispatch(getItem('idToken')),
    dispatch(getItem('isAuthenticated')),
  ]);

  const isAuthenticated = Boolean(isAuthenticatedAsString);

  try {
    if (accessToken != null) {
      dispatch({
        type: RESTORE_LOGIN_SUCCESS,
        accessCredentials: { accessToken, refreshToken, idToken, isAuthenticated },
      });
    } else {
      dispatch({ type: RESTORE_LOGIN_FAILURE });
      dispatch(logoutUser({ hasExplicitlyLoggedOut: false }));
    }
  } catch (err) {
    dispatch({ type: RESTORE_LOGIN_FAILURE });
    dispatch(logoutUser({ hasExplicitlyLoggedOut: false }));
  }
};

export const loginUser = accessCredentials => async dispatch => {
  await dispatch({ type: USER_LOGIN, accessCredentials });
  await dispatch(loadConfig());
};

export const logoutUser = () => async dispatch => {
  await dispatch(removeItem('isAuthenticated'));
  await dispatch(removeItem('accessToken'));
  await dispatch(removeItem('idToken'));
  await dispatch(removeItem('refreshToken'));
  dispatch({ type: USER_LOGOUT });
};
