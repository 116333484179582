import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'lodash/fp';
import styled from 'styled-components';
import { getIsAuthenticated } from '../state/user/selectors';
import { getIsConfigLoaded } from '../state/config/selectors';
import Header from './header';
import Footer from './footer';
import Modal from './modal';

const SearchingText = styled.h4`
  color: white;
  font-size: 18px;
`;

const UnsupportedBrowserContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 65vh;
`;

const UnsupportedBrowserInfoText = styled.p`
  font-size: 18px;
  color: white;
  text-align: center;
`;

const UnsupportedBrowserInfoTitle = styled.h1`
  font-size: 28px;
  color: white;
  text-align: center;
  text-transform: uppercase;
`;

const SupportedBrowserOptions = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  width: 40%;
  justify-content: space-evenly;
  align-items: center;
`;

const SupportedBrowserContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SupportedBrowserImage = styled.img`
  width: 150px;
`;

const SupportedBrowserLink = styled.a`
  text-transform: uppercase;
  margin-top: 20px;
  text-decoration: underline;
`;

class Layout extends React.Component {
  unsupportedBrowser = false;

  constructor(props) {
    super(props);
    this.state = {
      progressBars: [],
      loadingEditJobId: true,
      editJobId: 0,
      isAdobePanel: false,
      isLoginPage: true,
      isAuthenticated: false,
    };

    // Internet Explorer 6-11
    const isIE = /*@cc_on!@*/ false || !!document.documentMode;

    // Edge 20+
    // const isEdge = !isIE && !!window.StyleMedia;

    if (isIE) {
      this.unsupportedBrowser = true;
    }
  }

  lookupEditJobIdInLocalStorage = () => {
    let editJobId = localStorage.getItem('editJobId');
    if (!editJobId) {
      return;
    } else {
      clearInterval(this.searchEditJobIdInterval);
      this.setState({
        editJobId: localStorage.getItem('editJobId'),
        isAdobePanel: false,
        loadingEditJobId: false,
      });
    }
  };

  lookupIsAdobePanelInLocalStorage = () => {
    let isAdobePanel = localStorage.getItem('isAdobePanel');
    if (!isAdobePanel) {
      return;
    } else {
      clearInterval(this.searchIsAdobePanelInterval);
      this.setState({
        editJobId: localStorage.getItem('editJobId'),
        isAdobePanel: true,
        loadingEditJobId: false,
      });
    }
  };

  componentDidUpdate = async prevProps => {
    const { isAuthenticated, history } = this.props;
    const { isAuthenticated: prevIsAuthenticated } = prevProps;

    if (prevIsAuthenticated !== isAuthenticated && !isAuthenticated) {
      history.replace('/login');
    }
  };

  componentDidMount = async () => {
    let isUserLoggedIn = await this.isUserLoggedIn();

    if (!isUserLoggedIn) {
      this.redirectToLoginPage();
    } else {
      this.setState({ isAuthenticated: true });
      if (window.location.href === process.env.WORKFLOW_ROOT + '/login') {
        window.location = process.env.WORKFLOW_ROOT;
      }

      this.setState({ isLoginPage: false });

      this.searchEditJobIdInterval = setInterval(this.lookupEditJobIdInLocalStorage, 100);
      this.searchIsAdobePanelInterval = setInterval(this.lookupIsAdobePanelInLocalStorage, 100);

      setTimeout(() => {
        clearInterval(this.searchEditJobIdInterval);
        clearInterval(this.searchIsAdobePanelInterval);

        this.setState({ loadingEditJobId: false });
      }, 3000);
    }
  };

  redirectToLoginPage = () => {
    if (!window.location.href.includes('/login')) {
      this.props.history.push('/login');
    } else {
      this.setState({ isLoginPage: true });
    }
  };

  isUserLoggedIn = async () => {
    if (localStorage.getItem('isAuthenticated') && localStorage.getItem('isAuthenticated') == 'true') {
      return true;
    }
    return false;
  };

  componentWillUnmount = () => {
    clearInterval(this.searchEditJobIdInterval);
    clearInterval(this.searchIsAdobePanelInterval);
  };

  render() {
    const { children, isAuthenticated: isAuthenticatedProp, isConfigLoaded } = this.props;
    const { editJobId, loadingEditJobId, isAdobePanel, isLoginPage } = this.state;

    return (
      <React.Fragment>
        {!this.unsupportedBrowser ? (
          <React.Fragment>
            {isAuthenticatedProp && isConfigLoaded && (
              <Header editJobId={editJobId} isAdobePanel={isAdobePanel} loadingEditJobId={loadingEditJobId} />
            )}
            {!isLoginPage && loadingEditJobId && (
              <Modal>
                <SearchingText>Searching for edit job id...</SearchingText>
              </Modal>
            )}
            {children}
            <Footer />
          </React.Fragment>
        ) : (
          <UnsupportedBrowserContainer>
            <UnsupportedBrowserInfoTitle>Unsupported Browser</UnsupportedBrowserInfoTitle>
            <UnsupportedBrowserInfoText>
              You are using a browser which is not supported by our system yet.
              <br />
              Pleaes use one of these options to improve your experince in our system
            </UnsupportedBrowserInfoText>
            <SupportedBrowserOptions>
              <SupportedBrowserContainer>
                <SupportedBrowserImage src={require('../static/images/chrome.png')} />
                <SupportedBrowserLink href="https://www.google.com/search?q=download+google+chrome" target="_blank">
                  Google Chrome
                </SupportedBrowserLink>
              </SupportedBrowserContainer>

              <SupportedBrowserContainer>
                <SupportedBrowserImage src={require('../static/images/firefox.jpg')} />
                <SupportedBrowserLink href="https://www.google.com/search?q=download+mozilla+firefox" target="_blank">
                  Mozilla Firefox
                </SupportedBrowserLink>
              </SupportedBrowserContainer>
            </SupportedBrowserOptions>
          </UnsupportedBrowserContainer>
        )}
      </React.Fragment>
    );
  }
}

export default compose(
  withRouter,
  connect(state => ({
    isAuthenticated: getIsAuthenticated()(state),
    isConfigLoaded: getIsConfigLoaded()(state),
  }))
)(Layout);
