import React from 'react';

export default class Home extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 40px)',
          color: 'white',
        }}
      >
        <h1>NEKTA MEDIA WORKFLOWS</h1>
      </div>
    );
  }
}
