import styled from 'styled-components';
import { PrimaryTextColor } from './colors';

export const CardContainer = styled.div`
  padding: 1.25rem;
  flex: 1 1 auto;
  font-size: 12px;
  // background: #252829;
  background: #fff;

  color: ${PrimaryTextColor};
  border-radius: 0.25rem;
  width: 50%;
  margin: auto;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  box-shadow: 0 15px 15px rgba(182, 182, 182, 0.75);
`;

export const CardContainerBody = styled.div`
  padding: 1.25rem;
  flex: 1 1 auto;
`;

export const CardSubtitle = styled.h6`
  margin-top: -0.375rem;
  margin-bottom: 0;
  color: #6c757d !important;
`;

export const CardTitle = styled.h3`
  font-size: 14px;
  margin-bottom: 15px;
`;

export const MutedSubtitle = styled(CardSubtitle)`
  color: #6c757d !important;
`;

export const CardLightBackground = styled.div`
  background-color: #f9fafa;
  color: #000;
  max-height: 150px;
  overflow: auto;
  margin-bottom: 15px;
  div {
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
  }
`;
