import styled from 'styled-components';
import { PrimaryTextColor } from './colors';

export const TableRow = styled.tr`
  padding: 10px;
  &:nth-child(even) {
    background-color: #eeeff6;
  }
`;

export const TableHeaderRow = styled.tr`
  background-color: #fff;
`;

export const TableCell = styled.td`
  vertical-align: middle;
  &:nth-last-child {
    padding-right: 0;
  }

  padding: ${({ history }) => (history ? '10px 20px' : '10px')};
`;

export const TableContainer = styled.div`
  overflow-x: auto;
  color: #000;
  text-align: left;
`;

export const TableHeaderCell = styled.th`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  padding: 15px;
  color: #000;
`;

export const TableWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  color: #fff;
`;

export const PaginatedTableContainer = styled.div`
  overflow-x: auto;
  color: #fff;
  text-align: left;
  height: 500px;
  width: 950px;
`;

export const PaginatedTableHeaderRow = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  height: 60px;
  background: #fff;
  align-items: center;
  font-weight: 600;
  width: 950px;
  border-bottom: 1px solid #b1b1b1;
  color: #000;
`;

export const PaginatedTableHeaderColumn = styled.div`
  width: 150px;

`;

export const PaginatedTableRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 60px;
  align-items: center;
  background: ${({ isEven }) => (isEven ? '#fff' : '#f8f8fb')};
  border-bottom: 1px solid #b1b1b1;
  color: #000;
  width: 950px;
`;

export const PaginatedTabelCell = styled.div`
  word-break: break-all;
  width: 150px;
  padding-right:10px;

    ${({ ellipsis }) => (ellipsis ? 'white-space: nowrap; overflow: hidden; text-overflow:  ellipsis;' : '')};
`;
