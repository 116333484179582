import React from 'react';
import styled from 'styled-components';

const Bar = styled.div`
  position: relative;
  height: ${props => (!props.small ? '20px' : '10px')};
  width: 100%;
  border-radius: ${props => (!props.small ? '25px' : '10px')};
  border: 1px solid ${props => props.mainColour};
`;

const Fill = styled.div.attrs(({ percentage, mainColour }) => ({
  style: {
    width: `${percentage}%`,
    background: `${mainColour}`,
  },
}))`
  height: 100%;
  border-radius: inherit;
  transition: width 0.2s ease-in;
  color: white;
`;

const Text = styled.div`
  padding-left: 10px;
  line-height: 20px;
`;

export const ProgressBar = ({ percentage, small = false, error = false, text = null }) => {
  let mainColour = percentage == 100 ? '#0e537d' : '#66b55f';
  const errorRed = '#d9534f';

  if (error) {
    mainColour = errorRed;
  }

  return (
    <Bar small={small} mainColour={mainColour}>
      <Fill percentage={percentage} mainColour={mainColour}>
        {text && <Text>{text}</Text>}
      </Fill>
    </Bar>
  );
};

export const UploadProgressBar = ({ percent, fileName }) => {
  const s = {
    container: {
      backgroundColor: 'rgba(255, 255, 0, 0.1)',
      position: 'relative',
      marginBottom: 3,
      borderRadius: 2,
    },
    progress: {
      width: percent + '%',
      backgroundColor: '#FF5722',
      height: 20,
      borderRadius: 2,
    },
    textPercent: {
      fontSize: 8,
      color: 'white',
      position: 'absolute',
      right: 7,
      top: 0,
      fontWeight: 'bold',
    },
    textName: {
      fontSize: 8,
      color: 'white',
      position: 'absolute',
      left: 7,
      top: 0,
      fontWeight: 'bold',
    },
  };

  return (
    <div style={s.container}>
      <div style={s.progress} />
      <p style={s.textName}>{fileName}</p>
      <p style={s.textPercent}>{`${percent.toFixed(1)}%`}</p>
    </div>
  );
};
