import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import CreatableSelect from 'react-select/creatable';
import {
  CardContainer,
  CardContainerBody,
  CardSubtitle,
  CardTitle,
  CardLightBackground,
} from '../../ui-components/card';
import MakeRequest from '../../request';
import { InputElement, SelectElement, Label } from '../../ui-components/inputs';
import { BlockButton } from '../../ui-components/buttons';
import { getUploadApiKey, getUploadApiUrl } from '../../state/config/selectors';
import MultiUpload from './multi-upload';
import { PrimaryTextColor } from '../../ui-components/colors';

const S = {};
S.Button = BlockButton;

S.TwoColumns = styled.div`
  display: flex;
  flex-direction: row;
  > div {
    width: 100%;
    &:nth-child(1) {
      padding-right: 10px;
    }
    &:nth-child(2) {
      padding-left: 10px;
    }
  }
`;

S.IndividualColumn = styled.div`
  width: 100%;
  display: inline-block;
  backgroundcolor: #dcdcdc !important;
  border: '1px solid #b4b6b6';
  @media (max-width: 320px) {
    width: 100%;
    display: block;
  }
`;

const CAMERA_TYPES = [
  { value: 'DEFAULT', text: 'Select a camera type...' },
  { value: 'FS7A', text: 'FS7A' },
  { value: 'FS7B', text: 'FS7B' },
  { value: 'FS7C', text: 'FS7C' },
  { value: 'FS5', text: 'FS5' },
  { value: 'GOPRO', text: 'GOPRO' },
  { value: 'DSLR', text: 'DSLR' },
  { value: 'Audio', text: 'Audio' },
  { value: 'images', text: 'images' },
  { value: 'OTHER', text: 'OTHER' },
];

const LOCATIONS = [
  { value: 'DEFAULT', text: 'Select from the dropdown...' },
  { value: 'REMOTE', text: 'Remote Location' },
  { value: 'OFFICE', text: 'The Office' },
];

const UPLOAD_TYPES = [
  { value: 'shoots', label: 'Shoot' },
  { value: 'external/videos', label: 'External Videos' },
  { value: 'external/images', label: 'External Images' },
  { value: 'external/audio', label: 'External Audio' },
];

const initForm = {
  uploadLocation: '',
  cameraType: '',
  date: new Date().toISOString().substr(0, 10),
  shooterInitials: '',
  cardNumber: '',
};

function Upload(props) {
  const [jobId, setJobId] = useState('');
  const [uploadType, setUploadType] = useState(UPLOAD_TYPES[0].value);
  const [oldFiles, setOldFiles] = useState([]);
  const [selectOpts, setSelectOpts] = useState([]);
  const [stateForm, setForm] = useState(initForm);
  const [startUpload, setStartUpload] = useState(false);

  useEffect(() => {
    (async function() {
      const jobIdsList = await getJobIdList({ parsed: true, prefix: uploadType }, props);
      setSelectOpts(jobIdsList);
    })();
  }, [uploadType]);

  useEffect(() => {
    const jobIdIdx = selectOpts.findIndex(elm => elm['value'] === jobId);
    const isNewJob = jobIdIdx === -1;
    if (isNewJob) {
      return setOldFiles([]);
    }

    if (jobId) {
      (async function() {
        const jobList = await getJobIdList({ search: jobId, prefix: uploadType }, props);
        setOldFiles(jobList);
      })();
    }
  }, [jobId, uploadType]);

  const onStartUpload = () => {
    if (!jobId) {
      return window.alert('Please add a JobId');
    }

    if (!jobId.match(/\b[A-Z]{3}[0-9]{3}\b/)) {
      return window.alert(['Please make sure JobId is formatted', 'correctly e.g. CAP000'].join(' '));
    }

    setStartUpload(true);
  };

  const selectStyle = {
    control: (styles, state) => ({
      ...styles,
      color: PrimaryTextColor,
      backgroundColor: '#dcdcdc',
      height: '40px',
      borderColor: state.isFocused ? '#f69c2d' : '#b4b6b6',
      boxShadow: 'none',
      '&:hover': { borderColor: 'gray' },
    }),
    singleValue: styles => ({
      ...styles,
      color: PrimaryTextColor,
    }),
    input: styles => ({
      ...styles,
      color: PrimaryTextColor,
    }),
    option: (styles, state) => ({
      ...styles,
      color: state.isSelected ? '#fff' : '#000',
      backgroundColor: state.isSelected ? '#A9A9A9	' : 'transparent',
      '&:hover': {
        // Overwrittes the different states of border
        backgroundColor: '#d3d3d3',
        color: '#fff',
      },
    }),
  };

  return (
    <CardContainer style={{ width: '30%' }}>
      <CardContainerBody>
        <CardTitle>Upload</CardTitle>
        <CardSubtitle>Please fill the form below and click next to select and upload file</CardSubtitle>
        <div style={{ marginTop: 20 }}>
          <div>
            <Label>Upload Type</Label>
            <CreatableSelect
              styles={selectStyle}
              options={UPLOAD_TYPES}
              defaultValue={UPLOAD_TYPES[0]}
              onChange={currOpt => setUploadType(currOpt['value'])}
            />
          </div>
          <br />
          <div>
            <Label>Job Id</Label>
            <CreatableSelect
              key={uploadType}
              styles={selectStyle}
              options={selectOpts}
              onChange={currOpt => setJobId(currOpt['value'])}
            />
          </div>
          <br />
          <br />
          <S.TwoColumns>
            <div>
              <Label>Upload location</Label>
              {getSelectComponent('uploadLocation', LOCATIONS)}
            </div>
            <div>
              <Label>Camera Type</Label>
              {getSelectComponent('cameraType', CAMERA_TYPES)}
            </div>
          </S.TwoColumns>
          <S.TwoColumns>
            <div>
              <Label>Date</Label>
              {getInputComponent({
                key: 'date',
                type: 'date',
              })}
            </div>
            <div>
              <Label>Card Number</Label>
              {getInputComponent({
                key: 'cardNumber',
                placeholder: 'e.g. 1',
              })}
            </div>
          </S.TwoColumns>
          <S.TwoColumns>
            <div>
              <Label>Shooter Initials</Label>
              {getInputComponent({
                key: 'shooterInitials',
                placeholder: 'e.g. WS',
              })}
            </div>
            <div>
              <Label>AWS S3 Destination Path</Label>
              <InputElement disabled value={getDestinationPath(stateForm, jobId, uploadType)} />
            </div>
          </S.TwoColumns>

          <br />
          {getOldFilesComponent()}
          <S.TwoColumns>
            <React.Fragment key={'uploader-' + uploadType}>
              <div>
                <Label>Upload folder</Label>
                <MultiUpload
                  isDirectory={true}
                  basePath={getDestinationPath(stateForm, jobId, uploadType)}
                  startUpload={startUpload}
                />
              </div>
              <div>
                <Label>Upload files</Label>
                <MultiUpload
                  isDirectory={false}
                  basePath={getDestinationPath(stateForm, jobId, uploadType)}
                  startUpload={startUpload}
                />
              </div>
            </React.Fragment>
          </S.TwoColumns>
          <S.Button onClick={onStartUpload}>Start Upload</S.Button>
        </div>
      </CardContainerBody>
    </CardContainer>
  );

  function getInputComponent(params) {
    const onChange = evt => {
      const text = evt.target.value;
      setForm({ ...stateForm, [params['key']]: text });
    };

    return (
      <React.Fragment>
        <InputElement
          type={params['type']}
          placeholder={params['placeholder']}
          value={stateForm[params['key']]}
          onChange={onChange}
        />
        <br />
      </React.Fragment>
    );
  }

  function getSelectComponent(key, list, onChange) {
    const currSelIdx = list.findIndex(elm => {
      return elm['value'] === stateForm[key];
    });

    const style = {
      color: currSelIdx === 0 || currSelIdx === -1 ? '#495057' : '#ffffff',
    };

    const _onChange = onChange
      ? onChange
      : evt => {
          const text = evt.target.value;
          setForm({ ...stateForm, [key]: text });
        };

    return (
      <React.Fragment>
        <SelectElement style={style} onChange={_onChange}>
          {list.map(({ value, text }) => (
            <option value={value} key={value}>
              {text}
            </option>
          ))}
        </SelectElement>
        <br />
      </React.Fragment>
    );
  }

  function getOldFilesComponent() {
    if (oldFiles.length < 1) {
      return null;
    }
    const getElmPath = elm =>
      elm['Key']
        .split('/')
        .slice(3)
        .join('/');

    return (
      <React.Fragment>
        <Label>Already Uploaded Files</Label>
        <CardLightBackground>
          {oldFiles.map(elm => (
            <div key={elm['Key']}>{getElmPath(elm)}</div>
          ))}
        </CardLightBackground>
      </React.Fragment>
    );
  }
}

export function getDestinationPath(formState, jobId, uploadType) {
  // 2019-12-05 -> 05122019
  const dateParsed = formState.date
    .split('-')
    .reverse()
    .join('');

  const jobIdUpperCase = jobId.toUpperCase();

  return [
    uploadType,
    jobIdUpperCase,
    [jobIdUpperCase, dateParsed, formState.shooterInitials, formState.cameraType, formState.cardNumber]
      .join('_')
      .toUpperCase(),
  ].join('/');
}

export async function getJobIdList(params, props) {
  if (!process.env.UPLOAD_API_ID || params === undefined) {
    return [];
  }

  const parsed = params['parsed'] || false;
  const search = params['search'] || '';
  const prefix = params['prefix'] || UPLOAD_TYPES[0].value;

  let queryParams = [`parsed=${parsed}`, `search=${search}`, `prefix=${prefix}`];
  const {
    data: { contents },
  } = await MakeRequest({
    endpoint: { url: `${props.uploadApiUrl}/get-job-ids`, xApiKey: props.uploadApiKey },
    queryParams,
  });

  const getNameAndDate = elm => ({
    name: elm['Key'].replace(prefix, '').split('/')[1],
    date: moment(elm['LastModified']).format('DD/MM/YYYY'),
  });
  const noDuplicates = (elm, idx, self) => {
    const innerIdx = self.findIndex(innerElm => {
      return innerElm['name'] === elm['name'];
    });
    return idx === innerIdx;
  };
  const noFalsy = elm => !!elm['name'];
  const formatSelect = elm => ({
    value: elm['name'],
    label: `${elm['name']} - ${elm['date']}`,
  });

  if (!parsed) {
    return contents;
  }

  return contents
    .map(getNameAndDate)
    .filter(noDuplicates)
    .filter(noFalsy)
    .map(formatSelect);
}

export default connect(state => ({
  uploadApiUrl: getUploadApiUrl()(state),
  uploadApiKey: getUploadApiKey()(state),
}))(Upload);
