import { set, get, merge, flow } from 'lodash/fp';
import { USER_LOGOUT } from '../user/actions';
import { LOAD_CONFIG_REQUEST, LOAD_CONFIG_FAILURE, LOAD_CONFIG_SUCCESS } from './actions';

export const config = (state = { loaded: false }, action) => {
  switch (action.type) {
    case LOAD_CONFIG_REQUEST:
      return state;
    case LOAD_CONFIG_FAILURE:
      return state;
    case LOAD_CONFIG_SUCCESS:
      return flow(
        get('config'),
        merge(state),
        set('loaded', true)
      )(action);
    case USER_LOGOUT:
      return { loaded: false };
    default:
      return state;
  }
};
