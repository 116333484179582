import { get, set, flow, merge } from 'lodash/fp';
import { USER_LOGIN, RESTORE_LOGIN_SUCCESS, USER_LOGOUT } from './actions';

const defaultState = {
  isAuthenticated: false,
};

export const user = (state = defaultState, action) => {
  switch (action.type) {
    case USER_LOGIN:
    case RESTORE_LOGIN_SUCCESS:
      return flow(
        get('accessCredentials'),
        merge(state),
        set('isAuthenticated', true)
      )(action);
    case USER_LOGOUT:
      return defaultState;
    default:
      return state;
  }
};
