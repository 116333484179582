import React from 'react';
import ReactDOM from 'react-dom';

export default function Modal(props) {
  const containerStyle = {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    padding: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(100, 100, 100, 0.5)',
    zIndex: 99,
  };

  return ReactDOM.createPortal(
    <div style={containerStyle}>{props.children}</div>,
    document.getElementById('modal-root')
  );
}
