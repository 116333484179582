import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { initEvaporate } from '../../helpers/s3Upload';
import {
  getS3Config,
  getS3FileCredentialsApiKey,
  getS3FileCredentialsApiUrl,
  getUploadApiUrl,
  getUploadApiKey,
} from '../../state/config/selectors';
import SingleUpload from './single-upload';

const S = {};
S.Container = styled.div`
  margin-bottom: 10px;
`;

function MultiUpload(props = {}) {
  const didMountRef = React.useRef(false);
  const [instance, setInstance] = React.useState();
  const [files, setFiles] = React.useState([]);

  React.useEffect(() => {
    const useEffectCleanUp = () => {};

    const isInitalRender = !didMountRef.current;
    if (isInitalRender) {
      didMountRef.current = true;
      return useEffectCleanUp;
    }

    (async function() {
      setInstance(
        await initEvaporate(
          props.s3Config.landingBucket,
          {
            url: props.credentialsApiUrl + '/s3/access',
            xApiKey: props.credentialsApiKey,
          },
          {
            url: props.uploadApiUrl + '/get-upload-signed-url',
            xApiKey: props.uploadApiKey,
          }
        )
      );
    })();

    return useEffectCleanUp;
  }, [props.startUpload]);

  return (
    <S.Container>
      <input {...getFileProps(props.isDirectory)} />
      {files.map(file => {
        return <SingleUpload evaporateInst={instance} key={file.name} file={file} basePath={props.basePath} />;
      })}
    </S.Container>
  );

  function getStyle() {
    return {
      file: {
        width: '100%',
        marginBottom: 10,
        borderRadius: 2,
      },
    };
  }

  function getFileProps(isDirectory) {
    const s = getStyle();
    const onChange = evt => {
      const { files } = evt.target;
      const filesArray = Array.from(files).filter(removeUnwanted);
      setFiles(filesArray);
    };

    if (!isDirectory) {
      return {
        type: 'file',
        multiple: true,
        style: s.file,
        onChange: onChange,
      };
    }

    return {
      type: 'file',
      multiple: true,
      webkitdirectory: '',
      mozdirectory: 'true',
      directory: '',
      style: s.file,
      onChange: onChange,
    };
  }

  function removeUnwanted(file) {
    const UNWANTED = ['.DS_Store'];
    return !UNWANTED.includes(file.name);
  }
}

export default connect(state => ({
  s3Config: getS3Config()(state),
  credentialsApiUrl: getS3FileCredentialsApiUrl()(state),
  credentialsApiKey: getS3FileCredentialsApiKey()(state),
  uploadApiKey: getUploadApiKey()(state),
  uploadApiUrl: getUploadApiUrl()(state),
}))(MultiUpload);
