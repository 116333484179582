import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import copy from 'copy-to-clipboard';
import qs from 'qs';
import S from '../../ui-components/treeView';
import SubTitle from '../../ui-components/subTitle';
import ItemPlus from '../../ui-components/itemPlus';
import { PrimaryButtonElement } from '../../ui-components/buttons';

S.itemList.ItemTable = styled.span`
  ${({ isSearching }) =>
    isSearching
      ? css`
          display: table-row;
        `
      : css`
          display: table-cell;
        `}
`;

export const videoExtensionArray = [
  'mp4',
  'mov',
  'mxf',
  'avi',
  'm4a',
  'm4v',
  'f4v',
  'f4a',
  'm4b',
  'm4r',
  'f4b',
  'ogg',
  'oga',
  'flv',
  'r3d',
];

export const imageExtensionArray = ['jpg', 'jpeg', 'png', 'gif', 'svg'];

class TreeView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allFilesToggled: false,
      searchData: undefined,
      filesToDownload: [],
    };
  }

  clearDownloads = () => this.setState({ filesToDownload: [] });

  render() {
    const { nodes, isSearching, download } = this.props;

    return (
      <S.itemList.ItemListContainer>
        {download ? (
          <div style={{ display: 'flex' }}>
            <div
              style={{
                textAlign: 'center',
                width: 34,
                lineHeight: '48px',
                verticalAlign: 'middle',
              }}
            >
              <input
                type="checkbox"
                checked={this.state.allFilesToggled}
                onChange={e => this.handleAllFilesToggled(e, nodes)}
                disabled={!this.checkForURL(nodes) && 'disabled'}
              />
            </div>
            <PrimaryButtonElement
              style={Object.assign(
                {
                  width: 'auto',
                  margin: '10px 0px 10px',
                  border: 'none',
                },
                !this.state.filesToDownload.length ? { color: '#000', backgroundColor: '#1b1b1b', fontSize: 11 } : {}
              )}
              disabled={!this.state.filesToDownload.length}
              onClick={() => {
                this.state.filesToDownload.map(id => {
                  this.props.nodes.map(obj => {
                    if (obj.url === id) {
                      download(obj);
                    }
                  });
                });
              }}
            >
              Download files ({this.state.filesToDownload.length})
            </PrimaryButtonElement>
          </div>
        ) : null}
        {nodes.map(item => {
          return (
            <S.itemList.ItemContainer
              key={item.id}
              onClick={item.url ? () => null : () => this.props.onNodeClick(item)}
            >
              <div>
                {!item.url ? (
                  <span style={{ display: 'flex' }}>
                    <ItemPlus />
                    <div style={{ display: 'table', height: '35px' }}>
                      <S.itemList.ItemTable
                        style={{
                          whiteSpace: 'pre-wrap',
                          wordBreak: 'break-all',
                          verticalAlign: 'middle',
                          paddingRight: 35,
                          fontSize: 11,
                        }}
                      >
                        {item.name}
                      </S.itemList.ItemTable>
                      {isSearching ? <SubTitle id={item.id} /> : null}
                    </div>
                  </span>
                ) : (
                  <div style={{ display: 'flex', width: '100%' }}>
                    <div
                      style={{
                        textAlign: 'center',
                        width: 34,
                        verticalAlign: 'middle',
                        lineHeight: '32px',
                      }}
                    >
                      {download ? (
                        <input
                          type="checkbox"
                          checked={this.state.filesToDownload.indexOf(item.url) != -1}
                          onChange={() => this.handleFileChecked(item)}
                        />
                      ) : (
                        <i className="fa fa-file"></i>
                      )}
                    </div>
                    <div style={{ display: 'inline-flex', width: '100%' }}>
                      <div
                        style={{
                          display: 'table',
                          height: '35px',
                          width: '90%',
                          paddingLeft: '5px',
                        }}
                      >
                        <S.itemList.ItemTable
                          style={{
                            whiteSpace: 'pre-wrap',
                            wordBreak: 'break-all',
                            verticalAlign: 'middle',
                          }}
                        >
                          {this.canBeClicked(item)}
                        </S.itemList.ItemTable>
                        {isSearching ? <SubTitle id={item.id} /> : null}
                      </div>
                      <S.itemList.IconDiv>
                        {this.hasIcon(item, download)}
                        {download ? (
                          <S.itemList.LinkButton onClick={() => this.props.download(item)}>
                            <i className="fa fa-download" aria-hidden="true" />
                          </S.itemList.LinkButton>
                        ) : null}
                      </S.itemList.IconDiv>
                    </div>
                  </div>
                )}
              </div>
            </S.itemList.ItemContainer>
          );
        })}
      </S.itemList.ItemListContainer>
    );
  }

  handleFileChecked = item => {
    const originalList = [...this.state.filesToDownload];
    if (originalList.indexOf(item.url) != -1) {
      const indexOfItem = originalList.indexOf(item.url);
      originalList.splice(indexOfItem, 1);
      this.setState({ filesToDownload: originalList, allFilesToggled: false });
    } else {
      originalList.push(item.url);
      this.setState({ filesToDownload: originalList });
    }
  };

  handleAllFilesToggled = (e, data) => {
    let files = [];
    const allFilesToggled = e.target.checked;
    if (allFilesToggled) {
      data.map(item => {
        item.hasOwnProperty('url') && files.push(item.url);
      });
    }
    this.setState({ filesToDownload: files, allFilesToggled });
  };

  checkForURL = data => {
    let check = false;
    data.forEach(element => {
      if (element.hasOwnProperty('url')) {
        check = true;
      }
    });
    if (check) {
      return true;
    } else {
      return false;
    }
  };

  hasPreview = name => {
    const nameBits = name.split('.');
    switch (true) {
      case videoExtensionArray.indexOf(nameBits[nameBits.length - 1].toLowerCase()) !== -1:
        return 'video';
      case imageExtensionArray.indexOf(nameBits[nameBits.length - 1].toLowerCase()) !== -1:
        return 'image';
      case nameBits[nameBits.length - 1].toLowerCase() == 'txt':
        return 'text';
      default:
        return null;
    }
  };

  canBeClicked = ({ name = '', url = '', size = '', metadata = {} }) => {
    const hasPreview = this.hasPreview(name);
    if (hasPreview) {
      return <div onClick={() => this.props.preview(url, hasPreview, metadata)}>{name}</div>;
    } else {
      return <div>{name}</div>;
    }
  };

  hasIcon = ({ url = '', name = '', metadata = {} }, download) => {
    const hasPreview = this.hasPreview(name);
    if (hasPreview) {
      return (
        <React.Fragment>
          {(() => {
            return (
              <S.itemList.LinkButton onClick={() => this.props.preview(url, hasPreview, metadata)}>
                <i
                  /* prettier-ignore */
                  // eslint-disable-next-line quotes
                  className={hasPreview === 'video' ? "fa fa-play" : hasPreview === 'image' ? "fa fa-image" : hasPreview === 'text' ? "fa fa-file-text" : ""}
                  aria-hidden="true"
                />
              </S.itemList.LinkButton>
            );
          })()}
          {!download ? (
            <S.itemList.LinkButton
              onClick={() =>
                copy(
                  `${location.protocol}//${location.host}${location.pathname}?${qs.stringify({
                    file: url,
                    mediaBucket: this.props.mediaBucket,
                  })}`
                )
              }
            >
              <i className="fa fa-copy" aria-hidden="true" />
            </S.itemList.LinkButton>
          ) : null}
        </React.Fragment>
      );
    }
  };
}

export default TreeView;
