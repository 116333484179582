import { restoreLogin } from '../user/actions';
import { loadConfig } from '../config/actions';

export const APP_BOOTSTRAP_COMPLETE = 'APP_BOOTSTRAP_COMPLETE';
export const APP_LOAD_EDIT_JOB_ID = 'APP_LOAD_EDIT_JOB_ID';

export const bootstrapRedux = () => async dispatch => {
  await dispatch(restoreLogin());
};

export const bootstrapConfig = () => async (dispatch, getState) => {
  const state = getState();
  const { isAuthenticated } = state.user;
  isAuthenticated && (await dispatch(loadConfig()));
};

export const appBootstrapComplete = () => async dispatch => {
  dispatch({ type: APP_BOOTSTRAP_COMPLETE });
};

export const loadEditJobId = editJobId => async dispatch => {
  dispatch({ type: APP_LOAD_EDIT_JOB_ID, editJobId: editJobId });
};
