import React from 'react';
import styled from 'styled-components';

export const ItemPlus = () => {
  const Item = styled.div`
    display: inline-block;
    width: 3em;
    height: 3em;
    text-align: center;
    margin-right: 0.5em;

    &:before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      margin-right: -0.25em; /* Adjusts for spacing */
    }
  `;

  const ItemIcon = styled.i`
    margin: auto;
    text-align: center;
    vertical-align: middle;
  `;

  return (
    <Item>
      <ItemIcon className="fa fa-plus" aria-hidden="true" />
    </Item>
  );
};

export default ItemPlus;
