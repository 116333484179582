import { set, flow, get, merge } from 'lodash/fp';
import { APP_BOOTSTRAP_COMPLETE, APP_LOAD_EDIT_JOB_ID } from './actions';

export const app = (state = { bootstrapped: false }, action) => {
  switch (action.type) {
    case APP_BOOTSTRAP_COMPLETE:
      return set('bootstrapped', true, state);
    case APP_LOAD_EDIT_JOB_ID:
      var editJobId = get('editJobId')(action);
      return merge(state, { editJobId });
    default:
      return state;
  }
};
