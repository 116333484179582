const config = {
  file_select: {
    'generate-s3-url': `https://${process.env.FILE_SELECT_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/s3/url`,
    'submit-files': `https://${process.env.FILE_SELECT_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/files`,
    'get-s3-objects-tree': `https://${process.env.FILE_SELECT_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/s3/${process.env.S3_MEDIA}`,
    'get-s3-children': `https://${process.env.FILE_SELECT_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/s3-children/${process.env.S3_MEDIA}`,
  },
  create_edit_request: {
    'edit-job': `https://${process.env.EDIT_REQUEST_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}`,
  },
  edit_request_state: {
    get_details: `https://${process.env.EDIT_REQUEST_STATE_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/records`,
  },
  submit_edit_flow: {
    //Conform SQS Messages
    'get-sqs-message': `https://${process.env.SUBMIT_EDIT_FLOW_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/conform/message`,
    'update-sqs-message': `https://${process.env.SUBMIT_EDIT_FLOW_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/conform/message`,

    //Buckets Prefixes
    'conform-submission-prefix': 'backend/submissions',
    'conform-approvals-prefix': 'backend/approvals',
    'conform-approvals-delivery-prefix': 'backend/approvals/delivery',
    'conform-submission-delivery-prefix': 'backend/submissions/delivery',

    //Conform Workflow
    'init-conform-process': `https://${process.env.SUBMIT_EDIT_FLOW_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/conform`,
    'save-as-failed-conform': `https://${process.env.SUBMIT_EDIT_FLOW_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/conform/job`,
    'complete-conform': editJobId =>
      `https://${process.env.SUBMIT_EDIT_FLOW_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/conform/${editJobId}/complete`,
  },
  file_upload: {
    'get-job-ids': `https://${process.env.UPLOAD_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/get-job-ids`,
    'get-signed-url': `https://${process.env.UPLOAD_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/get-upload-signed-url`,
  },
  download_credentials: {
    'get-files-credentials': `https://${process.env.AWSCLI_DOWNLOAD_CREDENTIALS_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/s3/credentials`,
  },
  edit_job_events: {
    'save-event': `https://${process.env.STATUS_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/status`,
    'get-all-events': `https://${process.env.STATUS_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/status`,
    'update-event-progress': `https://${process.env.STATUS_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/status/progress`,
  },
  auth: {
    'get-login-code-for-panel': `https://${process.env.AUTH_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/code`,
    'get-access-token': `https://${process.env.AUTH_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/authorization`,
    'revoke-access-token': `https://${process.env.AUTH_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/authorization/logout`,
  },
  info: {
    'ame-checkin': `https://${process.env.INFO_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/connector/ame`,
  },
};

export default config;
