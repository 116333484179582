import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import SnackbarProvider from 'react-simple-snackbar';
import Layout from './layout';
import Bootstrap from './bootstrap';
import Home from './routes/home';
import Login from './routes/login';
import Edits from './routes/edits';
import Upload from './routes/upload';
import EditPanel from './routes/edit';
import History from './routes/history';
import Dashboard from './routes/dashboard';
import EditRequest from './routes/edit/request';
import FileSelect from './routes/edit/fileSelect';
import FileSelectOnly from './routes/fileSelect';
import EditReviewApprovals from './routes/edit/edit-approvals';
import PanelBridge from './helpers/panelBridge';
import store from './state/store';
var ES6Promise = require('es6-promise');
ES6Promise.polyfill();

//First thing to do is remove this flag
localStorage.removeItem('isAdobePanel');

global.pproBridge = new PanelBridge(store);

//To tell bootstrap module about jquery
import './static/css/font-awesome.min.css';
import './static/css/custom.css';

export default class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <Bootstrap>
          <Router>
            <SnackbarProvider>
              <Layout>
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/upload" component={Upload} />
                  <Route exact path="/editRequest" component={EditRequest} />
                  <Route exact path="/edits" component={Edits} />
                  <Route exact path="/history/:editJobId" component={History} />
                  <Route exact path="/file-browser" component={FileSelectOnly} />
                  <Route exact path="/dashboard" component={Dashboard} />
                  <Route exact path="/edit-panel" component={EditPanel} />
                  <Route exact path="/edit-panel/file-transfer" component={FileSelect} />
                  <Route exact path="/edit-panel/edit-approvals" component={EditReviewApprovals} />
                  <Route exact path="/edit-panel/history" component={History} />
                </Switch>
              </Layout>
            </SnackbarProvider>
          </Router>
        </Bootstrap>
      </Provider>
    );
  }
}
