import MakeRequest from '../../../request';

export default class EntireBucketDataSource {
  allNodes = null;

  async init() {
    const { data } = await MakeRequest({ requestType: 'FileSelect.GetS3ObjectsTree' });
    const response = data;

    const initData = [{ text: 'root', nodes: response }];
    const applyFuncs = [this.applyMyTransform, this.addIdsToTree];
    this.allNodes = this.transformTree(initData, applyFuncs);
    return this;
  }

  getFirstNode() {
    return this.allNodes[0];
  }

  getNode = ({ node }) => node;

  getNodeByPrefix(nodeId) {
    const nodeIdParts = nodeId.split('/');
    let chosenNode = null;
    let allNodes = this.allNodes;
    while (!chosenNode) {
      for (let i = 0; i < allNodes.length; i++) {
        if (allNodes[i].name == nodeIdParts[0]) {
          if (nodeIdParts.length === 1) {
            chosenNode = allNodes[i];
          } else {
            allNodes = allNodes[i].children;
            nodeIdParts.shift();
          }
          break;
        }
      }
    }
    return chosenNode;
  }

  getSearchResults(currentNode, searchTerm) {
    if (searchTerm.length < 3) {
      return this.getNodeByIdParts(currentNode.id.split('/'));
    }

    let nextData = this.searchTree(currentNode.children || [], searchTerm, this.searchComparator);

    const unique = {};
    const isUnique = obj => !unique[obj.id] && (unique[obj.id] = true);
    const arrFiltered = nextData.filter(isUnique);

    return { id: currentNode.id, children: arrFiltered, search: true };
  }

  searchTree(subTree, searchTerm, comparator, _accumulator = []) {
    if (subTree.length === 0) {
      return;
    }

    for (var i = 0; i < subTree.length; i++) {
      if (comparator(subTree[i], searchTerm)) {
        _accumulator.push(this.clone(subTree[i]));
      }

      this.searchTree(subTree[i].children || [], searchTerm, comparator, _accumulator);
    }

    return _accumulator;
  }

  searchComparator(currentLeaf, searchTerm) {
    return currentLeaf.name.indexOf(searchTerm) !== -1;
  }

  transformTree = (tree, func = [() => {}], parent = { key: '', id: '' }) => {
    if (tree.length === 0) {
      return;
    }

    return tree.map(leaf => {
      var finalTreeRes = func.reduce((accumulatorRes, funct) => {
        const resFunApp = funct(accumulatorRes);
        return resFunApp;
      }, leaf);

      if (parent.key === '') {
        finalTreeRes.key = finalTreeRes.name;
      } else {
        finalTreeRes.key = parent.key + '/' + finalTreeRes.name;
      }

      if (parent.id === '') {
        finalTreeRes.id = finalTreeRes.name;
      } else {
        finalTreeRes.id = parent.id + '/' + finalTreeRes.name;
      }

      return {
        ...finalTreeRes,
        children: this.transformTree(finalTreeRes['children'] || [], func, finalTreeRes),
      };
    });
  };

  clone(data) {
    return JSON.parse(JSON.stringify(data));
  }

  applyMyTransform = treeElm => {
    const clonedTreeElm = this.clone(treeElm);
    if (clonedTreeElm['text'] === '') {
      const id = Math.floor(Date.now() / 1000);
      clonedTreeElm['text'] = 'undefined_node_' + id;
    }
    clonedTreeElm['name'] = clonedTreeElm['text'];
    delete clonedTreeElm['text'];
    if (!clonedTreeElm.nodes) {
      return clonedTreeElm;
    }
    clonedTreeElm['children'] = this.clone(clonedTreeElm.nodes);
    delete clonedTreeElm['nodes'];
    return clonedTreeElm;
  };

  addIdsToTree = treeElm => {
    return treeElm;
  };
}
