import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { orderBy } from 'lodash/fp';
import Modal from '../../layout/modal';
import { Spinner2, Spinner } from '../../ui-components/spinner';
import { CardContainer, CardContainerBody, CardTitle, MutedSubtitle } from '../../ui-components/card';
import {
  TableWrapper,
  TableContainer,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
  TableCell,
} from '../../ui-components/table';
import { PrimaryButtonLinkElement, DangerButtonLinkElement } from '../../ui-components/buttons';
import MakeRequest from '../../request';
import { getInfoApiKey, getInfoApiUrl, getSubmitApiKey, getSubmitApiUrl } from '../../state/config/selectors';

const StyledCardTitle = styled(CardTitle)`
  text-align: center;
  font-size: 14px;
  letter-spacing: 2px;
`;

const NoRecordsMessageContainer = styled(MutedSubtitle)`
  text-align: center;
`;

class Dashboard extends React.Component {
  state = {
    amePanels: [],
    premierePanels: [],
    davinciResolveBackend: [],
    failedJobs: [],
    loadingEncoders: true,
    loadingFailedJobs: true,
  };

  componentDidMount = async () => {
    const { infoApiUrl, infoApiKey, submitApiUrl, submitApiKey } = this.props;

    let {
      data: {
        results: { amePanel: amePanels, premierePanel: premierePanels, davinciResolveBackend: davinciResolveBackend },
      },
    } = await MakeRequest({
      endpoint: { url: `${infoApiUrl}/connector`, xApiKey: infoApiKey },
      queryParams: ['seconds=180'],
    });

    amePanels = orderBy(['dateAdded'], ['desc'])(amePanels);
    premierePanels = orderBy(['dateAdded'], ['desc'])(premierePanels);
    davinciResolveBackend = orderBy(['dateAdded'], ['desc'])(davinciResolveBackend);

    await this.setState({ amePanels, premierePanels, davinciResolveBackend, loadingEncoders: false });

    let {
      data: { result: failedJobs },
    } = await MakeRequest({ endpoint: { url: `${submitApiUrl}/conform/jobs`, xApiKey: submitApiKey } });

    failedJobs = orderBy(['dateAdded'], ['desc'])(failedJobs);
    await this.setState({ failedJobs: failedJobs, loadingFailedJobs: false });
  };

  pauseME = async identifier => {
    const { infoApiUrl, infoApiKey } = this.props;
    this.setState({ activityInProgress: true });
    await MakeRequest(
      { endpoint: { url: `${infoApiUrl}/connector/ame/pause`, xApiKey: infoApiKey } },
      { method: 'POST', data: { identifier } }
    );

    let {
      data: {
        results: { amePanel: amePanels, premierePanel: premierePanels, davinciResolveBackend: davinciResolveBackend },
      },
    } = await MakeRequest({
      endpoint: { url: `${infoApiUrl}/connector`, xApiKey: infoApiKey },
      queryParams: ['seconds=180'],
    });

    amePanels = orderBy(['dateAdded'], ['desc'])(amePanels);
    premierePanels = orderBy(['dateAdded'], ['desc'])(premierePanels);
    davinciResolveBackend = orderBy(['dateAdded'], ['desc'])(davinciResolveBackend);

    await this.setState({
      amePanels,
      premierePanels,
      davinciResolveBackend,
      loadingEncoders: false,
      activityInProgress: false,
    });
  };

  resumeME = async identifier => {
    const { infoApiUrl, infoApiKey } = this.props;
    this.setState({ activityInProgress: true });
    await MakeRequest(
      { endpoint: { url: `${infoApiUrl}/connector/ame/pause/${identifier}`, xApiKey: infoApiKey } },
      { method: 'DELETE', identifier: identifier }
    );

    let {
      data: {
        results: { amePanel: amePanels, premierePanel: premierePanels, davinciResolveBackend: davinciResolveBackend },
      },
    } = await MakeRequest({
      endpoint: { url: `${infoApiUrl}/connector`, xApiKey: infoApiKey },
      queryParams: ['seconds=180'],
    });

    amePanels = orderBy(['dateAdded'], ['desc'])(amePanels);
    premierePanels = orderBy(['dateAdded'], ['desc'])(premierePanels);
    davinciResolveBackend = orderBy(['dateAdded'], ['desc'])(davinciResolveBackend);

    await this.setState({
      amePanels,
      premierePanels,
      davinciResolveBackend,
      loadingEncoders: false,
      activityInProgress: false,
    });
  };

  runJob = async uuid => {
    const { submitApiUrl, submitApiKey } = this.props;

    this.setState({ activityInProgress: true });
    await MakeRequest(
      { endpoint: { url: `${submitApiUrl}/conform/job/${uuid}/run`, xApiKey: submitApiKey } },
      { method: 'PUT', uuid: uuid }
    );

    let {
      data: { result: failedJobs },
    } = await MakeRequest({ endpoint: { url: `${submitApiUrl}/conform/jobs`, xApiKey: submitApiKey } });

    failedJobs = orderBy(['dateAdded'], ['desc'])(failedJobs);
    await this.setState({ failedJobs: failedJobs, activityInProgress: false });
  };

  deleteJob = async uuid => {
    const { submitApiUrl, submitApiKey } = this.props;
    this.setState({ activityInProgress: true });
    await MakeRequest(
      { endpoint: { url: `${submitApiUrl}/conform/job/${uuid}`, xApiKey: submitApiKey } },
      { method: 'DELETE', uuid: uuid }
    );

    let {
      data: { result: failedJobs },
    } = await MakeRequest({ endpoint: { url: `${submitApiUrl}/conform/jobs`, xApiKey: submitApiKey } });

    failedJobs = orderBy(['dateAdded'], ['desc'])(failedJobs);
    await this.setState({ failedJobs: failedJobs, activityInProgress: false });
  };

  render = () => {
    const {
      amePanels,
      premierePanels,
      davinciResolveBackend,
      failedJobs,
      loadingEncoders,
      loadingFailedJobs,
      activityInProgress,
    } = this.state;

    return (
      <React.Fragment>
        <CardContainer style={{width:'80%'}}>
          <CardContainerBody>
            <StyledCardTitle>Connected Adobe Media Encoders</StyledCardTitle>
            {loadingEncoders && <Spinner2 />}

            {!loadingEncoders && amePanels !== undefined && amePanels.length > 0 ? (
              <TableWrapper>
                <TableContainer>
                  <table>
                    <thead>
                      <TableHeaderRow>
                        <TableHeaderCell>ID</TableHeaderCell>
                        <TableHeaderCell>EC2 Instance ID</TableHeaderCell>
                        <TableHeaderCell>EC2 Instance Type</TableHeaderCell>
                        <TableHeaderCell>IP Address</TableHeaderCell>
                        <TableHeaderCell>Status</TableHeaderCell>
                        <TableHeaderCell>Hostname</TableHeaderCell>
                        <TableHeaderCell>Last Active</TableHeaderCell>
                        <TableHeaderCell>Total Memory</TableHeaderCell>
                        <TableHeaderCell>Free Memory</TableHeaderCell>
                        <TableHeaderCell>Action</TableHeaderCell>
                      </TableHeaderRow>
                    </thead>
                    <tbody>
                      {amePanels.map(entry => {
                        return (
                          <TableRow key={entry.UUID}>
                            <TableCell>{entry.identifier}</TableCell>
                            <TableCell>{entry.EC2InstanceId}</TableCell>
                            <TableCell>{entry.EC2InstanceType}</TableCell>
                            <TableCell>{entry.ip}</TableCell>
                            <TableCell>{entry.paused ? 'Paused' : 'Available'}</TableCell>
                            <TableCell>{entry.hostname}</TableCell>
                            <TableCell>{new Date(entry.dateAddedInMicroSeconds / 1000).toLocaleString()}</TableCell>
                            <TableCell>{Math.round((entry.totalmem / 1024 / 1024 / 1024) * 100) / 100} GB</TableCell>
                            <TableCell>{Math.round((entry.freemem / 1024 / 1024 / 1024) * 100) / 100} GB</TableCell>
                            <TableCell>
                              {entry.paused ? (
                                <PrimaryButtonLinkElement onClick={() => this.resumeME(entry.identifier)}>
                                  Resume
                                </PrimaryButtonLinkElement>
                              ) : (
                                <DangerButtonLinkElement
                                  onClick={() => this.pauseME(entry.identifier)}
                                  style={{ marginRight: 10 }}
                                >
                                  Pause
                                </DangerButtonLinkElement>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </tbody>
                  </table>
                </TableContainer>
              </TableWrapper>
            ) : null}

            {!loadingEncoders && amePanels !== undefined && amePanels.length == 0 && (
              <NoRecordsMessageContainer>
                No media encoder is connected at the moment. If you think this is some error please contact us at
                support@nekta.io
              </NoRecordsMessageContainer>
            )}
          </CardContainerBody>
        </CardContainer>

        <CardContainer style={{width: '80%'}}>
          <CardContainerBody >
            <StyledCardTitle>Connected Davinci Resolve Encoders</StyledCardTitle>
            {loadingEncoders && <Spinner2 />}

            {!loadingEncoders && davinciResolveBackend !== undefined && davinciResolveBackend.length > 0 ? (
              <TableWrapper>
                <TableContainer>
                  <table>
                    <thead>
                      <TableHeaderRow>
                        <TableHeaderCell>ID</TableHeaderCell>
                        <TableHeaderCell>EC2 Instance ID</TableHeaderCell>
                        <TableHeaderCell>EC2 Instance Type</TableHeaderCell>
                        <TableHeaderCell>IP Address</TableHeaderCell>
                        <TableHeaderCell>Status</TableHeaderCell>
                        <TableHeaderCell>Hostname</TableHeaderCell>
                        <TableHeaderCell>Last Active</TableHeaderCell>
                        <TableHeaderCell>Total Memory</TableHeaderCell>
                        <TableHeaderCell>Free Memory</TableHeaderCell>
                        <TableHeaderCell>Action</TableHeaderCell>
                      </TableHeaderRow>
                    </thead>
                    <tbody>
                      {davinciResolveBackend.map(entry => {
                        return (
                          <TableRow key={entry.UUID}>
                            <TableCell>{entry.identifier}</TableCell>
                            <TableCell>{entry.EC2InstanceId}</TableCell>
                            <TableCell>{entry.EC2InstanceType}</TableCell>
                            <TableCell>{entry.ip}</TableCell>
                            <TableCell>{entry.paused ? 'Paused' : 'Available'}</TableCell>
                            <TableCell>{entry.hostname}</TableCell>
                            <TableCell>{new Date(entry.dateAddedInMicroSeconds / 1000).toLocaleString()}</TableCell>
                            <TableCell>{Math.round((entry.totalmem / 1024 / 1024 / 1024) * 100) / 100} GB</TableCell>
                            <TableCell>{Math.round((entry.freemem / 1024 / 1024 / 1024) * 100) / 100} GB</TableCell>
                            <TableCell>
                              {entry.paused ? (
                                <PrimaryButtonLinkElement onClick={() => this.resumeME(entry.identifier)}>
                                  Resume
                                </PrimaryButtonLinkElement>
                              ) : (
                                <DangerButtonLinkElement
                                  onClick={() => this.pauseME(entry.identifier)}
                                  style={{ marginRight: 10 }}
                                >
                                  Pause
                                </DangerButtonLinkElement>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </tbody>
                  </table>
                </TableContainer>
              </TableWrapper>
            ) : null}

            {!loadingEncoders && davinciResolveBackend !== undefined && davinciResolveBackend.length == 0 && (
              <NoRecordsMessageContainer>
                No Davinci resolve is connected at the moment. If you think this is some error please contact us at
                support@nekta.io
              </NoRecordsMessageContainer>
            )}
          </CardContainerBody>
        </CardContainer>

        <CardContainer style={{width:'80%'}}>
          <CardContainerBody >
            <StyledCardTitle>Connected Adobe Premiere Pro</StyledCardTitle>
            {loadingEncoders && <Spinner2 />}

            {!loadingEncoders && premierePanels !== undefined && premierePanels.length > 0 ? (
              <TableWrapper>
                <TableContainer>
                  <table>
                    <thead>
                      <TableHeaderRow>
                        <TableHeaderCell>ID</TableHeaderCell>
                        <TableHeaderCell>EC2 Instance ID</TableHeaderCell>
                        <TableHeaderCell>EC2 Instance Type</TableHeaderCell>
                        <TableHeaderCell>IP Address</TableHeaderCell>
                        <TableHeaderCell>Status</TableHeaderCell>
                        <TableHeaderCell>Hostname</TableHeaderCell>
                        <TableHeaderCell>Last Active</TableHeaderCell>
                        <TableHeaderCell>Total Memory</TableHeaderCell>
                        <TableHeaderCell>Free Memory</TableHeaderCell>
                        <TableHeaderCell>Action</TableHeaderCell>
                      </TableHeaderRow>
                    </thead>
                    <tbody>
                      {amePanels.map(entry => {
                        return (
                          <TableRow key={entry.UUID}>
                            <TableCell>{entry.identifier}</TableCell>
                            <TableCell>{entry.EC2InstanceId}</TableCell>
                            <TableCell>{entry.EC2InstanceType}</TableCell>
                            <TableCell>{entry.ip}</TableCell>
                            <TableCell>{entry.paused ? 'Paused' : 'Available'}</TableCell>
                            <TableCell>{entry.hostname}</TableCell>
                            <TableCell>{new Date(entry.dateAddedInMicroSeconds / 1000).toLocaleString()}</TableCell>
                            <TableCell>{Math.round((entry.totalmem / 1024 / 1024 / 1024) * 100) / 100} GB</TableCell>
                            <TableCell>{Math.round((entry.freemem / 1024 / 1024 / 1024) * 100) / 100} GB</TableCell>
                            <TableCell>
                              {entry.paused ? (
                                <PrimaryButtonLinkElement onClick={() => this.resumeME(entry.identifier)}>
                                  Resume
                                </PrimaryButtonLinkElement>
                              ) : (
                                <DangerButtonLinkElement
                                  onClick={() => this.pauseME(entry.identifier)}
                                  style={{ marginRight: 10 }}
                                >
                                  Pause
                                </DangerButtonLinkElement>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </tbody>
                  </table>
                </TableContainer>
              </TableWrapper>
            ) : null}

            {!loadingEncoders && premierePanels !== undefined && premierePanels.length == 0 && (
              <NoRecordsMessageContainer>
                No premiere pro is connected at the moment. If you think this is some error please contact us at
                support@nekta.io
              </NoRecordsMessageContainer>
            )}
          </CardContainerBody>
        </CardContainer>

        <CardContainer style={{width: '80%'}}>
          <CardContainerBody>
            <StyledCardTitle>Failed Encoding Jobs</StyledCardTitle>
            {loadingFailedJobs && <Spinner2 />}

            {!loadingFailedJobs && failedJobs !== undefined && failedJobs.length > 0 && (
              <TableWrapper>
                <TableContainer>
                  <table>
                    <thead>
                      <TableHeaderRow>
                        <TableHeaderCell>ID</TableHeaderCell>
                        <TableHeaderCell>Job Name</TableHeaderCell>
                        <TableHeaderCell>Status</TableHeaderCell>
                        <TableHeaderCell>Type</TableHeaderCell>
                        <TableHeaderCell>Version</TableHeaderCell>
                        <TableHeaderCell>Reason</TableHeaderCell>
                        <TableHeaderCell>Requested By</TableHeaderCell>
                        <TableHeaderCell>Date</TableHeaderCell>
                        <TableHeaderCell style={{ width: 150 }}>Action</TableHeaderCell>
                      </TableHeaderRow>
                    </thead>
                    <tbody>
                      {failedJobs.map(entry => {
                        return (
                          <TableRow key={entry.UUID}>
                            <TableCell>{entry.jobID}</TableCell>
                            <TableCell>{entry.jobName}</TableCell>
                            <TableCell style={{ textTransform: 'uppercase' }}>{entry.status}</TableCell>
                            <TableCell>{entry.conformType}</TableCell>
                            <TableCell>{entry.versionID}</TableCell>
                            <TableCell
                              style={{ textAlign: 'left' }}
                              dangerouslySetInnerHTML={{ __html: entry.latestLogs || 'N/A' }}
                            ></TableCell>
                            <TableCell>{entry.userName}</TableCell>
                            <TableCell>{new Date(entry.dateAdded / 1000).toLocaleString()}</TableCell>
                            <TableCell style={{ width: 150 }}>
                              <DangerButtonLinkElement
                                onClick={() => this.deleteJob(entry.UUID)}
                                style={{ margin: 5, display: 'inline-block', width: 'auto' }}
                              >
                                Delete
                              </DangerButtonLinkElement>
                              <PrimaryButtonLinkElement
                                onClick={() => this.runJob(entry.UUID)}
                                style={{ display: 'inline-block', width: 'auto', margin: 5 }}
                              >
                                Retry
                              </PrimaryButtonLinkElement>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </tbody>
                  </table>
                </TableContainer>
              </TableWrapper>
            )}

            {!loadingFailedJobs && failedJobs !== undefined && failedJobs.length == 0 && (
              <NoRecordsMessageContainer>
                Nothing to do here. All Caught up <br />
              </NoRecordsMessageContainer>
            )}
          </CardContainerBody>
        </CardContainer>

        {activityInProgress && (
          <Modal>
            <Spinner />
          </Modal>
        )}
      </React.Fragment>
    );
  };
}

export default connect(state => ({
  infoApiKey: getInfoApiKey()(state),
  infoApiUrl: getInfoApiUrl()(state),
  submitApiUrl: getSubmitApiUrl()(state),
  submitApiKey: getSubmitApiKey()(state),
}))(Dashboard);
