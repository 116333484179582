import { get } from 'lodash/fp';

const apiGateways = {
  FileSelect: {
    GenerateS3Url: () =>
      `https://${process.env.FILE_SELECT_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/s3/url`,
    SubmitFiles: ({ editJobId }) =>
      `https://${process.env.FILE_SELECT_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/v2/files/${editJobId}`,
    GetS3ObjectsTree: () =>
      `https://${process.env.FILE_SELECT_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/s3/${process.env.S3_MEDIA}`,
    GetS3Children: () =>
      `https://${process.env.FILE_SELECT_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/s3-children/${process.env.S3_MEDIA}`,
    GetS3PathAndBucket: () =>
      `https://${process.env.FILE_SELECT_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/files/proxy`,
    GetSize: ({ editJobId, fileName }) =>
      `https://${process.env.FILE_SELECT_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/file/${editJobId}/${fileName}`,
  },
  EditJob: {
    Create: () =>
      `https://${process.env.EDIT_REQUEST_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/job`,
  },
  EditRequestState: {
    GetDetails: ({ editJobId = false }) =>
      !editJobId
        ? `https://${process.env.EDIT_REQUEST_STATE_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/records`
        : `https://${process.env.EDIT_REQUEST_STATE_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/records/${editJobId}`,
  },
  SubmitEditFlow: {
    //Conform SQS Messages
    GetSqsMesage: () =>
      `https://${process.env.SUBMIT_EDIT_FLOW_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/conform/message`,
    UpdateSqsMessage: () =>
      `https://${process.env.SUBMIT_EDIT_FLOW_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/conform/message`,

    //Conform Workflow
    DeleteFailedJob: ({ uuid }) =>
      `https://${process.env.SUBMIT_EDIT_FLOW_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/conform/job/${uuid}`,
    RunFailedJob: ({ uuid }) =>
      `https://${process.env.SUBMIT_EDIT_FLOW_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/conform/job/${uuid}/run`,
    FailedConforms: () =>
      `https://${process.env.SUBMIT_EDIT_FLOW_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/conform/jobs`,
    InitConformProcess: ({ editJobId }) =>
      `https://${process.env.SUBMIT_EDIT_FLOW_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/conform/${editJobId}`,
    CompleteConform: ({ editJobId }) =>
      `https://${process.env.SUBMIT_EDIT_FLOW_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/conform/${editJobId}/complete`,
  },
  FileUpload: {
    GetJobIds: () =>
      `https://${process.env.UPLOAD_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/get-job-ids`,
    GetSignedUrl: () =>
      `https://${process.env.UPLOAD_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/get-upload-signed-url`,
  },
  Credentails: {
    GetFilesCredentials: () =>
      `https://${process.env.AWSCLI_DOWNLOAD_CREDENTIALS_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/s3/credentials`,
    GetUploadAccessKey: () =>
      `https://${process.env.AWSCLI_DOWNLOAD_CREDENTIALS_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/s3/access`,
  },
  Events: {
    Save: () =>
      `https://${process.env.STATUS_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/status`,
    GetAll: ({ editJobId }) =>
      `https://${process.env.STATUS_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/status/${editJobId}`,
    GetLatest: ({ editJobId }) =>
      `https://${process.env.STATUS_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/status/${editJobId}/latest`,
    UpdateProgress: () =>
      `https://${process.env.STATUS_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/status/progress`,
  },
  Auth: {
    GetAccessToken: () =>
      `https://${process.env.AUTH_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/authorization`,
    RefreshToken: () =>
      `https://${process.env.AUTH_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/authorization`,
    RevokeAccessToken: () =>
      `https://${process.env.AUTH_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/authorization/logout`,
  },
  Approval: {
    GetVersions: () =>
      `https://${process.env.APPROVAL_FLOW_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/approval/versions`,
    GetVersionComments: () =>
      `https://${process.env.APPROVAL_FLOW_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/approval/comments`,
    MarkCommentAsComplete: ({ commentId }) =>
      `https://${process.env.APPROVAL_FLOW_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/approval/comment/${commentId}/complete`,
  },
  Config: {
    GetFrontEndConfig: () =>
      `https://${process.env.CONFIG_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/configuration/frontend`,
    GetConfigItem: ({ item }) =>
      `https://${process.env.CONFIG_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/configuration/frontend/${item}`,
  },
  Info: {
    GetAllEncoders: () =>
      `https://${process.env.INFO_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/connector`,
    PauseAME: () =>
      `https://${process.env.INFO_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/connector/ame/pause`,
    ResumeAME: ({ identifier }) =>
      `https://${process.env.INFO_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/connector/ame/pause/${identifier}`,
  },
};

export const getEndPointUrl = (endPointLabel, params = {}) => {
  const endpointUrl = get(endPointLabel, apiGateways)({ ...params });
  return endpointUrl;
};
