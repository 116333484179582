import styled from 'styled-components';
import { PrimaryButtonTextColor } from './colors';

export const SharedButtonCss = styled.button`
  display: inline-block;
  width: 100%;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #f69c2d;
  color: #fff;
  border-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  ${props => (props.mt30 ? 'margin-top: 30px;' : '')}
  box-shadow: 0 15px 15px rgba(0, 0, 0, 0.75);
`;

export const SharedAnchorCss = styled.a`
  // display: inline-block;
  width: 100%;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #f69c2d;
  color: #fff;
  border-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  text-decoration: none;
  ${props => (props.mt30 ? 'margin-top: 30px;' : '')}
  box-shadow: 0 15px 15px rgba(0, 0, 0, 0.75);
`;

export const PrimaryButtonLinkElement = styled(SharedAnchorCss)`
  color: ${PrimaryButtonTextColor};
  background-color: #f69c2d;
  border-color: #f69c2d;
  outline: none !important;
  cursor: pointer;
  color: #fff;
`;

export const DangerButtonLinkElement = styled(SharedAnchorCss)`
  color: white;
  background-color: red;
  border-color: red;
  outline: none !important;
  cursor: pointer;
`;

export const PrimaryButtonElement = styled(SharedButtonCss)`
  color: #fff;
  background-color: #f69c2d;
  border-color: #f69c2d;
  outline: none !important;
  cursor: pointer;
`;

export const DangerButtonElement = styled(SharedButtonCss)`
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
`;

export const WarningButtonElement = styled(SharedButtonCss)`
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
`;

export const SuccessButtonElement = styled(SharedButtonCss)`
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
`;

export const OpenButtonElement = styled.button`
  background-color: #555;
  color: white;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  width: 100%;
  display: block;
  padding: 14px 28px;
  text-align: center;
`;

export const SubmitButtonElement = styled.button`
  padding: 5px 15px;
  background: #0e537d;
  color: white;
  border: 0 none;
  cursor: pointer;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  width: 100%;
  ${props => (props.disabled ? 'background: #8395a0;' : '')}
`;

export const SmallWarningButtonElement = styled(WarningButtonElement)`
  padding: 0.25rem 0.4rem;
  font-size: 0.875rem;
  line-height: 0.5;
  border-radius: 0.2rem;
`;

export const BlockButton = styled.div`
  background-color: #f69c2d;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #f6c42d;
  }
  &:active {
    border: 1ps solid #f69c2d;
  }
  box-shadow: 0 15px 15px rgba(182, 182, 182, 0.75);
`;

export const LinkButton = styled.a`
  color: #000;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;

  text-decoration: none;

  border-style: solid;
  border-width: 0px 0px 1px 0px;
  border-color: #f69c2d
  transition: all 0.2s ease-in;
`;

export const PrimaryLinkButton = styled.a`
  color: #f69c2d;
  font-size: 11px;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: #2979ff;
  }
  &:active {
    color: #2962ff;
  }
`;
