import React from 'react';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import { CardContainer, CardContainerBody, CardTitle } from '../../ui-components/card';
import {
  PaginatedTableContainer as TableContainer,
  PaginatedTableHeaderRow as TableHeader,
  PaginatedTableHeaderColumn as HeaderColumn,
  PaginatedTableRow as TableRow,
  PaginatedTabelCell as TableCell,
} from '../../ui-components/table';
import { Spinner } from '../../ui-components/spinner';
import { LinkButton } from '../../ui-components/buttons';
import { Body1 } from '../../ui-components/typography';
import Modal from '../../layout/modal';
import MakeRequest from '../../request';
import { getEditRequestStateApiKey, getEditRequestStateApiUrl } from '../../state/config/selectors';
import UI from '../../ui-components';

const CardBodyWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: calc(100vh - 200px);
  flex-direction: column;
`;

const NoRecordsMessageContainer = styled.h3`
  text-align: center;
  margin-top: 50px;
  font-size: 14px;
`;

const StyledCardTitle = styled(CardTitle)`
  font-size: 14px;
  margin-bottom: 40px;
`;

class Edits extends React.Component {
  state = {
    data: undefined,
    editJobStatus: 'Created',
    loading: true,
    lastKey: null,
  };

  componentDidMount = async () => {
    const { editRequestStateApiUrl, editRequestStateApiKey } = this.props;
    const { data } = await MakeRequest({
      endpoint: { url: editRequestStateApiUrl + '/records', xApiKey: editRequestStateApiKey },
    });

    this.setState({
      loading: false,
      data: data.jobs,
      lastKey: data.lastKey,
    });
  };

  fetchMoreData = async () => {
    let { data: oldData, lastKey: oldLastKey } = this.state;
    const { editRequestStateApiUrl, editRequestStateApiKey } = this.props;
    const { data } = await MakeRequest({
      endpoint: { url: editRequestStateApiUrl + '/records', xApiKey: editRequestStateApiKey },
      queryParams: [`lastKey=${encodeURIComponent(JSON.stringify(oldLastKey))}`],
    });

    let newData = oldData.concat(data.jobs);
    this.setState({ data: newData, lastKey: data.lastKey });
  };

  onCreateJob = () => {
    this.props.history.push('/editRequest');
  };
  render() {
    const { data, loading } = this.state;

    return (
      <React.Fragment>
        {loading && (
          <Modal>
            <Spinner />
          </Modal>
        )}
        <CardContainer style={{ width: '950px', height: '700px' }}>
          {/* {<SelectElement onChange={this.onEditJobStateChange} style={{ width: 200, margin: '0 auto', color: 'white' }}>
            <option value="created">Created</option>
            <option value="submitted">Submitted</option>
          </SelectElement>} */}
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {/* <UI.BlockButton onClick={() => this.onCreateJob()} style={{ width: '100px' }}>
              Create Edit Job
            </UI.BlockButton> */}
          </div>
          <CardContainerBody>
            {data !== undefined && data.length > 0 ? (
              <CardBodyWrapper>
                <StyledCardTitle>Created Edit Jobs </StyledCardTitle>
                <TableContainer id="recordsWrapper">
                  <TableHeader>
                    <HeaderColumn style={{ width: '50px' }}>&nbsp;&nbsp;&nbsp;ID</HeaderColumn>
                    <HeaderColumn>JOB NAME</HeaderColumn>
                    <HeaderColumn style={{ width: '200px' }}>REQUESTED BY</HeaderColumn>
                    <HeaderColumn>BRIEF</HeaderColumn>
                    <HeaderColumn>STATUS</HeaderColumn>
                    <HeaderColumn>CREATED</HeaderColumn>
                    <HeaderColumn style={{ width: '100px' }}>TIMELINE</HeaderColumn>
                  </TableHeader>
                  <InfiniteScroll
                    dataLength={this.state.data.length}
                    next={this.fetchMoreData}
                    style={{ paddingTop: 60 }}
                    inverse={false}
                    hasMore={this.state.lastKey !== null}
                    loader={<Body1>Loading ...</Body1>}
                    scrollableTarget="recordsWrapper"
                  >
                    {data.map((entry, index) => {
                      var isEven = index % 2 == 0;
                      return (
                        <TableRow isEven={isEven} key={entry.id}>
                          <TableCell style={{ width: '50px' }}>&nbsp;&nbsp;&nbsp;{entry.id}</TableCell>
                          <TableCell>{entry.jobName}</TableCell>
                          <TableCell ellipsis={true} style={{ width: '200px' }}>
                            {entry.requestedBy}
                          </TableCell>
                          <TableCell ellipsis={true}>{entry.brief}</TableCell>
                          <TableCell>{entry.statusMessage ? entry.statusMessage : 'N/A'}</TableCell>
                          <TableCell>{entry.createdTimestamp}</TableCell>
                          <TableCell style={{ width: '100px' }}>
                            <LinkButton href={`/history/${entry.id}`}>Timeline</LinkButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </InfiniteScroll>
                </TableContainer>
              </CardBodyWrapper>
            ) : (
              <NoRecordsMessageContainer>
                There are no edit jobs that can be displayed at the moment
              </NoRecordsMessageContainer>
            )}
          </CardContainerBody>
        </CardContainer>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  editRequestStateApiUrl: getEditRequestStateApiUrl()(state),
  editRequestStateApiKey: getEditRequestStateApiKey()(state),
}))(Edits);
