import React from 'react';
import { connect } from 'react-redux';
import { Spinner } from '../../ui-components/spinner';
import {
  getCommentsPlatform,
  getEditRequestStateApiKey,
  getEditRequestStateApiUrl,
  getS3Config,
  getS3FileCredentialsApiKey,
  getS3FileCredentialsApiUrl,
  getUploadApiKey,
  getUploadApiUrl,
  getSubmitApiKey,
  getSubmitApiUrl,
  getS3ConformBucket,
} from '../../state/config/selectors';

import {
  getSequenceId,
  getSequenceSettings,
  getIsSequenceSourceFilesValid,
  getIsProjectFileUploadedForApproval,
  getIsProjectFileUploadingForApproval,
  getIsXmlFileUploadingForApproval,
  getIsXmlFileUploadedForApproval,
  getPlatformNameForApproval,
  getApprovalProjectObjectKey,
  getApprovalXmlObjectKey,
  getIsXmlFileUploadedForSubmission,
  getIsXmlFileUploadingForSubmission,
  getIsProjectFileUploadedForSubmission,
  getIsProjectFileUploadingForSubmission,
  getSubmissionProjectObjectKey,
  getSubmissionXmlObjectKey,
} from '../../state/project/selectors';
import { initConformProcess } from '../../helpers/submitEditFlow';
import MakeRequest from '../../request';
import Approvals from './review/upload';
import Comments from './review/comments';
import Submit from './submit';

class EditReviewApprovals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newUpload: false,
      projDetails: { percentage: 0, uploadStep: 0 },
      mediaDetails: { percentage: 0, uploadStep: 0 },
      xmlDetails: { percentage: 0, uploadStep: 0 },
      percentage: 0,
      panelUploadStep: 0,
      files: [],
      isUploading: false,
      cannotSubmit: false,
      loading: true,
      waitingForInitConform: false,
      disableButtons: true,
      activeSequenceSettings: null,
      sourceComparedCorrectly: false,
    };
  }

  async componentDidUpdate(prevProps) {
    const {
      isProjectFileUploadedForApproval,
      isXmlFileUploadedForApproval,
      isProjectFileUploadedForSubmission,
      isXmlFileUploadedForSubmission,
      submitApiUrl,
      submitApiKey,
      approvalsPlatformName,
      s3ConformBucket,
      sequenceId,
      approvalXmlObjectKey,
      approvalProjectObjectKey,
      submissionXmlObjectKey,
      submissionProjectObjectKey,
    } = this.props;
    const {
      isProjectFileUploadedForApproval: prevIsProjectFileUploaedForApproval,
      isXmlFileUploadedForApproval: prevIsXmlFileUploadedForApproval,
      isProjectFileUploadedForSubmission: prevIsProjectFileUploadedForSubmission,
      isXmlFileUploadedForSubmission: prevIsXmlFileUploadedForSubmission,
    } = prevProps;

    if (
      isXmlFileUploadedForApproval &&
      isProjectFileUploadedForApproval &&
      !(prevIsXmlFileUploadedForApproval && prevIsProjectFileUploaedForApproval)
    ) {
      this.setState({ waitingForInitConform: true });
      await initConformProcess({
        url: `${submitApiUrl}/conform/${localStorage.getItem('editJobId')}`,
        xApiKey: submitApiKey,
        payload: {
          versionID: '1',
          conformType: 'approval',
          approvalPlatform: approvalsPlatformName,
          conformPlatform: 'adobeMediaEncoder',
          bucketName: s3ConformBucket,
          sequenceUUID: sequenceId,
          xmlObjectKey: approvalXmlObjectKey,
          projectObjectKey: approvalProjectObjectKey,
        },
      });
      this.setState({ waitingForInitConform: false });
    }

    if (
      isXmlFileUploadedForSubmission &&
      isProjectFileUploadedForSubmission &&
      !(prevIsXmlFileUploadedForSubmission && prevIsProjectFileUploadedForSubmission)
    ) {
      this.setState({ waitingForInitConform: true });
      await initConformProcess({
        url: `${submitApiUrl}/conform/${localStorage.getItem('editJobId')}`,
        xApiKey: submitApiKey,
        payload: {
          versionID: '1',
          conformType: 'submission',
          approvalPlatform: 'frameio',
          conformPlatform: 'adobeMediaEncoder',
          bucketName: s3ConformBucket,
          sequenceUUID: sequenceId,
          xmlObjectKey: submissionXmlObjectKey,
          projectObjectKey: submissionProjectObjectKey,
        },
      });
      this.setState({ waitingForInitConform: false });
    }
  }

  async componentDidMount() {
    const { editRequestStateApiKey, editRequestStateApiUrl } = this.props;

    let editJobId = localStorage.getItem('editJobId');

    let queryParams = ['fields=id,jobName,dueDate,requestedBy,brief,editType,files'];
    const response = await MakeRequest({
      endpoint: { url: `${editRequestStateApiUrl}/records/${editJobId}`, xApiKey: editRequestStateApiKey },
      queryParams,
    });

    if (response.data.jobs) {
      this.setState({ loading: false, editJobDetails: response.data.jobs[0] });
    } else {
      this.setState({ loading: false });
    }

    global.pproBridge.emit('get.active.sequence.id');
  }

  render() {
    const { loading, editJobDetails } = this.state;

    return (
      <React.Fragment>
        <Approvals
          panelBridge={global.pproBridge}
          isUploading={this.props.isXmlFileUploadingForApproval || this.props.isProjectFileUploadingForApproval}
          panelUploadError={this.state.panelUploadError}
          panelUploadStep={this.state.panelUploadStep}
          percentage={this.props.isXmlFileUploadedForApproval && this.props.isProjectFileUploadedForApproval ? 100 : 25}
          panelUploadSuccess={this.props.isXmlFileUploadedForApproval && this.props.isProjectFileUploadedForApproval}
          approvalPlatforms={this.props.commentsPlatform}
          disableButtons={!this.props.isSequenceSourceFilesValid || this.props.sequenceId == '0'}
          activeSequenceId={this.props.sequenceId}
          waitingForInitConform={this.state.waitingForInitConform}
        />
        <Comments
          newUpload={this.state.newUpload}
          panelBridge={global.pproBridge}
          approvalPlatforms={this.props.commentsPlatform}
        />
        <Submit
          panelBridge={global.pproBridge}
          isUploading={this.props.isXmlFileUploadingForSubmission || this.props.isProjectFileUploadingForSubmission}
          panelUploadError={this.state.panelUploadError}
          panelUploadStep={this.state.panelUploadStep}
          percentage={
            this.props.isXmlFileUploadedForSubmission && this.props.isProjectFileUploadedForSubmission ? 100 : 25
          }
          panelUploadSuccess={
            this.props.isXmlFileUploadedForSubmission && this.props.isProjectFileUploadedForSubmission
          }
          cannotSubmit={this.state.cannotSubmit}
          editJobDetails={editJobDetails}
          activeSequenceSettings={this.props.sequenceSettings}
          disableButtons={!this.props.isSequenceSourceFilesValid || this.props.sequenceId == '0'}
          activeSequenceId={this.props.sequenceId}
          waitingForInitConform={this.state.waitingForInitConform}
        />
        {loading ? (
          <div id="loading">
            <Spinner />
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  s3Config: getS3Config()(state),
  credentialsApiUrl: getS3FileCredentialsApiUrl()(state),
  credentialsApiKey: getS3FileCredentialsApiKey()(state),
  uploadApiKey: getUploadApiKey()(state),
  uploadApiUrl: getUploadApiUrl()(state),
  commentsPlatform: getCommentsPlatform()(state),
  editRequestStateApiUrl: getEditRequestStateApiUrl()(state),
  editRequestStateApiKey: getEditRequestStateApiKey()(state),
  submitApiUrl: getSubmitApiUrl()(state),
  submitApiKey: getSubmitApiKey()(state),
  //Sequence related stuff
  sequenceId: getSequenceId(state),
  sequenceSettings: getSequenceSettings(state),
  isSequenceSourceFilesValid: getIsSequenceSourceFilesValid(state),
  s3ConformBucket: getS3ConformBucket(state),
  //Conforms related stuff
  isXmlFileUploadedForApproval: getIsXmlFileUploadedForApproval(state),
  isXmlFileUploadingForApproval: getIsXmlFileUploadingForApproval(state),
  isXmlFileUploadedForSubmission: getIsXmlFileUploadedForSubmission(state),
  isXmlFileUploadingForSubmission: getIsXmlFileUploadingForSubmission(state),
  isProjectFileUploadedForApproval: getIsProjectFileUploadedForApproval(state),
  isProjectFileUploadingForApproval: getIsProjectFileUploadingForApproval(state),
  isProjectFileUploadedForSubmission: getIsProjectFileUploadedForSubmission(state),
  isProjectFileUploadingForSubmission: getIsProjectFileUploadingForSubmission(state),

  approvalsPlatformName: getPlatformNameForApproval(state),
  approvalProjectObjectKey: getApprovalProjectObjectKey(state),
  approvalXmlObjectKey: getApprovalXmlObjectKey(state),
  submissionProjectObjectKey: getSubmissionProjectObjectKey(state),
  submissionXmlObjectKey: getSubmissionXmlObjectKey(state),
}))(EditReviewApprovals);
