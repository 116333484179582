import React from 'react';
import { compose } from 'lodash/fp';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { withSnackbar } from 'react-simple-snackbar';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { PrimaryButtonLinkElement } from '../../ui-components/buttons';
import { InputElement } from '../../ui-components/inputs';
import config from '../../config/config';
import { loginUser } from '../../state/user/actions';
import { getIsAuthenticated } from '../../state/user/selectors';

const Container = styled.div`
  display: flex;
  width: 100%;
  color: white;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 200px;
`;

const LoginMessage = styled.h1`
  font-size: 22px;
  color: #000;
`;

const TimerContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Timer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.div`
  color: #fff;
  text-transform: uppercase;
`;

const Value = styled.div`
  font-size: 40px;
`;

const LoginCodeContainer = styled.div`
  h1 {
    margin: 0px;
    margin-top: 20px;
  }
`;

const LoginCodeText = styled.h1`
  font-size: 32px;
  letter-spacing: 10px;
  text-align: center;
  margin-top: 20px;

  span {
    font-size: 32px;
    letter-spacing: 10px;
  }
`;

class Login extends React.Component {
  state = {};
  haveCodeInUrl = false;
  havePproInUrl = false;

  constructor(props) {
    super(props);

    if (this.urlIncludesCode()) {
      this.haveCodeInUrl = true;
    }

    if (this.urlIncludesPpro()) {
      this.havePproInUrl = true;
    }
  }

  urlIncludesPpro = () => {
    let url = window.location.href;
    if (url.includes('ppro')) {
      return true;
    }
    return false;
  };

  urlIncludesCode = () => {
    let url = window.location.href;
    if (url.includes('code')) {
      return true;
    }
    return false;
  };

  componentDidMount = async () => {
    if (this.haveCodeInUrl) {
      let code = window.location.href.split('code=')[1];

      let redirectUrl = window.location.origin + window.location.pathname;

      let authUrl = `${config.auth['get-access-token']}?code=${code}&type=authorization_code&client_id=${
        process.env.COGNITO_CLIENT_ID
      }&redirect_url=${redirectUrl + (this.havePproInUrl ? '?ppro=1' : '')}&scope=${process.env.COGNITO_SCOPE}`;

      const {
        data: {
          body: { result },
        },
      } = await axios.get(authUrl, { headers: { 'x-api-key': process.env.AUTH_API_KEY } });

      let response = JSON.parse(result);

      if (!response.error) {
        localStorage.setItem('accessToken', response.access_token);
        localStorage.setItem('refreshToken', response.refresh_token);
        localStorage.setItem('idToken', response.id_token);
        localStorage.setItem('isAuthenticated', true);

        if (this.havePproInUrl) {
          await this.props.loginUser({
            accessToken: response.access_token,
            idToken: response.id_token,
            refreshToken: response.refresh_token,
          });
          this.setState({ accessTokenResponse: response }, this.requestCode);
        } else {
          await this.props.loginUser({
            accessToken: response.access_token,
            idToken: response.id_token,
            refreshToken: response.refresh_token,
          });

          this.props.history.push('/');
          // window.location = process.env.WORKFLOW_ROOT;
        }
      } else {
        this.props.history.push('/');
        // window.location = process.env.WORKFLOW_ROOT;
      }
    }
  };

  requestCode = async () => {
    const response = this.state.accessTokenResponse;

    const {
      data: {
        result: { code },
      },
    } = await axios.post(
      config.auth['get-login-code-for-panel'],
      { accessToken: response.access_token, idToken: response.id_token, refreshToken: response.refresh_token },
      {
        headers: { 'x-api-key': process.env.AUTH_API_KEY, Authorization: `Bearer ${response.access_token}` },
      }
    );

    this.setState({ loginWithCode: true, loginCode: code, requestCodeAgain: false });
  };

  swapCodeForAccessCredentials = async code => {
    this.setState({ disableCodeInputBox: true });

    let result = null;
    try {
      let { data } = await axios.get(config.auth['get-login-code-for-panel'] + '/' + code, {
        headers: { 'x-api-key': process.env.AUTH_API_KEY },
      });
      result = data.result;
    } catch (e) {
      let { data } = e.response;
      result = data.result;
    }

    return result;
  };

  redirectToCognitoLogin = e => {
    e.preventDefault();

    let currentLocation = window.location.href;
    let loginUrl = `${process.env['COGNITO_LOGIN_URL']}?client_id=${process.env['COGNITO_CLIENT_ID']}&response_type=${
      process.env['COGNITO_RESPONSE_TYPE']
    }&scope=${process.env['COGNITO_SCOPE']}&redirect_uri=${currentLocation}`;

    if (localStorage.getItem('isAdobePanel')) {
      global.pproBridge.emit('cognito.login', { loginUrl: `${loginUrl}?ppro=1` });
      this.setState({ showCodeInputBox: true });
    } else {
      window.location = loginUrl;
    }
  };

  renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return (
        <Timer
          style={{ color: 'red', textTransform: 'uppercase', cursor: 'pointer', fontWeight: 500 }}
          onClick={this.getNewCode}
        >
          Code has expired
        </Timer>
      );
    }

    return (
      <Timer>
        <Text>Expires In</Text>
        <Value>{remainingTime}</Value>
        <Text>seconds</Text>
      </Timer>
    );
  };

  onCodeInputBoxChange = async e => {
    e.preventDefault();

    if (e.target.value.length == 6) {
      const response = await this.swapCodeForAccessCredentials(e.target.value);

      if (response.accessToken) {
        const { accessToken, refreshToken, idToken } = response;
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('idToken', idToken);
        localStorage.setItem('isAuthenticated', true);

        global.pproBridge.emit('on.user.login', { accessToken, refreshToken, idToken });
      } else {
        this.setState({ loginCodeError: true, loginCodeErrorMessage: response, disableCodeInputBox: false });
      }
    }
  };

  copyToClipboard = e => {
    const { openSnackbar } = this.props;

    e.preventDefault();
    let copyText = document.getElementById('login-code');
    let textArea = document.createElement('textarea');
    textArea.value = copyText.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();

    openSnackbar('Login code copied to clipboard');
  };

  render = () => {
    if (this.props.isAuthenticated && !this.haveCodeInUrl) {
      window.location = '/';
      return null;
    }

    return (
      <React.Fragment>
        {!this.haveCodeInUrl && !this.state.showCodeInputBox && (
          <Container>
            <img src={require('../../static/images/login.png')} />
            <LoginMessage>Please click on the button below to login.</LoginMessage>
            <PrimaryButtonLinkElement style={{ maxWidth: 200 }} onClick={this.redirectToCognitoLogin}>
              LOGIN
            </PrimaryButtonLinkElement>
          </Container>
        )}

        {!this.haveCodeInUrl && this.state.showCodeInputBox && (
          <Container>
            <img src={require('../../static/images/login.png')} />
            <LoginMessage>Please enter 6-digit code below</LoginMessage>
            <InputElement
              type="text"
              placeholder="Code"
              onChange={this.onCodeInputBoxChange}
              maxLength={6}
              disabled={this.state.disableCodeInputBox}
              style={Object.assign(
                {},
                { textAlign: 'center', letterSpacing: 10, textTransform: 'uppercase' },
                this.state.loginCodeError && { borderColor: 'red' }
              )}
            />
            {this.state.loginCodeError && <LoginMessage style={{ color: 'red' }}>Invalid or expired code</LoginMessage>}
            <PrimaryButtonLinkElement
              onClick={() => (window.location = '/login')}
              style={{ width: 'auto', marginTop: 20 }}
            >
              <i className="fa fa-arrow-left"></i> Go Back
            </PrimaryButtonLinkElement>
          </Container>
        )}

        {this.haveCodeInUrl && !this.state.loginWithCode && (
          <Container>
            <img src={require('../../static/images/spinner.gif')} />
          </Container>
        )}

        {this.haveCodeInUrl && this.state.loginWithCode && (
          <Container>
            <TimerContainer>
              <CountdownCircleTimer
                key={'login-code-' + this.state.loginCode}
                isPlaying
                duration={parseInt(process.env.PPRO_LOGIN_CODE_EXPIRE_DURATION)}
                colors={[['#004777', 0.33], ['#F7B801', 0.33], ['#A30000']]}
                onComplete={() => {
                  this.setState({ requestCodeAgain: true });
                }}
              >
                {this.renderTime}
              </CountdownCircleTimer>
            </TimerContainer>

            <LoginCodeContainer>
              <h1>Enter the code below in Premiere Pro Panel</h1>
              <LoginCodeText>
                <span id="login-code">{this.state.loginCode}</span>
                &nbsp;
                <i
                  className="fa fa-files-o"
                  style={{ cursor: 'pointer', color: '#3c6bf6', fontSize: 25 }}
                  onClick={this.copyToClipboard}
                  title={'Copy Code'}
                ></i>
              </LoginCodeText>
            </LoginCodeContainer>

            {this.state.requestCodeAgain && (
              <PrimaryButtonLinkElement
                style={{ maxWidth: 150, fontSize: 16, marginTop: 20 }}
                onClick={this.requestCode}
              >
                Request New Code
              </PrimaryButtonLinkElement>
            )}

            <a
              style={{ fontSize: 16, marginTop: 20, textDecoration: 'none', textTransform: 'uppercase' }}
              href={process.env.WORKFLOW_ROOT}
            >
              Go to Home Page
            </a>
          </Container>
        )}
      </React.Fragment>
    );
    // }
  };
}

const snackBarOptions = {
  position: 'bottom-center',
  style: {
    backgroundColor: 'midnightblue',
    border: '2px solid lightgreen',
    color: 'white',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '36px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  closeStyle: {
    color: 'white',
    fontSize: '16px',
  },
};

export default compose(
  withRouter,
  connect(
    state => ({
      isAuthenticated: getIsAuthenticated()(state),
    }),
    dispatch => ({
      loginUser: accessCredentials => dispatch(loginUser(accessCredentials)),
    })
  ),
  withSnackbar
)(Login, snackBarOptions);
