import { get, getOr } from 'lodash/fp';

export const getEditJobId = state => get('project.editJobId')(state);
export const getSequenceId = state => getOr('0', 'project.sequenceId', state);
export const getSequenceSettings = state => get('project.settings')(state);
export const getIsSequenceSourceFilesValid = state => get('project.sequenceComparisonResult')(state);
export const getProgressBars = state => get('project.progressBars')(state);

//Approvals and Submissions
export const getIsProjectFileUploadingForApproval = state =>
  getOr(false, 'project.approval.uploadingProjectFile', state);
export const getIsXmlFileUploadingForApproval = state => getOr(false, 'project.approval.uploadingXmlFile', state);
export const getIsProjectFileUploadedForApproval = state =>
  getOr(false, 'project.approval.uploadingProjectFileCompleted', state);
export const getIsXmlFileUploadedForApproval = state =>
  getOr(false, 'project.approval.uploadingXmlFileCompleted', state);
export const getPlatformNameForApproval = state => getOr('frameio', 'project.approval.approvalPlatformName', state);
export const getApprovalProjectObjectKey = state => get('project.approval.projectObjectKey')(state);
export const getApprovalXmlObjectKey = state => get('project.approval.xmlObjectKey')(state);

export const getIsProjectFileUploadingForSubmission = state =>
  getOr(false, 'project.submission.uploadingProjectFile', state);
export const getIsXmlFileUploadingForSubmission = state => getOr(false, 'project.submission.uploadingXmlFile', state);
export const getIsProjectFileUploadedForSubmission = state =>
  getOr(false, 'project.submission.uploadingProjectFileCompleted', state);
export const getIsXmlFileUploadedForSubmission = state =>
  getOr(false, 'project.submission.uploadingXmlFileCompleted', state);
export const getPlatformNameForSubmission = state => getOr('frameio', 'project.submission.approvalPlatformName', state);
export const getSubmissionProjectObjectKey = state => get('project.submission.projectObjectKey')(state);
export const getSubmissionXmlObjectKey = state => get('project.submission.xmlObjectKey')(state);
