import React from 'react';
import moment from 'moment';

import { MainCommentContainer, CommentContainer, CommentAuthor } from '../../../../ui-components/comments';

export default function SubComment(props) {
  const comment = props['comment'];

  return (
    <MainCommentContainer key={comment['id']}>
      <CommentContainer>
        <div>
          <CommentAuthor>
            {(comment['owner'] || comment['anonymous_user'])['name']}
            <span style={{ color: 'grey' }}>
              {' - '}
              {moment(comment['inserted_at']).fromNow()}
            </span>
          </CommentAuthor>
          <div style={{ marginTop: 5 }}> {comment['text']}</div>
        </div>
      </CommentContainer>
    </MainCommentContainer>
  );
}
