import React, { Component } from 'react';
import styled from 'styled-components';
import { ProgressBar } from '../../ui-components/progressBar';
import S3Upload from '../../helpers/s3Upload';

const Alert = styled.div`
  background-color: ${props =>
    props.alertType === 'error' ? '#f7675b' : props.alertType === 'success' ? '#6ebd70' : '#0e537d'}
  color: white;
  min-height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5px;
  font-size: 11px;
  border-radius: 4px;
`;

const CloseAlert = styled.span`
  margin-left: auto;
  margin-right: 5px;
  cursor: pointer;
`;

const UploadsContainer = styled.div`
  margin: 20px 0px;
`;

const UploadFileContainer = styled.div`
  margin-bottom: 5px;
`;

const UploadFileName = styled.p`
  margin: 0 0 4px;
`;
class DropzoneUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAlert: false,
      alertMessage: null,
      filesUploading: [],
    };
  }

  componentDidMount() {
    this.registerDropZoneEvents();
  }

  renderAlert() {
    const { alertMessage } = this.state;

    const closeAlert = () => {
      this.setState({ showAlert: false, alertMessage: null });
    };

    return (
      <Alert alertType={'error'}>
        {alertMessage}
        <CloseAlert onClick={closeAlert}>&times;</CloseAlert>
      </Alert>
    );
  }

  renderFileUploadProgressBars = () => {
    const { filesUploading } = this.state;
    return (
      <UploadsContainer>
        <Alert alertType="primary" style={{ marginTop: 5, marginBottom: 5, padding: 10 }}>
          Once the file upload completes. The file will be ready for use after processing. Refresh
          this window and check if the file is available shortly.
        </Alert>
        {filesUploading.map(file => (
          <UploadFileContainer key={file.name}>
            <UploadFileName style={{ color: 'white', margin: '0 0 4px' }}>
              Uploading {file.name}
            </UploadFileName>
            <ProgressBar percentage={file.uploadProgress} small={true} />
          </UploadFileContainer>
        ))}
      </UploadsContainer>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.state.showAlert && this.renderAlert()}
        {this.state.filesUploading.length > 0 && this.renderFileUploadProgressBars()}
        <div id="dropzone">{this.props.children}</div>
      </React.Fragment>
    );
  }

  registerDropZoneEvents = () => {
    let dropArea = document.getElementById('dropzone');

    const preventDefaults = e => {
      e.preventDefault();
      e.stopPropagation();
    };

    const highlight = () => {
      dropArea.classList.add('highlight');
    };

    const unhighlight = () => {
      dropArea.classList.remove('highlight');
    };

    const handleDrop = e => {
      const { currentLocationInBucket } = this.props;

      if (!currentLocationInBucket.startsWith('/source/external')) {
        this.setState({
          showAlert: true,
          alertMessage: 'Upload is allowed for folders in root/source/external',
        });
        return;
      }

      let shouldUpload = confirm(`Upload to ${currentLocationInBucket}`);
      if (!shouldUpload) {
        return;
      }

      let dt = e.dataTransfer;
      let files = dt.files;
      files = [...files];

      const folderLocation = currentLocationInBucket.split('source/')[1];

      files.map((file, index) => {
        const { filesUploading } = this.state;
        const barToAdd = { name: file.name, uploadProgress: 0 };
        filesUploading.push(barToAdd);

        this.setState({ filesUploading: filesUploading }, () => {
          S3Upload.upload({
            file: file,
            path: `${folderLocation}${file.name}`,
            bucket: process.env.S3_LANDING,
            progressCallback: percent => {
              if (filesUploading.includes(barToAdd)) {
                filesUploading[index].uploadProgress = percent * 100;
                this.setState({ filesUploading: filesUploading });
              }
            },
            completeCallback: xhr => {
              if (!xhr.response) {
                filesUploading.splice(index, 1);
                this.setState({
                  showAlert: true,
                  alertMessage: 'File is already present.',
                });
              }
            },
          });
        });
      });
    };

    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      dropArea.addEventListener(eventName, preventDefaults, false);
    });

    ['dragenter', 'dragover'].forEach(eventName => {
      dropArea.addEventListener(eventName, highlight, false);
    });

    ['dragleave', 'drop'].forEach(eventName => {
      dropArea.addEventListener(eventName, unhighlight, false);
    });

    dropArea.addEventListener('drop', handleDrop, false);
  };
}

export default DropzoneUpload;
