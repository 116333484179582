import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import config from '../../config/config';
import { ProgressBar } from '../../ui-components/progressBar';
import { Body1 } from '../../ui-components/typography';
import { getEditFileSelectApiKey, getEditFileSelectApiUrl } from '../../state/config/selectors';
import { getProgressBars } from '../../state/project/selectors';

const getAuthorizationHeader = () => {
  if (process.env.AUTH_HEADERS_REQUIRED === 'true') {
    return {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    };
  }
};

class Downloader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editJobId: localStorage.getItem('editJobId'),
    };
  }

  componentDidMount = async () => {
    this.getPreviouslyDownloadedFiles();
    window.addEventListener('beforeunload', this.beforeunloadHandler);
  };

  beforeunloadHandler = event => {
    if (this.props.progressBars.length) {
      const isCompleted = currentValue => currentValue.percentage === 100;

      if (!this.props.progressBars.every(isCompleted)) {
        event.returnValue = 'Files are downloading are you sure that you want to navigate away?';
      }
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener('beforeunload', this.beforeunloadHandler);
  };

  getPreviouslyDownloadedFiles = async () => {
    const { data: files } = await axios.get(
      `${config['edit_request_state']['get_details']}/${localStorage.getItem('editJobId')}`,
      {
        headers: { 'x-api-key': process.env.EDIT_REQUEST_STATE_API_KEY, ...getAuthorizationHeader() },
        params: { fields: 'files' },
      }
    );

    this.setState({ previouslyDownloadedFiles: files });
  };

  download = node => {
    if (node.url) {
      this.setState({ progressIndicator: true });

      const editJobId = this.state.editJobId;
      const downloadUrl = null;
      const fileName = node.name;
      const nodeUrl = node.url;
      const s3location = node.url;

      global.pproBridge.emit('app.editPanel.fileTransfer.downloadFileToMagentaWorkstation', {
        fileName,
        downloadUrl,
        nodeUrl,
        s3location,
        editJobId,
        s3Bucket: this.props.mediaBucket,
        name: nodeUrl,
        fileSelectApiKey: this.props.fileSelectApiKey,
        fileSelectApiUrl: this.props.fileSelectApiUrl,
      });
    }
  };

  render() {
    const indicatorStyle = {
      display: this.props.progressBars.length > 0 ? 'block' : 'none',
      marginTop: 10,
    };

    return (
      <React.Fragment>
        <div style={indicatorStyle}>
          {this.props.progressBars.length > 0 &&
            this.props.progressBars
              .slice(0)
              .reverse()
              .map(progressBar => (
                <div key={progressBar.fileName}>
                  <div style={{ marginTop: '1rem' }}>
                    {!progressBar.error ? (
                      <Body1>
                        {progressBar.percentage == 100 ? 'Downloaded' : 'Downloading'} {progressBar.fileName}
                      </Body1>
                    ) : (
                      <Body1>Error wile downloading {progressBar.fileName}</Body1>
                    )}
                  </div>
                  <ProgressBar
                    percentage={progressBar.percentage}
                    fill={progressBar.percentage == 100 ? '#0e537d' : undefined}
                    error={progressBar.error}
                  />
                </div>
              ))}
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({
    fileSelectApiUrl: getEditFileSelectApiUrl()(state),
    fileSelectApiKey: getEditFileSelectApiKey()(state),
    progressBars: getProgressBars(state),
  }),
  null,
  null,
  { forwardRef: true }
)(Downloader);
