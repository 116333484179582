import styled from 'styled-components';
import { PrimaryTextColor } from './colors';

export const InputElement = styled.input`
  display: block;
  <<<<<<<updatedupstreamfont-size: 11px;
  color: #b1b1b1;
  background-color: rgb(50, 56, 62);
  background-clip: padding-box;
  border: 1px solid #b4b6b6;
  border-color: ${props => (!!props.hasError ? 'red' : '#b4b6b6')};
  =======font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  background-color: #dcdcdc;
  background-clip: padding-box;
  border: 1px solid;
  border-color: ${props => (!!props.hasError ? 'red' : '#b4b6b6')};
  >>>>>>>stashedchangesborder-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  ::placeholder {
    color: #495057;
  }
  outline: none;
  height: 35px;
  width: 100%;
`;

export const TextareaElement = styled.textarea`
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background: ##b4b6b6;
  color: #000;
  background-clip: padding-box;
  border: 1px solid;
  border-color: ${props => (!!props.hasError ? 'red' : '#b4b6b6')};
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 1px solid;
`;

export const SelectElement = styled.select`
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 11px;
  color: #000;
  background-color: #dcdcdc;
  background-clip: padding-box;
  height: 40px;
  border: 1px solid #b4b6b6;

  :hover {
    bordercolor: #f69c2d;
  }
  :focus {
    bordercolor: #f69c2d;
  }
  :active {
    bordercolor: #f69c2d;
  }
  input {
    box-shadow: none;
  }
  option {
    color: black;
    background: white;
    font-weight: small;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

export const GroupedElement = styled.div`
  margin-bottom: 1rem;
  display: block;
  &::before {
    box-sizing: border-box;
  }
  &::after {
    box-sizing: border-box;
  }
`;

export const InvalidFeedback = styled.div`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
  box-shadow: 0 0 black;
`;

export const CheckboxInputWrapper = styled.div`
  position: relative;
  display: block;
  padding-left: 0.6rem;
  margin-bottom: 5px;
  border: ${props => props.hasError && '1px solid'};
  border-color: ${props => props.hasError && 'red'};
`;

export const CheckboxInputElement = styled.input`
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
`;

export const CheckboxLabelElement = styled.label`
  margin-bottom: 0;
  margin-left: 15px;
`;

export const CustomFile = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
`;

export const CustomFileInput = styled.input`
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
  cursor: pointer;
  [type='file'] {
    height: 50px;
  }
`;

export const Label = styled.label`
  font-size: 11px;
  font-weight: bold;
  color: ${PrimaryTextColor};
  display block;
  padding-bottom: 10px;

`;
