import React from 'react';
import { connect } from 'react-redux';
import { appBootstrapComplete, bootstrapConfig, bootstrapRedux } from '../state/app/actions';
import { getIsBootstrapped } from '../state/app/selectors';
import { getIsAuthenticated } from '../state/user/selectors';

class Bootstrap extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount = async () => {
    const { appBootstrapComplete, bootstrapRedux, bootstrapConfig } = this.props;

    await bootstrapRedux();

    await bootstrapConfig();

    await appBootstrapComplete();
  };

  render = () => {
    const { isBootstrapped, children } = this.props;
    return isBootstrapped ? children : null;
  };
}

export default connect(
  state => ({
    isBootstrapped: getIsBootstrapped()(state),
    isAuthenticated: getIsAuthenticated()(state),
  }),
  dispatch => ({
    bootstrapRedux: () => dispatch(bootstrapRedux()),
    bootstrapConfig: () => dispatch(bootstrapConfig()),
    appBootstrapComplete: () => dispatch(appBootstrapComplete()),
  })
)(Bootstrap);
