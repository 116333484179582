import axios from 'axios';
import mimeTypes from 'mime-types';

export const project_id = process.env.FRAMEIO_PROJECT_ID;
export const parent_asset_id = process.env.FRAMEIO_APPROVALS_FOLDER;
export const frameioTeamId = '4921c65b-a5aa-42ee-b03d-f5b580374a6d'; // Nekta

export const tokenUploadAndReviewLinks =
  'fio-u-Vs8UuWuPRmeXubP2lgURxHawqa_O3-EIrjAF_CmUrfq1t1K3EHASw7LfRFPDqdtq';
export const tokenManageComments =
  'fio-u-mdGaJmdPfFhnjN3OdtmC9is5gvLZIF5-9rCS5Bkce1lD_-S3EykV6EWh4JqiLNiW';
export const apiPath = 'https://api.frame.io/v2/';

export const searchAssets = async (criteria = {}) => {
  const { data } = await axios.post(
    `${apiPath}search/assets`,
    {
      q: '*',
      team_id: frameioTeamId,
      project_id,
      ...criteria,
    },
    {
      headers: { Authorization: `bearer ${tokenUploadAndReviewLinks}` },
    }
  );
  return data;
};

const createAsset = async (payload = {}, { _parent_asset_id = parent_asset_id } = {}) => {
  const { data } = await axios.post(`${apiPath}assets/${_parent_asset_id}/children`, payload, {
    headers: { Authorization: `bearer ${tokenUploadAndReviewLinks}` },
  });

  return data;
};

const frameIoClient = {
  getAssetChildrens: async assetId => {
    const { data } = await axios.get(`${apiPath}assets/${assetId}/children`, {
      headers: { Authorization: `bearer ${tokenUploadAndReviewLinks}` },
    });
    return data;
  },
  getAsset: async assetId => {
    const { data } = await axios.get(`${apiPath}assets/${assetId}`, {
      headers: { Authorization: `bearer ${tokenUploadAndReviewLinks}` },
    });
    return data;
  },
  getComments: async function(assetId) {
    const { data } = await axios.get(`${apiPath}assets/${assetId}/comments`, {
      headers: { Authorization: `bearer ${tokenManageComments}` },
    });
    return data;
  },
  createComment: async (versionStackId, text) => {
    const { data } = await axios.post(
      `${apiPath}assets/${versionStackId}/comments`,
      {
        annotation: null,
        entities: [],
        pitch: null,
        private: false,
        target_asset_id: null,
        text: text,
        timestamp: 0,
        yaw: null,
      },
      { headers: { Authorization: `bearer ${tokenManageComments}` } }
    );
    return data;
  },
  markCommentCompleted: async commentId => {
    const { data } = await axios.post(`${apiPath}comments/${commentId}/complete`, undefined, {
      headers: { Authorization: `bearer ${tokenManageComments}` },
    });
    return data;
  },
  replyToComment: async (commentId, text) => {
    const { data } = await axios.post(
      `${apiPath}comments/${commentId}/replies`,
      { entities: [], text },
      { headers: { Authorization: `bearer ${tokenManageComments}` } }
    );
    return data;
  },
  createFileAsset: async (payload = {}, { _parent_asset_id = parent_asset_id } = {}) => {
    payload.properties = { type: 'review', ...payload.properties };
    payload = {
      type: 'file',
      filetype: mimeTypes.lookup(payload.name),
      ...payload,
    };
    return await createAsset(payload, { _parent_asset_id });
  },
  createFolderAssetIfNotExist: async (editJobId, reviewType) => {
    const folders = await searchAssets({ properties: { editJobId, reviewType }, type: 'folder' });
    if (folders.length) {
      return folders[0].id;
    } else {
      const { id } = await createAsset({
        type: 'folder',
        name: `${reviewType}-${editJobId}`,
        properties: { editJobId, reviewType },
      });
      return id;
    }
  },
  addAssetToVersionStack: async (next_asset_id, editJobId, reviewType) => {
    const assetsInReviewGroup = await searchAssets({
      properties: { editJobId, reviewType, type: 'review' },
    });

    const getVersionStackId = () => {
      for (let i = 0; i < assetsInReviewGroup.length; i++) {
        if (assetsInReviewGroup[i].parent.type === 'version_stack') {
          return assetsInReviewGroup[i].parent.id;
        }
      }
    };

    const chooseFirstAssetId = () => {
      for (let i = 0; i < assetsInReviewGroup.length; i++) {
        if (assetsInReviewGroup[i].id !== next_asset_id) {
          return assetsInReviewGroup[i].id;
        }
      }
    };

    let assetId;
    const firstAssetIntoGroup = assetsInReviewGroup.length === 0;
    if (!firstAssetIntoGroup) {
      assetId = getVersionStackId();
      const versionStackNotYetInitialized = !assetId;
      if (versionStackNotYetInitialized) {
        assetId = chooseFirstAssetId();
      }

      if (assetId) {
        const { data } = await axios.post(
          `${apiPath}assets/${assetId}/version`,
          {
            next_asset_id,
          },
          {
            headers: { Authorization: `bearer ${tokenUploadAndReviewLinks}` },
          }
        );
        return data;
      }
    }
  },
  upload: async function({ asset, file, progress: progressCallback }) {
    const frameioUploader = new FrameioUploader({ asset, file, progressCallback });
    await frameioUploader.start();
  },
};
export default frameIoClient;

const MaxThreadsQuantity = 5;

class FrameioUploader {
  constructor({ asset, file, progressCallback }) {
    this.upload_urls = asset.upload_urls;
    const total_size = asset.filesize;
    this.chunkSize = parseInt(Math.ceil(total_size / this.upload_urls.length));
    this.chunksQueue = new Array(this.upload_urls.length)
      .fill()
      .map((_, index) => index)
      .reverse();
    this.activeConnections = 0;
    this.progressCache = {};
    this.uploadedSize = 0;
    Object.assign(this, { asset, file, progressCallback });
  }

  async start() {
    return new Promise(accept => {
      this.finished = accept;
      this.nextChunk();
    });
  }

  nextChunk() {
    if (this.activeConnections >= MaxThreadsQuantity) {
      return;
    }

    if (!this.chunksQueue.length) {
      if (!this.activeConnections) {
        this.finished();
      }
      return;
    }

    const chunkId = this.chunksQueue.pop();
    const begin = chunkId * this.chunkSize;
    const chunk = this.file.slice(begin, begin + this.chunkSize, this.file.type);
    this.activeConnections += 1;
    this.sendChunk(chunk, chunkId)
      .then(() => {
        this.activeConnections -= 1;
        this.nextChunk();
      })
      .catch(error => {
        this.activeConnections -= 1;
        this.chunksQueue.push(chunkId);
      });

    this.nextChunk();
  }

  async sendChunk(chunk, chunkId) {
    return await axios.put(this.upload_urls[chunkId], chunk, {
      headers: {
        'content-type': this.asset.filetype,
        'x-amz-acl': 'private',
      },
      onUploadProgress: event => {
        if (event.type === 'progress' || event.type === 'error' || event.type === 'abort') {
          this.progressCache[chunkId] = event.loaded;
        }

        if (event.type === 'loadend') {
          this.uploadedSize += this.progressCache[chunkId] || 0;
          delete this.progressCache[chunkId];
        }

        const inProgress = Object.keys(this.progressCache).reduce(
          (memo, id) => memo + this.progressCache[id],
          0
        );
        const sentLength = Math.min(this.uploadedSize + inProgress, this.file.size);

        const percent = Math.round((sentLength / this.file.size) * 100 * 100) / 100;
        this.progressCallback(percent);
      },
    });
  }
}
