import EntireBucket from './EntireBucket';
import BucketPrefix from './BucketPrefix';

class DataSource {
  dataSource = null;

  constructor(dataSource = 'EntireBucket', otherProps) {
    if (dataSource == 'EntireBucket') {
      this.dataSource = new EntireBucket({ ...otherProps.entireBucketEndpoint, mediaBucket: otherProps.mediaBucket });
    } else if (dataSource == 'BucketPrefix') {
      this.dataSource = new BucketPrefix({ ...otherProps.bucketPrefixEndpoint, mediaBucket: otherProps.mediaBucket });
    } else {
      throw new Error('Unknown dataSource');
    }
  }

  init = async () => await this.dataSource.init();

  getFirstNode = () => this.dataSource.getFirstNode();

  getNode = node => this.dataSource.getNode({ node });

  getNodeByPrefix = async nodeId => await this.dataSource.getNodeByPrefix(nodeId);

  getSearchResults = async (currentNode, searchTerm) => await this.dataSource.getSearchResults(currentNode, searchTerm);

  setSearchCallback = searchCallback =>
    this.dataSource.setSearchCallback && this.dataSource.setSearchCallback(searchCallback);
}

export default DataSource;
