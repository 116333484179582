import styled from 'styled-components';

export const Spinner = styled.div`
  position: absolute;
  left: calc(50% - 30px);
  top: 50%;
  height: 60px;
  width: 60px;
  margin: 0px auto;
  -webkit-animation: rotation 0.6s infinite linear;
  -moz-animation: rotation 0.6s infinite linear;
  -o-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
  border-left: 6px solid rgba(246, 157, 45, 0.15);
  border-right: 6px solid rgba(246, 157, 45, 0.15);
  border-bottom: 6px solid rgba(246, 157, 45, 0.15);
  border-top: 6px solid rgba(246, 157, 45, 0.8);
  border-radius: 100%;
`;

export const Spinner2 = styled.div`
  height: 60px;
  width: 60px;
  margin: 0px auto;
  -webkit-animation: rotation 0.6s infinite linear;
  -moz-animation: rotation 0.6s infinite linear;
  -o-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
  border-left: 6px solid rgba(246, 157, 45, 0.15);
  border-right: 6px solid rgba(246, 157, 45, 0.15);
  border-bottom: 6px solid rgba(246, 157, 45, 0.15);
  border-top: 6px solid rgba(246, 157, 45, 0.8);
  border-radius: 100%;
`;
