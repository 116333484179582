import React from 'react';
import styled from 'styled-components';

export const ItemSubtitle = props => {
  // console.log(props);

  const InnerSpan = styled.div`
    font-size: 8px;
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    &:not(:first-child):before {
      color: black;
      display: inline-block;
      content: '/';
      margin: 5px;
    }
  `;

  const { id } = props;

  return (
    <div style={{ display: 'table-row' }}>
      <InnerSpan>{id}</InnerSpan>
    </div>
  );
};

export default ItemSubtitle;
