import axios from 'axios';
import { get, merge } from 'lodash/fp';
import decodeAccessToken from 'jwt-decode';
import { getXApiKey } from './apiKeys';
import { getEndPointUrl as ep } from './endpoints';
import appendQueryParams from './appendQueryParams';
import { getAccessToken, getRefreshToken, setAccessToken, setRefreshToken, setIdToken } from './token';

export default (action, params) => {
  const hasTokenExpired = () => {
    const token = getAccessToken();

    if (!token || token === 'undefined') {
      return true;
    }

    return token && decodeAccessToken(token).exp < Math.round(Date.now() / 1000);
  };

  const refreshToken = async () => {
    const requestType = 'Auth.RefreshToken';
    const endpoint = { url: ep(requestType), xApiKey: getXApiKey(requestType) };
    const refreshToken = getRefreshToken();
    const queryParams = [`code=${refreshToken}`, 'type=refresh_token', `client_id=${process.env.COGNITO_CLIENT_ID}`];
    const uri = appendQueryParams(endpoint.url, queryParams);

    try {
      const {
        data: {
          body: { result },
          statusCode,
        },
      } = await axios.get(uri, getParams({ endpoint }));

      if (statusCode == 400) {
        await localStorage.clear();
        window.location = '/login';
        return false;
      }

      let response = JSON.parse(result);

      setAccessToken(response.access_token);
      setRefreshToken(refreshToken);
      setIdToken(response.id_token);

      return true;
    } catch (error) {
      await localStorage.clear();
      window.location = '/login';
      return false;
    }
  };

  const refreshTokenIfNeeded = async () => {
    return Promise.resolve().then(async () => {
      if (hasTokenExpired()) {
        await refreshToken();
      }
    });
  };

  const getParams = ({ endpoint }) => {
    const defaultParams = {
      method: 'get',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'x-api-key': get('xApiKey')(endpoint),
      },
    };
    let params1 = merge(defaultParams, params);
    return params1;
  };

  const getUri = ({ endpoint, queryParams = [] }) => {
    return appendQueryParams(endpoint.url, queryParams);
  };

  return refreshTokenIfNeeded()
    .then(() => axios(getUri(action), getParams(action)))
    .catch(error => console.log('refresh token if needed error new' + error))
    .then(response => response);
};
