import { get } from 'lodash/fp';

export const FETCH_EDIT_JOB_ID_REQUEST = 'FETCH_EDIT_JOB_ID_REQUEST';
export const FETCH_EDIT_JOB_ID_SUCCESS = 'FETCH_EDIT_JOB_ID_SUCCESS';
export const FETCH_EDIT_JOB_ID_FAILURE = 'FETCH_EDIT_JOB_ID_FAILURE';

export const SET_SEQUENCE_ID = 'SET_SEQUENCE_ID';
export const SET_SEQUENCE_SETTINGS = 'SET_SEQUENCE_SETTINGS';
export const SET_SEQUENCE_SOURCE_COMPARISON_RESULT = 'SET_SEQUENCE_SOURCE_COMPARISON_RESULT';

export const CREATE_FILE_DOWNLOAD_PROGRESS_BAR = 'CREATE_FILE_DOWNLOAD_PROGRESS_BAR';
export const UPDATE_FILE_DOWNLOAD_PROGRESS_BAR = 'UPDATE_FILE_DOWNLOAD_PROGRESS_BAR';

//Approvals
export const UPLOADING_PROJECT_FILE_FOR_APPROVAL_REQUEST = 'UPLOADING_PROJECT_FILE_FOR_APPROVAL_REQUEST';
export const UPLOADING_PROJECT_FILE_FOR_APPROVAL_SUCCESS = 'UPLOADING_PROJECT_FILE_FOR_APPROVAL_SUCCESS';
export const UPLOADING_PROJECT_FILE_FOR_APPROVAL_FAILURE = 'UPLOADING_PROJECT_FILE_FOR_APPROVAL_FAILURE';

export const UPLOADING_XML_FILE_FOR_APPROVAL_REQUEST = 'UPLOADING_XML_FILE_FOR_APPROVAL_REQUEST';
export const UPLOADING_XML_FILE_FOR_APPROVAL_SUCCESS = 'UPLOADING_XML_FILE_FOR_APPROVAL_SUCCESS';
export const UPLOADING_XML_FILE_FOR_APPROVAL_FAILURE = 'UPLOADING_XML_FILE_FOR_APPROVAL_FAILURE';

//Submissions
export const UPLOADING_PROJECT_FILE_FOR_SUBMISSION_REQUEST = 'UPLOADING_PROJECT_FILE_FOR_SUBMISSION_REQUEST';
export const UPLOADING_PROJECT_FILE_FOR_SUBMISSION_SUCCESS = 'UPLOADING_PROJECT_FILE_FOR_SUBMISSION_SUCCESS';
export const UPLOADING_PROJECT_FILE_FOR_SUBMISSION_FAILURE = 'UPLOADING_PROJECT_FILE_FOR_SUBMISSION_FAILURE';

export const UPLOADING_XML_FILE_FOR_SUBMISSION_REQUEST = 'UPLOADING_XML_FILE_FOR_SUBMISSION_REQUEST';
export const UPLOADING_XML_FILE_FOR_SUBMISSION_SUCCESS = 'UPLOADING_XML_FILE_FOR_SUBMISSION_SUCCESS';
export const UPLOADING_XML_FILE_FOR_SUBMISSION_FAILURE = 'UPLOADING_XML_FILE_FOR_SUBMISSION_FAILURE';

export const setEditJobId = editJobId => async dispatch => {
  await dispatch({ type: FETCH_EDIT_JOB_ID_REQUEST });
  try {
    await dispatch({ type: FETCH_EDIT_JOB_ID_SUCCESS, editJob: { editJobId: editJobId } });
  } catch (e) {
    await dispatch({ type: FETCH_EDIT_JOB_ID_FAILURE });
  }
};

export const setSequenceId = sequenceId => async dispatch => {
  await dispatch({ type: SET_SEQUENCE_ID, editJob: { sequenceId } });
};

export const setSequenceComparisonResult = result => async dispatch => {
  await dispatch({ type: SET_SEQUENCE_SOURCE_COMPARISON_RESULT, editJob: { sequenceComparisonResult: result } });
};

export const setSequenceSettings = settings => async dispatch => {
  await dispatch({ type: SET_SEQUENCE_SETTINGS, editJob: { settings } });
};

export const createFileDownloadProgressBar = progressBar => async dispatch => {
  await dispatch({ type: CREATE_FILE_DOWNLOAD_PROGRESS_BAR, progressBar: { ...progressBar } });
};

export const updateFileDownloadProgressBar = progressBar => async dispatch => {
  await dispatch({ type: UPDATE_FILE_DOWNLOAD_PROGRESS_BAR, progressBar: { ...progressBar } });
};

//Approvals

//Project File
export const uploadingProjectFileForApproval = info => async dispatch => {
  await dispatch({
    type: UPLOADING_PROJECT_FILE_FOR_APPROVAL_REQUEST,
    editJob: { approval: { uploadingProjectFile: true, ...info } },
  });
};

export const uploadingProjectFileForApprovalCompleted = () => async dispatch => {
  await dispatch({
    type: UPLOADING_PROJECT_FILE_FOR_APPROVAL_SUCCESS,
    editJob: { approval: { uploadingProjectFile: false, uploadingProjectFileCompleted: true } },
  });
};

//Xml FIle
export const uploadingXmlFileForApproval = info => async dispatch => {
  await dispatch({
    type: UPLOADING_XML_FILE_FOR_APPROVAL_REQUEST,
    editJob: { approval: { uploadingXmlFile: true, ...info } },
  });
};

export const uploadingXmlFileForApprovalCompleted = () => async dispatch => {
  await dispatch({
    type: UPLOADING_XML_FILE_FOR_APPROVAL_SUCCESS,
    editJob: { approval: { uploadingXmlFile: false, uploadingXmlFileCompleted: true } },
  });
};

//Submissions
export const uploadingProjectFileForSubmission = info => async dispatch => {
  await dispatch({
    type: UPLOADING_PROJECT_FILE_FOR_SUBMISSION_REQUEST,
    editJob: { submission: { uploadingProjectFile: true, ...info } },
  });
};

export const uploadingProjectFileForSubmissionCompleted = () => async dispatch => {
  await dispatch({
    type: UPLOADING_PROJECT_FILE_FOR_SUBMISSION_REQUEST,
    editJob: { submission: { uploadingProjectFile: false, uploadingProjectFileCompleted: true } },
  });
};

export const uploadingXmlFileForSubmission = info => async dispatch => {
  await dispatch({
    type: UPLOADING_XML_FILE_FOR_SUBMISSION_REQUEST,
    editJob: { submission: { uploadingXmlFile: true, ...info } },
  });
};

export const uploadingXmlFileForSubmissionCompleted = () => async dispatch => {
  await dispatch({
    type: UPLOADING_XML_FILE_FOR_SUBMISSION_REQUEST,
    editJob: { submission: { uploadingXmlFile: false, uploadingXmlFileCompleted: true } },
  });
};
