import MakeRequest from '../../request/makeRequest';

export const LOAD_CONFIG_REQUEST = 'LOAD_CONFIG_REQUEST';
export const LOAD_CONFIG_SUCCESS = 'LOAD_CONFIG_SUCCESS';
export const LOAD_CONFIG_FAILURE = 'LOAD_CONFIG_FAILURE';

export const loadConfig = () => async dispatch => {
  await dispatch({ type: LOAD_CONFIG_REQUEST });
  try {
    const url = `https://${process.env.CONFIG_API_ID}.execute-api.${process.env.AWS_REGION}.amazonaws.com/${process.env.API_PREFIX}/configuration/frontend`;

    const {
      data: { items },
    } = await MakeRequest({ endpoint: { url: url, xApiKey: process.env.CONFIG_API_KEY } });

    await dispatch({ type: LOAD_CONFIG_SUCCESS, config: items });
  } catch (ex) {
    await dispatch({ type: LOAD_CONFIG_FAILURE });
  }
};
